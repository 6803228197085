import { createContext } from 'react'

export type ThemeProviderProps = {
  children: React.ReactNode
  defaultTheme?: string
  storageKey?: string
}

type ThemeProviderState = {
  theme: string
  setTheme: (theme: string) => void
}

const initialState = {
  theme: 'system',
  setTheme: () => null,
}

export const ThemeContext = createContext<ThemeProviderState>(initialState)
