import {
  differenceInDays,
  differenceInHours,
  differenceInMinutes,
  differenceInMonths,
  differenceInYears,
} from 'date-fns'
import dayjs from 'dayjs'

// A very simple conversion function that only checks years, months, and days
export const convertTimeToLastSeen = (lastMessageAt: Date): string => {
  const now = new Date()
  if (differenceInYears(now, lastMessageAt) >= 1) {
    return `${differenceInYears(now, lastMessageAt)}y`
  }
  if (differenceInMonths(now, lastMessageAt) >= 1) {
    return `${differenceInMonths(now, lastMessageAt)}mo`
  }
  if (differenceInDays(now, lastMessageAt) >= 1) {
    return `${differenceInDays(now, lastMessageAt)}d`
  }
  if (differenceInHours(now, lastMessageAt) >= 1) {
    return `${differenceInHours(now, lastMessageAt)}h`
  }
  if (differenceInMinutes(now, lastMessageAt) >= 1) {
    return `${differenceInMinutes(now, lastMessageAt)}m`
  }

  // Always return at least <1m as we dont do seconds
  return `<1m`
}

function formatTimeUnit(value: number, unit: string): string {
  return `${value} ${value === 1 ? unit : unit + 's'}`
}

export function formatRemainingTime(expirationTimestamp: string) {
  const now = new Date()
  const expirationDate = new Date(expirationTimestamp)

  const daysRemaining = differenceInDays(expirationDate, now)
  const hoursRemaining = differenceInHours(expirationDate, now)
  const minutesRemaining = differenceInMinutes(expirationDate, now)

  if (daysRemaining > 0) {
    const hours = hoursRemaining % 24
    return `${formatTimeUnit(daysRemaining, 'day')} and ${formatTimeUnit(hours, 'hour')}`
  } else if (hoursRemaining > 0) {
    const minutes = minutesRemaining % 60
    return `${formatTimeUnit(hoursRemaining, 'hour')} and ${formatTimeUnit(minutes, 'minute')}`
  } else {
    return formatTimeUnit(minutesRemaining, 'minute')
  }
}

export function formatDuration(durationToFormat: any) {
  const diffDuration = dayjs.duration(durationToFormat)

  const months = Math.floor(diffDuration.asMonths())
  const weeks = Math.floor(diffDuration.asWeeks() % 4)
  const days = Math.floor(diffDuration.asDays() % 7)
  const hours = Math.floor(diffDuration.asHours() % 24)
  const minutes = diffDuration.minutes()

  const parts = []
  if (months) parts.push(`${months}m`)
  if (weeks) parts.push(`${weeks}w`)
  if (days) parts.push(`${days}d`)
  if (hours) parts.push(`${hours}h`)
  if (minutes) parts.push(`${minutes}m`)

  return parts.join(', ')
}

export function getTimeDiff(start: string, end: string) {
  const startDay = dayjs(start)
  const endDay = dayjs(end)

  return endDay.diff(startDay)
}

type DateFormat = 'day' | 'monthDay' | 'full'

// Formats a given date string into different date representations.
export function formatDateString(date: string, format: DateFormat = 'full') {
  const parsedDate = new Date(date)

  const day = String(parsedDate.getDate())
  const month = String(parsedDate.getMonth() + 1)
  const year = String(parsedDate.getFullYear()).slice(-2)

  switch (format) {
    case 'day':
      return day
    case 'monthDay':
      return `${month}/${day}`
    case 'full':
      return `${month}/${day}/${year}`
    default:
      throw new Error(`Invalid format ${format}`)
  }
}
