import { useRedemptionFiltersContext } from '@context/redemptionFiltersContext'
import {
  faCheck,
  faCircleXmark,
  faMagnifyingGlass,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  CommunityDiscoveryQueryAttributes,
  PageFilterOp,
  PublicVendor,
  useCommunitiesDiscoveryQuery,
} from '@graphql'
import { ComboboxItem, MultiSelect } from '@mantine/core'
import { useDebouncedValue } from '@mantine/hooks'
import { useEffect, useMemo, useState } from 'react'

type FormProps = {
  onChange: (value: string[]) => void
  value?: string[] | undefined
}

type VendorsMultiSelectProps = {
  name: string
}

const buildVendorOption = (
  vendor: Partial<PublicVendor> & { id: string; displayName: string }
): ComboboxItem => {
  return {
    label: vendor.name || vendor.displayName,
    value: vendor.id,
  }
}

export const VendorsMultiSelect = ({
  name,
  onChange: formOnChange,
  value: currentVendorIds,
}: VendorsMultiSelectProps & FormProps) => {
  // The current selected communities.
  const { currentSelectedVendorsForFilter, setSelectedVendorsForFilter } =
    useRedemptionFiltersContext()
  // The communities filtered by `communityName`.
  const [filteredCommunitiesOptions, setFilteredCommunitiesOptions] = useState<
    ComboboxItem[]
  >([])
  // The current search value of the vendors multi select.
  const [searchValue, setSearchValue] = useState('')
  const [debouncedSearchValue] = useDebouncedValue(searchValue, 350)
  const [focused, setFocused] = useState(false)

  const { data: communitiesData, loading } = useCommunitiesDiscoveryQuery({
    variables: {
      query: {
        filters: debouncedSearchValue
          ? [
              {
                attribute: CommunityDiscoveryQueryAttributes.FullText,
                op: PageFilterOp.Contains,
                value: { string: debouncedSearchValue },
              },
            ]
          : [],
        limit: 100,
      },
    },
  })

  const filteredCommunities = useMemo(() => {
    return communitiesData?.discoverCommunities?.data
  }, [communitiesData])

  //# Builds the community select options.
  useEffect(() => {
    if (!loading) {
      const filteredCommunitiesOption = filteredCommunities?.map((c) =>
        buildVendorOption(c.vendor)
      )
      //# The communities displayed in the `multi-select` dropdown are a combination of:
      // * Communities filtered by full text search.
      // * The previously selected communities.

      const allCommunities = [
        ...(filteredCommunitiesOption || []),
        ...currentSelectedVendorsForFilter,
      ]

      // Remove duplicates
      const uniqueCommunities = [
        ...new Map(allCommunities.map((item) => [item.value, item])).values(),
      ]

      setFilteredCommunitiesOptions(uniqueCommunities)
    }
  }, [filteredCommunities, currentSelectedVendorsForFilter, loading])

  const searchIcon = (
    <FontAwesomeIcon icon={faMagnifyingGlass} className={'text-primary'} />
  )

  const confirmButton = (
    <FontAwesomeIcon
      icon={faCheck}
      className={'text-primary'}
      onClick={() => setFocused(false)}
    />
  )

  const hasValues = currentVendorIds && currentVendorIds.length > 0

  const getRightSection = () => {
    if (focused) return confirmButton
    if (!hasValues) return searchIcon
    return null
  }

  return (
    <MultiSelect
      value={currentVendorIds}
      name={name}
      label="Vendors"
      styles={{
        label: {
          fontWeight: 600,
        },
      }}
      placeholder="Search by name"
      searchable
      clearable
      comboboxProps={{
        shadow: 'md',
        transitionProps: { transition: 'fade-down', duration: 200 },
      }}
      rightSection={getRightSection()}
      onChange={(communityIds) => {
        // Every time we select a community,the `selected vendors` state is updated.
        // We do this so we can combine the selected vendors with the ones being filtered later.
        const selectedCommunities = filteredCommunitiesOptions?.filter(
          (community) => communityIds.includes(community.value)
        )

        setSelectedVendorsForFilter(selectedCommunities || [])
        formOnChange(communityIds)
      }}
      nothingFoundMessage="No vendors found..."
      checkIconPosition="right"
      radius="md"
      clearButtonProps={{ icon: <FontAwesomeIcon icon={faCircleXmark} /> }}
      onSearchChange={(value) => setSearchValue(value)}
      data={filteredCommunitiesOptions}
      onFocus={() => setFocused(true)}
      onBlur={() => setFocused(false)}
      mt={20}
    />
  )
}
