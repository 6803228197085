import { createContext } from 'react'

export interface LocationHistoryContextType {
  hasHistory: boolean
}

export const LocationHistoryContext = createContext<LocationHistoryContextType>(
  {
    hasHistory: false,
  }
)
