import { faClock } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Badge } from '@mantine/core'
import { formatDuration, getTimeDiff } from '@util/time'
import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'
dayjs.extend(duration)

export function ValidEndLimitBadge({
  redemptionValidEnd,
}: {
  redemptionValidEnd: string
}) {
  const timeDiff = getTimeDiff(dayjs().toISOString(), redemptionValidEnd)

  const limitedReached = timeDiff <= 0

  return (
    <Badge
      color={limitedReached ? 'red' : 'cyan.6'}
      variant="filled"
      leftSection={<FontAwesomeIcon size="lg" icon={faClock} />}
      size="md"
      mx={12}
    >
      {formatDuration(timeDiff)}
    </Badge>
  )
}
