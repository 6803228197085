import { Box, Button, Group, Text, Textarea } from '@mantine/core'
import { UseFormReturnType } from '@mantine/form'
import { SEMI_BOLD } from '@util/utils'
import { BroadcastFormValues } from '../BroadcastContext'
import { MAX_BODY_MESSAGE_SIZE } from './EditBroadcastForm.constants'

type BroadcastMessageProps = {
  form: UseFormReturnType<
    BroadcastFormValues,
    (values: BroadcastFormValues) => BroadcastFormValues
  >
  setShowMessagePreviewModal: React.Dispatch<React.SetStateAction<boolean>>
}

export const BroadcastMessage = ({
  form,
  setShowMessagePreviewModal,
}: BroadcastMessageProps) => {
  return (
    <Box id="form-message-body">
      <Group justify="space-between">
        <Text
          component="label"
          htmlFor="messageTemplate.body"
          size="sm"
          fw={SEMI_BOLD}
        >
          Message{' '}
          <Text component="span" c="red">
            *
          </Text>
        </Text>
        <Button
          variant="transparent"
          fz="xs"
          onClick={() => setShowMessagePreviewModal(true)}
          size="compact-sm"
        >
          Preview
        </Button>
      </Group>
      <Textarea
        id="messageTemplate-body"
        placeholder="Enter message"
        required
        autosize
        maxRows={5}
        {...form.getInputProps('messageTemplate.body')}
      />
      <Group justify="space-between" mt={4}>
        <Text
          size="xs"
          c={
            (form.values.messageTemplate.body?.length || 0) <
            MAX_BODY_MESSAGE_SIZE * 0.8
              ? 'gray'
              : 'red'
          }
          ta="end"
          w="100%"
        >
          {form.values.messageTemplate.body?.length || 0} /{' '}
          {MAX_BODY_MESSAGE_SIZE}
        </Text>
      </Group>
    </Box>
  )
}
