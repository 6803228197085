import { Capacitor } from '@capacitor/core'
import { Geolocation, PermissionStatus, Position } from '@capacitor/geolocation'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useGeolocation } from './useGeolocation'

export function useGetLocation() {
  const geolocation = useGeolocation()
  const [watchId, setWatchId] = useState<string | null>(null)
  const getPositionInterval = useRef<ReturnType<typeof setInterval> | null>(
    null
  )

  /**
   * Watch the position of the device
   * @param {PositionOptions} options
   * @param {(position: GeolocationPosition | null, error: any) => void} callback
   * - The callback function that will be called with the position or error
   * @returns {Promise<void>}
   */
  const watchPosition = async (
    options: PositionOptions,
    callback: (position: Position | null, error: any) => void
  ) => {
    if (watchId) {
      await Geolocation.clearWatch({ id: watchId })
    }

    const newWatchId = await Geolocation.watchPosition(
      options,
      (position, error) => {
        callback(position, error)
      }
    )

    setWatchId(newWatchId)
  }

  /**
   * Clear the watch
   * @returns {Promise<void>}
   */
  const clearWatch = useCallback(async () => {
    if (watchId) {
      await Geolocation.clearWatch({ id: watchId })
      setWatchId(null)
    }
  }, [watchId])

  /**
   * Request location permissions
   * @returns {Promise<PermissionStatus | null>}
   * - The status of the location permissions or null if the platform is web
   * (requestPermissions not available on web)
   */
  const requestLocationPermission =
    async (): Promise<PermissionStatus | null> => {
      // requestPermissions not available on web
      if (Capacitor.getPlatform() === 'web') {
        return null
      }

      const permission = await Geolocation.requestPermissions()
      geolocation.setPermissions(permission)
      return permission
    }

  /**
   * Check the location permissions
   * @returns {Promise<PermissionStatus>} - The status of the location permissions
   */
  const checkLocationPermission = async (): Promise<PermissionStatus> => {
    let permissions

    try {
      permissions = await Geolocation.checkPermissions()
    } catch (error) {
      geolocation.setError(error as Error)
      return { location: 'denied', coarseLocation: 'denied' }
    }

    if (
      permissions.location === geolocation.permissions?.location &&
      permissions.coarseLocation === geolocation.permissions?.coarseLocation
    ) {
      return geolocation.permissions
    } else {
      geolocation.setPermissionsChecked(true)
      geolocation.setPermissions(permissions)

      return permissions
    }
  }

  /**
   * Used for web platform to do the initial permissions request and location fetch if permissions weren't asked before
   * Updates the permissions and the position if granted in the store
   * If the permisions were already granted or denied, it does nothing because the permissions and the position are already in the store
   * Throws an error if the platform is not web
   * @returns {Promise<void>}
   */
  const requestAndGetLocationForWeb = async (): Promise<void> => {
    if (Capacitor.getPlatform() === 'web') {
      const permissions = await checkLocationPermission()
      if (permissions.location.startsWith('prompt')) {
        getCurrentPosition({ shouldUpdatePermissions: true })
      }
    } else {
      throw new Error('requestAndGetLocationForWeb is only available on web')
    }
  }

  /**
   * Get the current position and update the store
   * @param {Object} options - The options object
   * @param {boolean} options.shouldUpdatePermissions - If true, it will update the permissions in the store
   * @returns {void}
   */
  const getCurrentPosition = ({
    shouldUpdatePermissions = false,
  }: { shouldUpdatePermissions?: boolean } = {}): void => {
    Geolocation.getCurrentPosition()
      .then((position) => {
        geolocation.updatePosition(position)
        geolocation.updateLastPositionTimestamp(position.timestamp)
        if (shouldUpdatePermissions) {
          const permissionObject: PermissionStatus = {
            location: 'granted',
            coarseLocation: 'granted',
          }

          geolocation.setPermissions(permissionObject)
        }
      })
      .catch((error) => {
        geolocation.setError(error)
        if (shouldUpdatePermissions) {
          const permissionObject: PermissionStatus = {
            location: 'denied',
            coarseLocation: 'denied',
          }

          geolocation.setPermissions(permissionObject)
        }
      })
  }

  useEffect(
    function startAndClearGetPositionInterval() {
      // Updating permissions on start
      checkLocationPermission()

      const timeoutMs = 15000

      if (
        geolocation.permissions?.location === 'granted' &&
        !getPositionInterval.current
      ) {
        getCurrentPosition()
        getPositionInterval.current = setInterval(getCurrentPosition, timeoutMs)
      }

      return () => {
        if (getPositionInterval.current) {
          clearInterval(getPositionInterval.current)
          getPositionInterval.current = null
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [geolocation.permissions?.location]
  )

  useEffect(
    function clearGeolocationWatch() {
      return () => {
        if (watchId) {
          clearWatch()
        }
      }
    },
    [clearWatch, watchId]
  )

  return {
    position: geolocation.position,
    permissions: geolocation.permissions,
    permissionsChecked: geolocation.permissionsChecked,
    error: geolocation.error,
    lastPositionTimestamp: geolocation.lastPositionTimestamp,
    setPermissions: geolocation.setPermissions,
    setPermissionsChecked: geolocation.setPermissionsChecked,
    requestAndGetLocationForWeb,
    watchPosition,
    requestLocationPermission,
    checkLocationPermission,
  }
}
