import { noop } from 'lodash-es'
import { createContext } from 'react'
import { LayoutContextType } from './LayoutContext.type'

export const LayoutContext = createContext<LayoutContextType>({
  isHeaderTransparent: false,
  setHeaderTransparent: () => noop(),
  setHeaderOpaque: () => noop(),
  displayHeader: true,
  hideMobileToolbar: () => noop(),
  showMobileToolbar: () => noop(),
  isGetAppSheetOpen: false,
  closeGetAppSheet: () => noop(),
  openGetAppSheet: () => noop(),
  isGetAppModalOpen: false,
  closeGetAppModal: () => noop(),
  openGetAppModal: () => noop(),
  shouldShowAppBanner: false,
  showShowAppBanner: () => noop(),
  hideShowAppBanner: () => noop(),
} as LayoutContextType)
