import { faEyeSlash } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Center, Group, Title } from '@mantine/core'

type SwayEmptyProps = {
  message: string
}

export const SwayEmpty = ({ message }: SwayEmptyProps) => {
  return (
    <Center>
      <Group>
        <FontAwesomeIcon icon={faEyeSlash} className="h-7 w-7" />
        <Title order={4}>{message}</Title>
      </Group>
    </Center>
  )
}
