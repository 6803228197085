import { FeatureFlag } from '@graphql'
import { noop } from 'lodash-es'
import { createContext } from 'react'
import { FeatureFlagContextBaseState } from './FeatureFlagContextBaseState'

export interface FeatureFlagContextType {
  allFlags: Array<
    | (FeatureFlag & {
        override?: boolean
        overrideValue?: boolean
      })
    | null
  >
  contentFeed: boolean
  experimentalFeatures: boolean
  hasLoaded: boolean
  knockFeed: boolean
  vendorDashboard: boolean
  removeOverride: (flagName: string) => void
  setFlagOverride: (flagName: string, value: boolean) => void
  updateFeatureFlags: (features: Array<FeatureFlag | null>) => void
}

export const FeatureFlagContext = createContext<FeatureFlagContextType>({
  ...FeatureFlagContextBaseState,
  removeOverride: () => noop,
  setFlagOverride: () => noop,
  updateFeatureFlags: () => noop,
})
