import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { faTimes } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useIsMobileViewport } from '@hooks/useIsMobileViewport'
import { SwayCashIcon } from '@icons/SwayCash'
import { Box, Button, Flex, Modal, useMantineTheme } from '@mantine/core'
import { DESKTOP_MODAL_HEIGHT } from '@util/utils'
import React from 'react'

export const FullscreenModal = ({
  opened,
  onClose,
  backgroundColor,
  backgroundIcon,
  children,
}: {
  opened: boolean
  onClose: () => void
  backgroundColor?: string
  backgroundIcon?: React.ReactNode
  children?: React.ReactNode
}) => {
  const theme = useMantineTheme()
  const isMobileViewport = useIsMobileViewport()

  backgroundColor = backgroundColor || theme.primaryColor

  const ModalBody = (
    <>
      <Flex
        className="absolute inset-0 m-auto h-80 w-80 scale-200 overflow-hidden opacity-10"
        justify={'center'}
      >
        {backgroundIcon || <SwayCashIcon allFilled color="white" />}
      </Flex>
      {children}
    </>
  )

  {
    return isMobileViewport ? (
      <Modal.Root opened={opened} onClose={onClose} fullScreen>
        <Modal.Content bg={backgroundColor}>
          <Modal.Body h="100vh" pos="relative" className="overflow-hidden">
            {ModalBody}
          </Modal.Body>
        </Modal.Content>
      </Modal.Root>
    ) : (
      <Modal.Root opened={opened} onClose={onClose} centered>
        <Modal.Overlay>
          <Modal.Content bg={backgroundColor}>
            <Modal.Body
              mah={DESKTOP_MODAL_HEIGHT}
              pos="relative"
              className="overflow-hidden"
              h="100vh"
            >
              <Box pos="absolute" top={2} left={2}>
                <Button
                  onClick={onClose}
                  variant="transparent"
                  leftSection={
                    <FontAwesomeIcon
                      color="white"
                      className="h-6 w-6"
                      icon={faTimes as IconProp}
                    />
                  }
                />
              </Box>
              {ModalBody}
            </Modal.Body>
          </Modal.Content>
        </Modal.Overlay>
      </Modal.Root>
    )
  }
}
