import { create } from 'zustand'
import { createJSONStorage, persist } from 'zustand/middleware'
import CapacitorStorage from './capacitorStorage'

type State = {
  challengesPageVisited: boolean
  leaderboardAlertDismissed: boolean
}

type Action = {
  setChallengePageVisited: (bool: boolean) => void
  setLeaderboardAlertDismissed: (bool: boolean) => void
}

type ChallengesStore = State & Action

export const useChallengesStore = create(
  persist<ChallengesStore>(
    (set) => ({
      challengesPageVisited: false,
      setChallengePageVisited: (bool: boolean) => {
        set({ challengesPageVisited: bool })
      },
      leaderboardAlertDismissed: false,
      setLeaderboardAlertDismissed: (bool: boolean) => {
        set({ leaderboardAlertDismissed: bool })
      },
    }),
    {
      name: 'challenges-store',
      storage: createJSONStorage(() => CapacitorStorage),
    }
  )
)
