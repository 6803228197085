// https://capacitorjs.com/docs/apis/device

import { Device, DeviceInfo } from '@capacitor/device'
import { useEffect, useState } from 'react'

export const useGetDevice = () => {
  const [device, setDevice] = useState<DeviceInfo | null>(null)

  useEffect(() => {
    Device.getInfo().then((info) => {
      setDevice(info)
    })
  }, [])

  return device
}
