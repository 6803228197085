import { useApolloClient } from '@apollo/client'
import { useFeatureFlags } from '@context/featureFlagContext'
import { LoginForm } from '@forms/LoginForm'
import {
  AccessToken,
  FeaturesDocument,
  FeaturesQuery,
  ProfileType,
  useLoginMutation,
} from '@graphql'
import { useAuth } from '@hooks/useAuth'
import { Modal } from '@mantine/core'
import { useForm } from '@mantine/form'
import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'

import * as Sentry from '@sentry/react'

export function LoginModal({
  opened,
  onClose,
}: {
  opened: boolean
  onClose: () => void
}) {
  const { currentUser, onLogin } = useAuth()
  const { updateFeatureFlags } = useFeatureFlags()

  const navigate = useNavigate()

  const client = useApolloClient()
  const [login] = useLoginMutation()

  const form = useForm<{
    email: string
    password: string
  }>({
    initialValues: {
      email: '',
      password: '',
    },
  })

  const handleLogin = useCallback(
    async function handleLoginCallback({
      email,
      password,
    }: {
      email: string
      password: string
    }) {
      if (email === currentUser?.email) {
        form.setErrors({
          email: ' ',
          password: 'Already logged in',
        })
        return
      }

      try {
        const { data } = await login({ variables: { email, password } })

        if (!data?.login.user || !data?.login.user?.id) {
          throw new Error('No user returned')
        }

        const { accessToken, user, impersonator } = data.login
        const userId = data.login.user.id

        Sentry.setUser({
          id: userId,
          email: email,
        })

        if (!accessToken?.value) {
          throw new Error('No access token returned')
        }

        if (data?.login.user && data.login.user.id) {
          const { data: featuresData } = await client.query<FeaturesQuery>({
            query: FeaturesDocument,
            fetchPolicy: 'network-only', // Ensure we have the latest
            context: {
              headers: {
                Authorization: `Bearer ${accessToken.value}`,
              },
            },
          })

          updateFeatureFlags(featuresData.features)

          onLogin({
            newUser: {
              id: userId,
              accessToken: accessToken as AccessToken,
              communityName: user.profile?.communityName || '',
              displayName: user.profile?.displayName || '',
              email: user.email || '',
              firstName: user.profile?.firstName || '',
              lastName: user.profile?.lastName || '',
              profileType: user.profile?.profileType as ProfileType,
              profilePictureUrl: user.profile?.profilePictureUrl || '',
              profileId: user?.profile?.id,
              impersonator: impersonator && {
                id: impersonator?.id || '',
                email: impersonator?.email || '',
              },
              magicLink: '',
            },
          })
          form.reset()
          onClose()
          navigate('/')
        } else {
          form.setErrors({
            email: ' ',
            password: 'Invalid email or password',
          })
        }
      } catch (error: any) {
        console.error('error logging in: ', error)
        form.setErrors({
          email: ' ',
          password: error.message || 'Something went wrong',
        })
        return Promise.reject(error)
      }
    },
    [
      client,
      currentUser?.email,
      form,
      login,
      navigate,
      onClose,
      onLogin,
      updateFeatureFlags,
    ]
  )

  return (
    <Modal opened={opened} onClose={onClose} title="Login">
      <LoginForm
        form={form}
        onSubmit={handleLogin}
        hideRegistration
        onClose={onClose}
      />
    </Modal>
  )
}
