import {
  BroadcastInput,
  BroadcastStatus,
  MessageTemplate,
  useBroadcastPublishMutation,
  useBroadcastUpdateMutation,
} from '@graphql'
import { Button } from '@mantine/core'
import { UseFormReturnType } from '@mantine/form'
import { generateImgixOptions } from '@util/imgixUtils'
import { notifications } from '@util/notifications/notifications'
import dayjs from 'dayjs'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  BroadcastFormValues,
  isMessageTemplateDefault,
} from '../BroadcastContext'
import { EditBroadcastPublishConfirmationModal } from '../EditBroadcastPublishConfirmationModal'
import { EditBroadcastSendImmediatelyConfirmationModal } from '../EditBroadcastSendImmediatelyConfirmationModal'
import { MediaFocalPoint } from './EditBroadcastForm'

type BroadcasSendOrUpdaterops = {
  form: UseFormReturnType<
    BroadcastFormValues,
    (values: BroadcastFormValues) => BroadcastFormValues
  >
  broadcast: BroadcastInput | undefined
  broadcastId: string | undefined
  mediaFocalPoint: MediaFocalPoint | undefined
  isLiveOrScheduled?: boolean
}

export const BroadcastSendOrUpdate = ({
  form,
  broadcast,
  broadcastId,
  mediaFocalPoint,
  isLiveOrScheduled,
}: BroadcasSendOrUpdaterops) => {
  const [showSendImmediatelyConfirmation, setShowSendImmediatelyConfirmation] =
    useState(false)
  const [
    showScheduleBroadcastConfirmation,
    setShowScheduleBroadcastConfirmation,
  ] = useState(false)

  const [updateBroadcast, { loading: updateBroadcastLoading }] =
    useBroadcastUpdateMutation()
  const [publishBroadcast, { loading: publishBroadcastLoading }] =
    useBroadcastPublishMutation()

  const navigate = useNavigate()

  function broadcastErrorToast() {
    notifications.show({
      title: 'Broadcast Error',
      message:
        'There was an error creating your broadcast. Please refresh the page. If the problem persists, please contact support.',
      color: 'red',
    })
    return
  }

  async function handleSendImmediately(values: BroadcastFormValues) {
    const broadcastStart = values.sendImmediately ? null : values.broadcastStart
    // ? If there is no broadcastId, either the page is being used improperly, or they got here impossibly
    if (!broadcastId) {
      broadcastErrorToast()
      return
    }

    const valid = form.validate()

    if (valid.hasErrors) {
      notifications.show({
        title: 'Form Incomplete',
        message: 'Please update the field(s) marked in red above',
        color: 'red',
      })

      if (valid.errors && Object.keys(valid.errors).length > 0) {
        const errorKey = Object.keys(valid.errors)[0]

        // Handle the case of keys containig '-' (ex: messageTemplate-body)
        const parsedErrorKey = `${errorKey.replace(/\./g, '-')}`

        const elementWithError = document.querySelector(`#${parsedErrorKey}`)

        if (elementWithError) {
          elementWithError.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
          })
        }
      }

      return
    }

    try {
      const updatedBroadcastInput =
        convertBroadcastFormValuesToBroadcastInput(values)

      const updatedBroadcast = await updateBroadcast({
        variables: {
          broadcast: {
            ...updatedBroadcastInput,
            messageTemplate: {
              ...updatedBroadcastInput.messageTemplate,
              primaryAttachmentDefaultMediaOptions: generateImgixOptions({
                'fp-x': mediaFocalPoint?.x,
                'fp-y': mediaFocalPoint?.y,
                'fp-z': mediaFocalPoint?.z,
                fit: 'crop',
                crop: 'focalpoint',
                ar: '5:3',
              }),
            },
            broadcastStart,
          },
          broadcastId,
        },
      })

      if (updatedBroadcast.data?.broadcastUpdate?.successful === false) {
        notifications.show({
          title: 'Broadcast Error',
          message: 'There was an error creating your broadcast',
          color: 'red',
        })

        // Shortcircuit
        return
      }

      const publishedBroadcast = await publishBroadcast({
        variables: {
          broadcastId,
        },
      })

      if (publishedBroadcast.data?.broadcastPublish?.successful) {
        notifications.show({
          title: 'Broadcast Sent',
          message: 'Your broadcast has been sent',
          color: 'green',
        })
        navigate('/vendor/broadcasts')
      }
    } catch (e) {
      console.error('Error publishing broadcast: ', e)
      notifications.show({
        title: 'Broadcast Error',
        message: 'There was an error publishing your broadcast',
        color: 'red',
      })
    }
  }

  async function handleOnPublish(values: BroadcastFormValues) {
    // ? If there is no broadcastId, either the page is being used improperly, or they got here impossibly
    if (!broadcastId) {
      broadcastErrorToast()
      return
    }

    const updatedBroadcastInput =
      convertBroadcastFormValuesToBroadcastInput(values)

    try {
      const updatedBroadcast = await updateBroadcast({
        variables: {
          broadcast: {
            ...updatedBroadcastInput,
            messageTemplate: {
              ...updatedBroadcastInput.messageTemplate,
              primaryAttachmentDefaultMediaOptions: generateImgixOptions({
                'fp-x': mediaFocalPoint?.x,
                'fp-y': mediaFocalPoint?.y,
                'fp-z': mediaFocalPoint?.z,
                fit: 'crop',
                crop: 'focalpoint',
                ar: '5:3',
              }),
            },
          },
          broadcastId,
        },
      })

      if (updatedBroadcast.data?.broadcastUpdate?.successful === false) {
        notifications.show({
          title: 'Broadcast Error',
          message: 'There was an error creating your broadcast',
          color: 'red',
        })

        // Shortcircuit
        return
      }

      if (
        updatedBroadcast.data?.broadcastUpdate?.result?.status ===
        BroadcastStatus.Scheduled
      ) {
        notifications.show({
          title: 'Broadcast Scheduled',
          message: `Your broadcast has been updated and is scheduled to send on ${dayjs(
            updatedBroadcastInput.broadcastStart
          ).format('DD MMM YYYY hh:mm A')}`,
          color: 'green',
        })
        // Shortcircuit
        return
      }

      const publishedBroadcast = await publishBroadcast({
        variables: {
          broadcastId,
        },
      })

      if (publishedBroadcast.data?.broadcastPublish?.successful === false) {
        notifications.show({
          title: 'Broadcast Error',
          message: 'There was an error publishing your broadcast',
          color: 'red',
        })

        // Shortcircuit
        return
      }

      if (publishedBroadcast.data?.broadcastPublish?.successful) {
        if (values.sendImmediately) {
          notifications.show({
            title: 'Broadcast Scheduled',
            message: `Your broadcast has been scheduled to send on ${dayjs(
              updatedBroadcastInput.broadcastStart
            ).format('DD MMM YYYY hh:mm A')}`,
            color: 'green',
          })
        } else {
          notifications.show({
            title: 'Broadcast Sent',
            message: 'Your broadcast has been sent',
            color: 'green',
          })
        }

        navigate('/vendor/broadcasts')
      }
    } catch (e) {
      console.error('Error publishing broadcast: ', e)
      notifications.show({
        title: 'Broadcast Error',
        message: 'There was an error publishing your broadcast',
        color: 'red',
      })
    }
  }

  async function handleUpdate(values: BroadcastFormValues) {
    if (!broadcastId) {
      broadcastErrorToast()
      return
    }

    const valid = form.validate()

    if (valid.hasErrors) {
      notifications.show({
        title: 'Form Incomplete',
        message: 'Please update the field(s) marked in red above',
        color: 'red',
      })

      if (valid.errors && Object.keys(valid.errors).length > 0) {
        const errorKey = Object.keys(valid.errors)[0]

        // Handle the case of keys containig '-' (ex: messageTemplate-body)
        const parsedErrorKey = `${errorKey.replace(/\./g, '-')}`

        const elementWithError = document.querySelector(`#${parsedErrorKey}`)

        if (elementWithError) {
          elementWithError.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
          })
        }
      }

      return
    }

    if (broadcastId) {
      // We want to delete the current follow up message from the given broadcasts if:
      // * There is an existing follow-up message template in the broadcast.
      // * The follow-up message template from the form is in a default state.

      const currentBroadcastHasFollowUp = !isMessageTemplateDefault(
        broadcast?.followUpMessageTemplate as MessageTemplate
      )
      const formDoesNotHaveFollowUp = isMessageTemplateDefault(
        values.followUpMessageTemplate as MessageTemplate
      )

      const shouldDeleteFollowUpMessageTemplate =
        currentBroadcastHasFollowUp && formDoesNotHaveFollowUp

      const updatedBroadcastInput = convertBroadcastFormValuesToBroadcastInput(
        values,
        shouldDeleteFollowUpMessageTemplate
      )

      const result = await updateBroadcast({
        variables: {
          broadcast: {
            ...updatedBroadcastInput,
            messageTemplate: {
              ...updatedBroadcastInput.messageTemplate,
              primaryAttachmentDefaultMediaOptions: generateImgixOptions({
                'fp-x': mediaFocalPoint?.x,
                'fp-y': mediaFocalPoint?.y,
                'fp-z': mediaFocalPoint?.z,
                fit: 'crop',
                crop: 'focalpoint',
                ar: '5:3',
              }),
            },
            broadcastStart: null,
          },
          broadcastId,
        },
      })

      if (result.data?.broadcastUpdate?.successful) {
        if (isLiveOrScheduled) {
          notifications.show({
            title: 'Broadcast Updated',
            message: 'Your broadcast has been updated',
            color: 'green',
          })
          navigate('/vendor/broadcasts')
        } else {
          notifications.show({
            title: 'Broadcast Saved',
            message: 'Your broadcast has been saved as a draft',
            color: 'green',
          })
        }
      } else {
        notifications.show({
          title: 'Broadcast Error',
          message: 'There was an error updating your broadcast',
          color: 'red',
        })
      }
    }
  }

  function handleFormSubmission(values: BroadcastFormValues) {
    if (values.sendImmediately) {
      setShowSendImmediatelyConfirmation(true)
      return
    } else if (!values.sendImmediately && values.broadcastStart) {
      setShowScheduleBroadcastConfirmation(true)
    } else {
      handleUpdate(values)
    }
  }

  function convertBroadcastFormValuesToBroadcastInput(
    values: BroadcastFormValues,
    shouldDeleteFollowUpMessageTemplate = false
  ): BroadcastInput {
    const { messageTemplate, followUpMessageTemplate, ...rest } = values
    // The default data for the template `body` is an empty string, and the backend has validations for this.
    // So in case we are using a default template, we pass `null` to prevent the validation.
    const parsedFollowUpTemplate = isMessageTemplateDefault(
      followUpMessageTemplate as MessageTemplate
    )
      ? null
      : followUpMessageTemplate

    const { templateRedemptionOffers, ...parsedTemplate } = messageTemplate

    return {
      ...rest,
      deleteFollowUpMessageTemplate: shouldDeleteFollowUpMessageTemplate,
      followUpMessageTemplate: parsedFollowUpTemplate,
      messageTemplate: {
        ...parsedTemplate,
        redemptionOffers: {
          offers: templateRedemptionOffers.map((redemptionId) => ({
            redemptionId,
          })),
        },
      },
    }
  }

  return (
    <>
      <Button
        loading={updateBroadcastLoading || publishBroadcastLoading}
        size="lg"
        type="button"
        variant="primary"
        fullWidth
        onClick={() => {
          if (
            (!form.values.sendImmediately && !form.values.broadcastStart) ||
            isLiveOrScheduled
          ) {
            handleUpdate(form.values)
          } else {
            handleFormSubmission(form.values)
          }
        }}
      >
        {isLiveOrScheduled
          ? 'Update broadcast'
          : form.values.sendImmediately
            ? form.values.isPersistent
              ? 'Go Live Now'
              : 'Send Now'
            : form.values.broadcastStart
              ? `Schedule for ${dayjs(form.values.broadcastStart).format('ddd MMM DD, YYYY hh:mm A')}`
              : 'Save Draft'}
      </Button>
      <Button
        size="lg"
        type="button"
        variant="outline"
        fullWidth
        onClick={() => {
          form.reset()
          navigate('/vendor/broadcasts')
        }}
      >
        Cancel
      </Button>

      <EditBroadcastSendImmediatelyConfirmationModal
        opened={showSendImmediatelyConfirmation}
        isPersistent={form.values.isPersistent as boolean}
        onConfirmation={() => {
          handleSendImmediately(form.values)
          setShowSendImmediatelyConfirmation(false)
        }}
        onClose={() => setShowSendImmediatelyConfirmation(false)}
      />
      <EditBroadcastPublishConfirmationModal
        opened={showScheduleBroadcastConfirmation}
        publishDate={form.values.broadcastStart}
        onConfirmation={() => {
          handleOnPublish(form.values)
          setShowScheduleBroadcastConfirmation(false)
        }}
        onClose={() => setShowScheduleBroadcastConfirmation(false)}
      />
    </>
  )
}
