import { ProfileType } from '@graphql'
import { useAuth } from '@hooks/useAuth'
import { Navigate } from 'react-router-dom'

export const RequireMember = ({ children }: { children?: JSX.Element }) => {
  const { currentUser } = useAuth()

  if (currentUser?.profileType !== ProfileType.CommunityFollower) {
    return <Navigate to="/" replace />
  }

  return children || null
}
