import { AttachedMedia } from '@context/mediaContext/MediaTypes.types'
import { noop } from 'lodash-es'
import { createContext } from 'react'

export interface UploadMediaContextType {
  maxUploads: number
  attachedMedia: AttachedMedia[]
  selectMedia: () => Promise<void>
  addAttachedMedia: (media: AttachedMedia) => void
  uploadDroppedMedia: (droppedFiles: File[]) => void
  clearAttachedMedia: (mediaPointerId?: string) => void
  updateUploadProgress: (mediaPointerId: string, progress: number) => void
}

export const UploadMediaContext = createContext<UploadMediaContextType>({
  maxUploads: 1,
  attachedMedia: [],
  selectMedia: () => Promise.resolve(),
  addAttachedMedia: () => {
    noop()
  },
  clearAttachedMedia: () => {
    noop()
  },
  uploadDroppedMedia: () => {
    noop()
  },
  updateUploadProgress: () => {
    noop()
  },
})
