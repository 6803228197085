import { Group, Skeleton, Stack } from '@mantine/core'

type RedemptionDiscoverySkeletonsProps = {
  amountOfSkeletons?: number
}

export const RedemptionDisoverySkeletons = ({
  amountOfSkeletons = 1,
}: RedemptionDiscoverySkeletonsProps) => {
  return (
    <Stack w="100%" h="100%" py={8}>
      {Array.from({ length: amountOfSkeletons }).map((_, index) => (
        <Group wrap="nowrap" align="center" w="100%" key={index}>
          <Skeleton height={100} w="40%" maw={105} radius="md" />
          <Stack w="100%">
            <Skeleton height={15} width="80%" radius="md" />
            <Skeleton height={15} width="60%" radius="md" />
            <Skeleton height={10} width="15%" radius="md" />
            <Group wrap="nowrap">
              <Skeleton height={10} width="10%" radius="md" />
              <Skeleton height={10} width="15%" radius="md" />
            </Group>
          </Stack>
        </Group>
      ))}
    </Stack>
  )
}
