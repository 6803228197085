import { Text } from '@mantine/core'
import { SEMI_BOLD } from '@util/utils'
import React from 'react'

export function InputLabelSectionHeader({
  children,
}: {
  children: React.ReactNode
}) {
  return (
    <Text size="sm" fw={SEMI_BOLD} display="inline-block">
      {children}
    </Text>
  )
}
