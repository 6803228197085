import { Position } from '@capacitor/geolocation'
import {
  MediaOption,
  RedemptionFiltersInput,
  RedemptionOrderByInput,
} from '@graphql'
import { useGetLocation } from '@hooks/useGetLocation'
import { ComboboxItem } from '@mantine/core'
import { useForm } from '@mantine/form'
import useRedemptionFiltersStore from '@stores/useRedemptionFiltersStore'
import { useState } from 'react'
import {
  FilterFormValues,
  RedemptionFiltersContext,
} from './RedemptionFilters.context'
import { DEFAULT_REDEMPTION_FILTERS } from './redemptionFilters.constants'
import { generateFlopEntry } from './redemptionFilters.utils'

export function RedemptionFiltersProvider({
  children,
}: {
  children: React.ReactNode
}): JSX.Element {
  const [currentSelectedVendorsForFilter, setSelectedVendorsForFilter] =
    useState<ComboboxItem[]>([])

  const { storedFiltersFormValues, setStoredFiltersFormValues } =
    useRedemptionFiltersStore()
  // If we have filters in our store, then we use those rather than the default ones.
  const initialFilters = storedFiltersFormValues
    ? storedFiltersFormValues
    : DEFAULT_REDEMPTION_FILTERS

  const [currentFilters, setFilters] =
    useState<FilterFormValues>(initialFilters)

  const { permissions } = useGetLocation()

  const filtersForm = useForm<FilterFormValues>({
    initialValues: {
      ...initialFilters,
      useCurrentLocation: permissions?.location === 'granted',
    },
    onValuesChange: (values) => setStoredFiltersFormValues(values),
  })

  // Based on the current filters form values, returns a set of query arguments.
  const buildQueryArguments = (
    position?: Position | null,
    imgixOpts?: MediaOption[],
    defaultLimit?: number
  ) => {
    const filtersInput: RedemptionFiltersInput[] = []
    const orderByInput: RedemptionOrderByInput[] = []
    const formValues = filtersForm.values

    Object.keys(formValues).forEach((key) => {
      const value = formValues[key as keyof typeof formValues]
      const defaultValue =
        DEFAULT_REDEMPTION_FILTERS[key as keyof typeof formValues]

      if ((filtersForm.isDirty(key) || defaultValue === value) && value) {
        const {
          filtersInput: filtersInputValue,
          orderByInput: orderByInputValue,
        } = generateFlopEntry(key, value)

        filtersInput.push(...filtersInputValue)

        orderByInput.push(...orderByInputValue)
      }
    })
    const currentLocation = formValues.useCurrentLocation
      ? {
          latitude: position?.coords.latitude as number,
          longitude: position?.coords.longitude as number,
        }
      : null

    return {
      query: {
        limit: defaultLimit,
        ...(filtersInput.length && { filters: filtersInput }),
        ...(orderByInput.length && { orderBy: orderByInput }),
        offset: 0,
      },
      currentLocation,
      imgixOpts,
    }
  }

  return (
    <RedemptionFiltersContext.Provider
      value={{
        currentFilters,
        setFilters,
        currentSelectedVendorsForFilter,
        setSelectedVendorsForFilter,
        generateFlopEntry,
        filtersForm,
        defaultFilters: DEFAULT_REDEMPTION_FILTERS,
        buildQueryArguments,
      }}
    >
      {children}
    </RedemptionFiltersContext.Provider>
  )
}
