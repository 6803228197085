import { noop } from 'lodash-es'
import { createContext } from 'react'
import { NotificationSettingsContextType } from './NotificationSettingsContext.type'

export const NotificationSettingsContext =
  createContext<NotificationSettingsContextType>({
    notificationPreferences: undefined,
    user: undefined,
    smsVerificationCurrent: undefined,
    loading: false,
    getNotificationPreferencesForUser: () => Promise.resolve(null),
    refetch: () => noop(),
    toggleChannelPreference: () => noop(),
    toggleWorkflowPreference: () => noop(),
    toggleWorkflowChannelPreference: () => noop(),
  })
