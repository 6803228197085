import { ALL_CAUGHT_UP } from '@pages/feed/constants/allCaughtUp.constants'
import { create } from 'zustand'
import { persist } from 'zustand/middleware'

type State = {
  lastSeenItemId: string | undefined
  lastSeenTimestamp: number | undefined
}

type Action = {
  updateLastSeenItemId: (newItemId: string | undefined) => void
}

type FeedStore = State & Action

const useFeedStore = create(
  persist<FeedStore>(
    (set, get) => ({
      lastSeenItemId: undefined,
      lastSeenTimestamp: undefined,

      updateLastSeenItemId: (newItemId: string | undefined) => {
        // ! ⚡️ Short circuit if there is no new item id
        if (
          newItemId === undefined ||
          newItemId === null ||
          newItemId === ALL_CAUGHT_UP.id
        ) {
          return
        }

        // ! ⚡️ Short circuit if the new item id is the same as the last seen item id
        if (
          get().lastSeenItemId !== undefined &&
          newItemId === get().lastSeenItemId
        ) {
          return
        }

        // * 🌈 All good to go
        set({ lastSeenItemId: newItemId, lastSeenTimestamp: Date.now() })
      },
    }),
    {
      name: 'feed-store',
    }
  )
)

export default useFeedStore
