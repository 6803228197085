import { AnalyticsBrowser } from '@segment/analytics-next'
import { useMemo } from 'react'
import { AnalyticsContext, AnalyticsProviderProps } from './Analytics.context'

export const AnalyticsProvider: React.FC<AnalyticsProviderProps> = ({
  children,
}) => {
  const writeKey = import.meta.env.VITE_ROOT_SEGMENT_TOKEN ?? ''

  const value = useMemo(() => {
    if (writeKey) {
      return { analytics: AnalyticsBrowser.load({ writeKey }) }
    } else {
      return {
        analytics: undefined,
      } // Return undefined as there is no key
    }
  }, [writeKey])

  return (
    <AnalyticsContext.Provider value={value}>
      {children}
    </AnalyticsContext.Provider>
  )
}
