// https://capacitorjs.com/docs/apis/device

import { px } from '@mantine/core'
import { useMediaQuery } from '@mantine/hooks'

const breakpointMap = {
  xs: px('36em'),
  sm: px('48em'),
  md: px('62em'),
  lg: px('75em'),
  xl: px('88em'),
}

export const useBreakpoint = (
  minOrMax: 'min' | 'max',
  breakpoint: 'xs' | 'sm' | 'md' | 'lg' | 'xl',
  initialValue: boolean | undefined = undefined
) => {
  const breakpointHit = useMediaQuery(
    `(${minOrMax}-width: ${breakpointMap[breakpoint]}px)`,
    initialValue
  )

  return breakpointHit
}

export const useBreakpointHeight = (
  minOrMax: 'min' | 'max',
  breakpoint: 'xs' | 'sm' | 'md' | 'lg' | 'xl'
) => {
  const breakpointHit = useMediaQuery(
    `(${minOrMax}-height: ${breakpointMap[breakpoint]}px)`
  )

  return breakpointHit
}
