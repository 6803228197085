import { faLocationDot } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Stack, Text } from '@mantine/core'
import { BOLD, openAppSettings } from '@util/utils'

const ChangeLocationSettingsModalContent = ({
  onClose,
}: {
  onClose: () => void
}) => {
  return (
    <>
      <Stack
        align="center"
        h="100%"
        justify="center"
        gap={18}
        px={12}
        pos={'relative'}
      >
        <FontAwesomeIcon icon={faLocationDot} color="white" size="4x" />
        <Text fw={BOLD} c="white" className="text-3xl" ta="center">
          SwayDM is meant to be local
        </Text>
        <Text fw={BOLD} c="white" ta="center" className="text-lg opacity-70">
          Please update your device settings!
        </Text>
        <Stack w="100%" gap={18} pos="absolute" bottom={24}>
          <Button
            fullWidth
            color="deep-blue.9"
            onClick={() => {
              openAppSettings()
              onClose()
            }}
          >
            Update Location Settings
          </Button>
          <Button
            fullWidth
            color="deep-blue.9"
            variant="outline"
            onClick={onClose}
          >
            Maybe later
          </Button>
        </Stack>
      </Stack>
    </>
  )
}

export default ChangeLocationSettingsModalContent
