import { useWalletBalanceQuery, useWalletEventsSubscription } from '@graphql'
import { useAuth } from '@hooks/useAuth'
import { WalletContext } from './Wallet.context'

export function WalletContextProvider({
  children,
}: {
  children: React.ReactNode
}): JSX.Element {
  const { currentUser } = useAuth()
  const {
    data: walletData,
    loading,
    error,
    refetch,
  } = useWalletBalanceQuery({
    onError: (err) => {
      console.error('[Wallet Context Error: ', err)
    },
    fetchPolicy: 'cache-and-network',
    skip: !currentUser,
  })
  useWalletEventsSubscription({
    onData() {
      refetch()
    },
    onError(error) {
      console.error('[Wallet Context Error: ', error)
    },
  })

  return (
    <WalletContext.Provider
      value={{
        wallet: walletData?.wallet,
        walletLoading: loading,
        walletError: error,
        refetch,
      }}
    >
      {children}
    </WalletContext.Provider>
  )
}
