import { FilterFormValues } from '@context/redemptionFiltersContext'
import { create } from 'zustand'

type State = {
  storedFiltersFormValues: FilterFormValues | undefined
}

type Action = {
  setStoredFiltersFormValues: (
    filtersFormValues: FilterFormValues | undefined
  ) => void
}

const useRedemptionFiltersStore = create<State & Action>((set) => ({
  storedFiltersFormValues: undefined,

  setStoredFiltersFormValues: (storedFiltersFormValues) =>
    set({ storedFiltersFormValues }),
}))

export default useRedemptionFiltersStore
