import {
  ProfileType,
  Redemption,
  RedemptionMediaPreviewPartsFragment,
} from '@graphql'
import { useAuth } from '@hooks/useAuth'
import { useGetLocation } from '@hooks/useGetLocation'
import { Box, Divider } from '@mantine/core'
import { isRedemptionExpired } from '@util/redemptionsUtils'
import { formattedDistance } from '@util/utils'
import { Link } from 'react-router-dom'
import { RedemptionHorizontalCard } from './RedemptionHorizontalCard'

type RedemptionsListProps = {
  redemptions: Redemption[]
  onClickItemRedirectUrl?: string
}

const RedemptionItem = ({
  redemption,
  distance,
  onClickItemRedirectUrl,
}: {
  redemption: Redemption
  distance?: string
  onClickItemRedirectUrl: string
}) => {
  const { currentUser } = useAuth()

  const isVendor = currentUser?.profileType === ProfileType.Community

  return (
    <Link
      key={redemption.id}
      to={`${onClickItemRedirectUrl}/${redemption.id}`}
      className="relative w-full cursor-pointer"
    >
      <RedemptionHorizontalCard
        id={redemption.id}
        name={redemption.name}
        description={redemption.description}
        community={{
          communityName: redemption.vendor.name,
          profilePhotoUrl: redemption.vendor.photoUrl,
        }}
        price={{
          amount: redemption.price.amount || 0,
        }}
        redemptionMedia={redemption as RedemptionMediaPreviewPartsFragment}
        isNonProfit={redemption.isNonProfit}
        donationProgress={redemption.donationProgress}
        linkedProfile={redemption.linkedVendor}
        isExpired={isRedemptionExpired(redemption)}
        ownerProfileId={redemption.vendor.id}
        hasLimits={!!redemption.limits?.limits?.length}
        limits={redemption.limits?.limits || undefined}
        validEnd={redemption.validEnd}
        distance={distance}
        saved={!!redemption.saved}
        showSaveSection={!isVendor}
      />
    </Link>
  )
}

const redemptionPosition = (redemption: Redemption) => {
  return {
    latitude: redemption.location?.lat as number,
    longitude: redemption.location?.lng as number,
  }
}

export const RedemptionsList = ({
  redemptions,
  onClickItemRedirectUrl = `/redemptions/offers`,
}: RedemptionsListProps) => {
  const { position } = useGetLocation()

  const userPosition = {
    latitude: position?.coords.latitude as number,
    longitude: position?.coords.longitude as number,
  }

  return (
    <>
      {redemptions.map((redemption, index) => (
        <Box px={6} key={redemption.id}>
          <RedemptionItem
            redemption={redemption}
            distance={formattedDistance(
              userPosition,
              redemptionPosition(redemption)
            )}
            key={redemption.id}
            onClickItemRedirectUrl={onClickItemRedirectUrl}
          />
          {index !== redemptions.length - 1 && <Divider key={index} my={6} />}
        </Box>
      ))}
    </>
  )
}
