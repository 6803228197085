import { FeedItemNotification, FeedNotificationType } from '@graphql'

export const ALL_CAUGHT_UP: FeedItemNotification = {
  __typename: 'FeedItemNotification',
  id: 'all-caught-up',
  addedAt: new Date().toISOString(),
  notification: {
    body: 'You’re all caught up!',
    title: 'All caught up',
    type: FeedNotificationType.Message,
  },
}
