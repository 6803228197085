import { faMinus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Drawer, Modal, Stack, Text } from '@mantine/core'
import { AppStoreLinks } from './AppStoreLinks'

export const GetAppModal = ({
  show,
  closeModal,
}: {
  show: boolean
  closeModal: () => void
}) => {
  return (
    <Modal opened={show} onClose={closeModal} title="Get the SwayDM App">
      <Stack className="items-center space-y-2 pt-2 text-center">
        <Text>Scan this QR code to download the app now</Text>
        <div>
          <img
            className="w-64"
            src="https://link.sway.dm/e/app-store/ua?qrcode"
            alt="Downlaod the App QR Code"
          />
        </div>
        <Text>Or check it out in the app stores</Text>
        <AppStoreLinks />
      </Stack>
    </Modal>
  )
}

export const GetAppSheetModal = ({
  show,
  closeModal,
}: {
  show: boolean
  closeModal: () => void
}) => {
  return (
    <Drawer opened={show} onClose={() => closeModal()} position="bottom">
      <div className="pointer-events-none absolute inset-0 w-full">
        <FontAwesomeIcon icon={faMinus} />
      </div>
      <div className="grid h-full content-center">
        <div className="flex h-full flex-col items-center space-y-2 p-8 text-center">
          <Text>Scan this QR code to download the app now</Text>
          <div>
            <img
              className="w-64"
              src="https://link.sway.dm/e/app-store/ua?qrcode"
              alt="Downlaod the App QR Code"
            />
          </div>
          <Text>Or check it out in the app stores</Text>
          <div className="flex items-center space-x-4">
            <a href="https://link.sway.dm/e/app-store/android">
              <img
                className="w-[200px]"
                alt="Get it on Google Play"
                src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
              />
            </a>
            <a href="https://link.sway.dm/e/app-store/ios">
              <img
                className="w-[175px]"
                alt="Get it on Apple App Store"
                src="https://sway-dm-prod.s3.amazonaws.com/images/app-store-badge.png"
              />
            </a>
          </div>
        </div>
      </div>
    </Drawer>
  )
}
