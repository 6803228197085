import { NavigateFunction } from 'react-router-dom'
import { notifications } from './notifications/notifications'

export interface RedirectResponse {
  status: 'ok' | 'error'
  reason?: string
  type?: 'profile' | 'redemption' | 'uri'
  redirect_profile?: {
    id: string
    display_name: string
    profile_type: 'community' | 'community_follower'
  }
  redirect_redemption?: string
  redirect_uri?: {
    host: string
    path: string
    query: string
    fragment: string
  }
}

export const resolveShortLink = async (
  shortCode: string
): Promise<RedirectResponse | null> => {
  try {
    const response = await fetch(
      `${import.meta.env.VITE_ROOT_API_SERVER_URL}/links/resolve/${shortCode}`
    )

    if (response.status == 200) {
      return (await response.json()) as RedirectResponse
    } else {
      return null
    }
  } catch (error) {
    console.error('Failed to resolve short link', error)
    notifications.show({
      title: 'Unexpected Error',
      message: 'There was an error resolving this short link.',
      color: 'red',
    })
    return null
  }
}

export const isShortLink = (url: URL | string) =>
  new URL(url).pathname.startsWith('/s/')
export const getShortCode = (url: URL | string) =>
  new URL(url).pathname.split('/s/').pop() || ''

export const resolveAndNavigate = async (
  url: URL | string,
  navigate: NavigateFunction
) => {
  try {
    const parsedUrl = new URL(url)
    // Extract the short code from the url using pathname so no query params are included.
    const shortCode = getShortCode(parsedUrl)
    resolveShortLink(shortCode).then((redirect: RedirectResponse | null) => {
      switch (redirect?.type) {
        case 'profile': {
          // redirect to profile
          navigate(`/profile/${redirect.redirect_profile?.display_name}`)
          break
        }
        case 'redemption': {
          // redirect to redemption
          navigate(`/redemptions/offers/${redirect.redirect_redemption}`)
          break
        }
        case 'uri': {
          // redirect to uri
          const uri = redirect.redirect_uri
          navigate(
            `${uri?.path}${uri?.query ? '?' + uri?.query : ''}${uri?.fragment ? '#' + uri?.fragment : ''}`
          )
          break
        }
        default:
          console.error('Failed to resolve and navigate short link', redirect)
          notifications.show({
            title: 'Unexpected Error',
            message: 'There was an error resolving this short link.',
            color: 'red',
          })
      }
    })
  } catch (error) {
    console.error('Failed to resolve and navigate short link', error)
    notifications.show({
      title: 'Unexpected Error',
      message: 'There was an error resolving this short link.',
      color: 'red',
    })
  }
}

export const trackUri = async (
  uri: string
): Promise<RedirectResponse | null> => {
  try {
    try {
      const response = await fetch(
        `${import.meta.env.VITE_ROOT_API_SERVER_URL}/links/track`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ uri }),
        }
      )

      if (response.status == 200) {
        return (await response.json()) as RedirectResponse
      } else {
        return null
      }
    } catch (error) {
      console.error('Failed to track uri', error)
      return null
    }
  } catch (error) {
    console.error('Failed to track uri', error)
    return null
  }
}
