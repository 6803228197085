import { AppShell, Flex, Text, Title } from '@mantine/core'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

export const AlreadyRegistered = () => {
  const navigate = useNavigate()

  useEffect(() => {
    const timeout = setTimeout(() => {
      navigate('/')
    }, 5000)
    return () => {
      clearTimeout(timeout)
    }
  }, [navigate])

  return (
    <AppShell.Main>
      <Flex direction="column" align="center" justify="center">
        <Title order={1} className="text-2xl font-bold">
          You’re already a Member!
        </Title>
        <Text size="lg">
          Redirecting back to conversations page in 5 seconds...
        </Text>
      </Flex>
    </AppShell.Main>
  )
}
