import {
  FilterFormValues,
  useRedemptionFiltersContext,
} from '@context/redemptionFiltersContext'
import { faFilter } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { MediaOption } from '@graphql'
import { useIsMobileViewport } from '@hooks/useIsMobileViewport'
import { ActionIcon, Box, Group, Text, Transition } from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import { useEffect, useState } from 'react'
import { RedemptionTabs } from '../RedemptionTabs'
import { RedemptionsFilters } from './RedemptionsFilters'
import { RedemptionsFiltersBadges } from './RedemptionsFiltersBadges'
import { SearchInput } from './SearchInput'

export const RedemptionsPageHeader = ({
  refetchRedemptions,
  handleFiltersFormRefetch,
  setSearchValue,
  searchValue,
  imgixOpts,
  redemptionsLength,
}: {
  refetchRedemptions: (queryArguments: any) => void
  handleFiltersFormRefetch: (newFilters: FilterFormValues) => void
  setSearchValue: React.Dispatch<React.SetStateAction<string>>
  searchValue: string
  imgixOpts: MediaOption[]
  redemptionsLength: number
}) => {
  const [mobileSearchActive, setMobileSearchActive] = useState<boolean>(false)
  const { currentFilters, setFilters, filtersForm } =
    useRedemptionFiltersContext()

  const isMobileViewport = useIsMobileViewport()

  const [opened, { open, close }] = useDisclosure(false, {
    onClose: () => {
      // Submiting the form when the filter drawer is closed
      filtersForm.onSubmit((values: FilterFormValues) => {
        handleFiltersFormRefetch(values)
      })()
    },
  })

  // The redemptions mobile search input should only be enabled on mobile viewports.
  useEffect(() => {
    if (!isMobileViewport) setMobileSearchActive(false)
  }, [isMobileViewport])

  return (
    <Box>
      <RedemptionTabs />
      <RedemptionsFilters opened={opened} close={close} />
      <Group wrap="nowrap" mt={8} mx={16}>
        <ActionIcon variant="transparent" onClick={open}>
          <FontAwesomeIcon icon={faFilter} />
        </ActionIcon>
        <SearchInput
          searchValue={searchValue}
          setSearchValue={setSearchValue}
          placeholder="Search by keyword or name"
        />
      </Group>

      <Transition mounted={mobileSearchActive}>
        {(styles) => (
          <Box style={styles} px={4} mt={8} mb={4}>
            <SearchInput
              searchValue={searchValue}
              setSearchValue={setSearchValue}
              placeholder="Search by keyword or name"
              showOnFocusConfirmButton={true}
            />
            {searchValue !== '' && (
              <Box mt={8}>
                <Text>
                  Search results for <b>"{searchValue}"</b> ({redemptionsLength}
                  ){' '}
                </Text>
              </Box>
            )}
          </Box>
        )}
      </Transition>

      <RedemptionsFiltersBadges
        currentFilters={currentFilters}
        setFilters={setFilters}
        refetchRedemptions={refetchRedemptions}
        removeFilter={(newFilters: FilterFormValues): void =>
          handleFiltersFormRefetch(newFilters)
        }
        imgixOpts={imgixOpts}
      />
    </Box>
  )
}
