import { InputLabelSectionHeader } from '@components/InputLabelSectionHeader/InputLabelSectionHeader'
import { InputMaybe } from '@graphql'
import { SwayCashIcon } from '@icons/SwayCash'
import { Box, Text } from '@mantine/core'
import { UseFormReturnType } from '@mantine/form'
import { BroadcastFormValues } from '../BroadcastContext'

type BroadcasCostProps = {
  form: UseFormReturnType<
    BroadcastFormValues,
    (values: BroadcastFormValues) => BroadcastFormValues
  >
  searchingTargetMembers: boolean
  totalNumberOfBroadcastTargets: number | null | undefined
}

export const BroadcastCost = ({
  form,
  searchingTargetMembers,
  totalNumberOfBroadcastTargets,
}: BroadcasCostProps) => {
  function getMaximumSpendForBroadcast(
    broadcastPrice: number,
    followUpMessagePrice: InputMaybe<number> | undefined,
    totalNumberOfBroadcastTargets: number
  ) {
    const messageCostText = (
      <Text component="span">
        <SwayCashIcon className="inline-block h-4 w-4" />{' '}
        {(broadcastPrice / 100).toFixed(2)} per message
      </Text>
    )

    const followUpText = followUpMessagePrice ? (
      <Text component="span">
        <SwayCashIcon className="inline-block h-4 w-4" />{' '}
        {(followUpMessagePrice / 100).toFixed(2)} per follow-up message
      </Text>
    ) : null

    const totalCost = followUpMessagePrice
      ? (broadcastPrice + followUpMessagePrice) * totalNumberOfBroadcastTargets
      : broadcastPrice * totalNumberOfBroadcastTargets

    const totalCostText = (
      <Text component="span">
        <SwayCashIcon className="inline-block h-4 w-4" />{' '}
        {(totalCost / 100).toFixed(2)} SC
      </Text>
    )

    return (
      <>
        {messageCostText}
        {followUpText && (
          <>
            {' '}
            {' + '}
            {followUpText}{' '}
          </>
        )}
        {' = '}
        {totalCostText}
      </>
    )
  }

  return (
    <>
      <InputLabelSectionHeader>
        Maximum Spend For Broadcast
      </InputLabelSectionHeader>
      <Box h={30} mb={12}>
        {searchingTargetMembers ? (
          <Text>Calculating total cost of broadcast...</Text>
        ) : totalNumberOfBroadcastTargets &&
          form.values.messageTemplate.price ? (
          <Text>
            {`${totalNumberOfBroadcastTargets.toLocaleString()} recipients x `}
            {getMaximumSpendForBroadcast(
              form.values.messageTemplate.price,
              form.values.followUpMessageTemplate?.price,
              totalNumberOfBroadcastTargets
            )}
          </Text>
        ) : (
          <Text>Unable to calculate total cost of this broadcast.</Text>
        )}
      </Box>
    </>
  )
}
