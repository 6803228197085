import { useIsMobileViewport } from '@hooks/useIsMobileViewport'
import { Box, Grid, ScrollArea } from '@mantine/core'
import { cn } from '@util/utils'
import React from 'react'
import { Outlet } from 'react-router-dom'

interface DualPageLayoutProps {
  children: React.ReactNode
  mobileToggle?: boolean
  outletNoScroll?: boolean
}

const fullHeightCalc = `calc(100vh - calc(var(--app-shell-header-offset, 0px) + var(--app-shell-padding)) - calc(var(--app-shell-footer-offset, 0px) + var(--app-shell-padding)))`

export const DualPageLayout = ({
  children,
  mobileToggle,
  outletNoScroll = false,
}: DualPageLayoutProps) => {
  const isMobileViewport = useIsMobileViewport()

  return (
    <Box w="100%">
      <Grid overflow="hidden" gutter={'0px'}>
        <Grid.Col
          span={{ base: 12, md: 5 }}
          hidden={isMobileViewport && !!mobileToggle}
        >
          <ScrollArea.Autosize offsetScrollbars h={fullHeightCalc}>
            {children}
          </ScrollArea.Autosize>
        </Grid.Col>
        {(!isMobileViewport || mobileToggle) && (
          <Grid.Col
            span={{ base: 12, md: 7 }}
            h={fullHeightCalc}
            className={cn('transition-height', !isMobileViewport && 'border-l')}
          >
            {!outletNoScroll && (
              <ScrollArea.Autosize offsetScrollbars h={fullHeightCalc}>
                <Outlet />
              </ScrollArea.Autosize>
            )}
            {outletNoScroll && <Outlet />}
          </Grid.Col>
        )}
      </Grid>
    </Box>
  )
}
