import { createContext } from 'react'

interface PushNotificationsContextType {
  deviceHasPushNotificationsEnabled: boolean
  turnOnPushNotifications: () => Promise<void>
}

export const PushNotificationsContext =
  createContext<PushNotificationsContextType>({
    deviceHasPushNotificationsEnabled: false,
    turnOnPushNotifications: () => Promise.resolve(),
  })
