import { SwayError } from '@components/swayStates/SwayError'
import * as Sentry from '@sentry/react'

export const VendorContentRoutes = [
  {
    path: 'content',
    children: [
      {
        path: 'trivia-polls',
        children: [
          {
            path: '',
            async lazy() {
              const { TriviaAndPollsPage } = await import(
                './triviaAndPolls/TriviaAndPolls.page'
              )
              return {
                element: (
                  <Sentry.ErrorBoundary
                    fallback={SwayError}
                    beforeCapture={(scope) => {
                      scope.setTag('location', 'TriviaRoutes')
                    }}
                  >
                    <TriviaAndPollsPage />
                  </Sentry.ErrorBoundary>
                ),
              }
            },
          },
          {
            path: 'create-trivia/:id?',
            async lazy() {
              const { CreateTriviaPage } = await import(
                './triviaAndPolls/CreateTrivia.page'
              )
              return {
                element: (
                  <Sentry.ErrorBoundary
                    fallback={SwayError}
                    beforeCapture={(scope) => {
                      scope.setTag('location', 'CreateTriviaRoutes')
                    }}
                  >
                    <CreateTriviaPage />
                  </Sentry.ErrorBoundary>
                ),
              }
            },
          },
          {
            path: 'create-poll/:id?',
            async lazy() {
              const { CreatePollPage } = await import(
                './triviaAndPolls/CreatePoll.page'
              )
              return {
                element: (
                  <Sentry.ErrorBoundary
                    fallback={SwayError}
                    beforeCapture={(scope) => {
                      scope.setTag('location', 'CreatePollRoutes')
                    }}
                  >
                    <CreatePollPage />
                  </Sentry.ErrorBoundary>
                ),
              }
            },
          },
        ],
      },
    ],
  },
]
