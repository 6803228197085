import { InputLabelSectionHeader } from '@components/InputLabelSectionHeader/InputLabelSectionHeader'
import { Broadcast, BroadcastInput, BroadcastStatus, User } from '@graphql'
import { Center, Checkbox, Indicator, Switch, Text } from '@mantine/core'
import { DateTimePicker } from '@mantine/dates'
import { UseFormReturnType } from '@mantine/form'
import { SEMI_BOLD } from '@util/utils'
import { BroadcastFormValues } from '../BroadcastContext'

type BroadcasScheduleProps = {
  form: UseFormReturnType<
    BroadcastFormValues,
    (values: BroadcastFormValues) => BroadcastFormValues
  >
  broadcasts: Broadcast[] | undefined
  user: User | undefined
}

export const BroadcastSchedule = ({
  form,
  broadcasts,
  user,
}: BroadcasScheduleProps) => {
  return (
    <>
      <InputLabelSectionHeader>
        Schedule Broadcast{' '}
        <Text component="span" c="dimmed">
          (Optional)
        </Text>
      </InputLabelSectionHeader>
      <Text>
        {form.values.isPersistent
          ? 'You can go live now, or schedule this Persistent Broadcast to go live at a future date and time.'
          : 'You can send this Broadcast now, or schedule it to send at a future date and time.'}
      </Text>
      <Checkbox
        label={
          <InputLabelSectionHeader>
            {form.values.isPersistent ? 'Go Live Now' : 'Send immediately'}
          </InputLabelSectionHeader>
        }
        {...form.getInputProps('sendImmediately')}
        onChange={(e) => {
          form.setFieldValue('sendImmediately', e.target.checked)
          if (e.target.checked) {
            form.setFieldValue('broadcastStart', new Date())
          } else {
            form.setFieldValue('broadcastStart', null)
          }
        }}
      />
      <DateTimePicker
        clearable
        description="Dates with scheduled broadcasts have a blue indicator"
        disabled={!!form.values.sendImmediately}
        dropdownType="popover"
        firstDayOfWeek={0}
        label={
          <InputLabelSectionHeader>
            {form.values.isPersistent ? 'Live at' : 'Send at'}
          </InputLabelSectionHeader>
        }
        placeholder="Pick date and time"
        valueFormat="DD MMM YYYY hh:mm A"
        pointer
        required={!form.values.sendImmediately}
        renderDay={(date) =>
          RenderDateTimeDay(
            date,
            form.values,
            broadcasts as Array<Broadcast> | undefined
          )
        }
        {...form.getInputProps('broadcastStart')}
      />
      <InputLabelSectionHeader>
        Timezone:{' '}
        <Text component="span" c="dark">
          {user?.profile?.timezone || 'UTC'}
        </Text>
      </InputLabelSectionHeader>

      <Text size="sm" fw={SEMI_BOLD}>
        Persistent
      </Text>
      <Switch
        label="Persistent Broadcast"
        {...form.getInputProps('isPersistent', {
          type: 'checkbox',
        })}
      />
    </>
  )
}

function RenderDateTimeDay(
  date: Date,
  formValues: BroadcastInput,
  broadcasts: Array<Broadcast> | undefined
) {
  const dayIsToday = date.toDateString() === new Date().toDateString()
  const dayIsCurrentlySelected =
    date.toDateString() === new Date(formValues.broadcastStart).toDateString()
  const day = date.getDate()
  const broadcastScheduledOnThisDay = broadcasts?.some((broadcast) => {
    const broadcastScheduledDate =
      broadcast.broadcastStart || broadcast.broadcastPublishedAt
    return (
      new Date(broadcastScheduledDate).toDateString() === date.toDateString() &&
      broadcast.status !== BroadcastStatus.Archived
    )
  })
  return (
    <Center
      bg={dayIsToday && !dayIsCurrentlySelected ? 'teal.1' : 'transparent'}
      w="100%"
      h="100%"
      style={{
        borderRadius: 2,
      }}
    >
      <Indicator
        size={6}
        color="blue"
        offset={-2}
        disabled={!broadcastScheduledOnThisDay}
      >
        <div>{day}</div>
      </Indicator>
    </Center>
  )
}
