import { SwayError } from '@components/swayStates/SwayError'
import * as Sentry from '@sentry/react'

export const VendorDashboardRoutes = [
  {
    path: 'dashboard',
    async lazy() {
      const { VendorDashboard } = await import('./VendorDashboard.page')
      return {
        element: (
          <Sentry.ErrorBoundary
            fallback={SwayError}
            beforeCapture={(scope) => {
              scope.setTag('location', 'VendorDashboard')
            }}
          >
            <VendorDashboard />
          </Sentry.ErrorBoundary>
        ),
      }
    },
  },
]
