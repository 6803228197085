import { SwayError } from '@components/swayStates/SwayError'
import * as Sentry from '@sentry/react'

export const MemberReferralsRoutes = [
  {
    path: 'referrals',
    async lazy() {
      const { MemberReferralsPage } = await import('./MemberReferrals.page')
      return {
        element: (
          <Sentry.ErrorBoundary
            fallback={SwayError}
            beforeCapture={(scope) => {
              scope.setTag('location', 'MemberReferralsRoutes')
            }}
          >
            <MemberReferralsPage />
          </Sentry.ErrorBoundary>
        ),
      }
    },
  },
]

export const VendorReferralsRoutes = []
