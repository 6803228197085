import React from 'react'
import {
  UploadMediaContext,
  UploadMediaContextType,
} from './UploadMedia.context'

export default function useUploadMediaContext(): UploadMediaContextType {
  const context = React.useContext(UploadMediaContext)
  if (context === undefined) {
    throw new Error(
      'useUploadMediaContext must be used within a UploadMediaContextProvider'
    )
  }
  return context
}
