import { ApolloClient, ApolloLink, createHttpLink, split } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'
import { GraphQLWsLink } from '@apollo/client/link/subscriptions'
import { getMainDefinition } from '@apollo/client/utilities'
import { Capacitor } from '@capacitor/core'
import { createCache } from '@util/apollo'
import { createClient } from 'graphql-ws'
import { useEffect, useRef, useState } from 'react'
import { TEN_MINUTES } from '../constants'
import { useAuth } from './useAuth'

let hookHasBeenUsed = false

const ROOT_API_SERVER_URL = import.meta.env.VITE_ROOT_API_SERVER_URL
const ROOT_WEBSOCKET_URL = import.meta.env.VITE_ROOT_WS_SERVER_URL

const LOGOUT_SUBCODES = ['missing_credentials', 'invalid_credentials']

/**
 * * Our version of the Apollo Client configured for Sway.
 * ! This is a hook, and so if it is used in multiple places, it will return a unique client for each place it is used.
 * ? If you need to share a client between multiple components, you should use Apollo's context provider.
 * @returns The Apollo Client with the correct configuration for the Sway API
 */
export function useSwayApolloClient() {
  useEffect(function checkIfAlreadyUsed() {
    if (hookHasBeenUsed) {
      throw new Error(
        'useSwayApolloClient has been used more than once. You should use ApolloProvider to share the client between multiple components.'
      )
    }

    hookHasBeenUsed = true

    return () => {
      hookHasBeenUsed = false
    }
  }, [])

  const { currentUser, onLogout } = useAuth()
  const authToken = currentUser?.accessToken?.value

  const [client, setClient] = useState<ApolloClient<any> | null>(null)
  const clientRef = useRef<ApolloClient<any> | null>(null)
  const authTokenRef = useRef(authToken)

  useEffect(() => {
    authTokenRef.current = authToken
  }, [authToken])

  useEffect(() => {
    let didCancel = false

    const initializeClient = async () => {
      if (clientRef.current) {
        // * Stop the existing client to prevent further queries
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        clientRef.current!.stop()

        // * This clears the store to prep stopping the client
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        await clientRef.current!.clearStore()
      }

      const cache = createCache()

      const authLink = setContext((_, { headers }) => ({
        headers: {
          ...headers,
          ...(authToken ? { Authorization: `Bearer ${authToken}` } : {}),
        },
      }))

      const deviceLink = setContext((_, { headers }) => ({
        headers: {
          ...headers,
          'Device-Source': Capacitor.getPlatform() || '',
          'Access-Control-Request-Headers': 'Device-Source',
        },
      }))

      const httpLink = createHttpLink({
        uri: `${ROOT_API_SERVER_URL}/graphql`,
        credentials: 'include',
        fetchOptions: {
          mode: 'cors',
        },
      })

      const wsClient = authToken
        ? createClient({
            url: `${ROOT_WEBSOCKET_URL}/graphql-ws`,
            connectionParams: {
              Authorization: `Bearer ${authToken}`,
            },
            shouldRetry: () => true,
            retryAttempts: 20,
            on: {
              connected: () => {
                console.info('WebSocket connected')
              },
              closed: () => {
                console.info('WebSocket disconnected')
              },
              error: (err) => {
                console.error('[Apollo Link] WebSocket error', err)
              },
            },
          })
        : null

      const webSocketLink = wsClient ? new GraphQLWsLink(wsClient) : null

      const splitLink = webSocketLink
        ? split(
            ({ query }) => {
              const definition = getMainDefinition(query)
              return (
                definition.kind === 'OperationDefinition' &&
                definition.operation === 'subscription'
              )
            },
            webSocketLink,
            httpLink
          )
        : httpLink

      const errorLink = onError(({ graphQLErrors, networkError }) => {
        if (graphQLErrors) {
          graphQLErrors.forEach((error) => {
            const {
              message,
              locations,
              path,
              code,
              sub_code,
            }: {
              message: string
              locations: Array<{ line: number; column: number }>
              path: Array<string | number>
              code: string
              sub_code: string
            } = error as any

            console.error(
              `[GraphQL error]: Message: ${message}, Location: ${locations?.toString()}, Path: ${path?.toString()}, Code: ${code}, Subcode: ${sub_code}`
            )

            if (
              code === 'authentication' &&
              LOGOUT_SUBCODES.includes(sub_code)
            ) {
              onLogout(currentUser?.id, {
                isExpiredSession: true,
              })
            }
          })
        }

        if (networkError) {
          console.error(`[Network error]: ${networkError}`)
        }
      })

      const newClient = new ApolloClient({
        cache,
        link: ApolloLink.from([errorLink, authLink, deviceLink, splitLink]),
        connectToDevTools: process.env.NODE_ENV !== 'production',
        defaultOptions: {
          watchQuery: {
            pollInterval: TEN_MINUTES,
            fetchPolicy: 'network-only',
            nextFetchPolicy: 'cache-and-network',
          },
        },
      })

      if (!didCancel) {
        clientRef.current = newClient
        setClient(newClient)
      }
    }

    initializeClient()

    return () => {
      didCancel = true
    }
    // * We only want to trigger this when the authToken changes. Any other changes
    // * we want to ignore
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authToken])

  return client
}
