import { useContext } from 'react'
import { CameraContext } from './Camera.context'

export const useCameraContext = () => {
  const context = useContext(CameraContext)

  if (context === undefined)
    throw new Error('useCamera must be used within a CameraProvider')

  return context
}
