import { SwayError } from '@components/swayStates/SwayError'
import * as Sentry from '@sentry/react'

export const PublicProfileRoutes = [
  {
    path: 'profile/:displayName',
    async lazy() {
      const { PublicVendorProfilePage } = await import(
        './PublicVendorProfilePage'
      )
      return {
        element: (
          <Sentry.ErrorBoundary
            fallback={SwayError}
            beforeCapture={(scope) => {
              scope.setTag('location', 'PublicVendorProfilePage')
            }}
          >
            <PublicVendorProfilePage />
          </Sentry.ErrorBoundary>
        ),
      }
    },
  },
]

export const ProfileRoutes = [
  {
    path: 'profile/:displayName',
    async lazy() {
      const { ProfilePage } = await import('./ProfilePage')
      return {
        element: (
          <Sentry.ErrorBoundary
            fallback={SwayError}
            beforeCapture={(scope) => {
              scope.setTag('location', 'ProfilePage')
            }}
          >
            <ProfilePage />
          </Sentry.ErrorBoundary>
        ),
      }
    },
  },
]
