import React, { SVGAttributes } from 'react'

interface SwashCashIconProps extends SVGAttributes<SVGSVGElement> {
  title?: string
  titleId?: string
  allFilled?: boolean
}

export const SwayCashIcon = React.forwardRef<SVGSVGElement, SwashCashIconProps>(
  ({ title, titleId, className, color, allFilled, ...props }, ref) => {
    return (
      <svg
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        width="100%"
        viewBox="0 0 584 578"
        xmlSpace="preserve"
        aria-hidden="true"
        ref={ref}
        aria-labelledby={titleId}
        className={className}
        style={
          color
            ? { color }
            : { color: 'var(--mantine-color-swurple-light-color)' }
        }
        {...props}
      >
        {title ? <title id={titleId}>{title}</title> : null}
        <path
          opacity="0.000000"
          stroke="none"
          d="M585.000000,215.000000
          C585.000000,336.666656 585.000000,457.833344 585.000000,579.000000
          C390.333344,579.000000 195.666672,579.000000 1.000000,579.000000
          C1.000000,386.333344 1.000000,193.666672 1.000000,1.000000
          C195.666672,1.000000 390.333344,1.000000 585.000000,1.000000
          C585.000000,72.166664 585.000000,143.333328 585.000000,215.000000
        M306.451721,471.910278
          C281.149445,512.850525 243.787857,540.036255 201.701508,562.872314
          C203.585983,563.597107 204.609222,564.058533 205.672943,564.389954
          C248.151260,577.624817 291.372314,581.029236 335.370331,574.187866
          C392.010742,565.380798 441.899475,541.995239 484.494781,503.751495
          C568.275574,428.529694 600.705322,312.500916 567.401123,204.865646
          C544.069641,129.461060 497.058838,72.829704 427.755585,34.798347
          C424.724548,33.134998 422.409668,33.041996 419.517303,34.769424
          C412.515381,38.951229 405.251160,42.702209 398.324982,47.001148
          C369.855621,64.671509 344.836243,86.085510 326.202972,114.311371
          C324.989380,116.149719 323.955750,118.106842 322.662109,120.308784
          C352.395477,126.309456 379.189728,137.347580 403.917419,153.276199
          C424.067169,166.255920 442.573273,181.095337 456.227722,201.248657
          C458.501221,204.604263 458.564331,206.815964 455.627228,209.760696
          C448.689911,216.716080 442.074463,223.992249 435.317688,231.128082
          C428.036072,238.818161 420.741730,246.496201 413.109100,254.541916
          C411.443695,252.344254 410.167114,250.637878 408.868042,248.948822
          C388.469055,222.425552 363.074188,202.643448 331.248840,191.607422
          C321.302399,188.158310 311.135529,185.999344 300.235962,187.211136
          C300.071869,188.563675 299.917755,189.679306 299.803467,190.799011
          C298.271027,205.813324 300.390961,220.647568 301.442413,235.584671
          C302.383514,248.953918 308.392212,256.731873 319.848175,263.393433
          C342.912292,276.805054 364.503845,292.551331 382.249634,312.851288
          C398.529236,331.473969 408.983490,352.273041 405.064484,377.984558
          C398.397644,421.724121 370.557098,456.867035 319.639893,462.726837
          C313.236084,463.463806 309.005646,465.268066 306.451721,471.910278
        M4.212301,309.049194
          C4.829665,314.834473 5.256115,320.647583 6.095872,326.400391
          C10.955286,359.690125 20.810593,391.395325 36.395878,421.271027
          C62.856823,471.994476 100.899582,511.685699 150.218216,540.565491
          C151.993149,541.604797 155.215668,541.892822 156.970566,540.989807
          C187.071838,525.501648 214.729446,506.611481 237.338654,481.105286
          C243.790390,473.826874 249.382446,465.786469 255.812561,457.525177
          C227.250916,450.015564 201.369949,439.416351 177.323578,424.560547
          C153.260284,409.694336 131.133026,392.632294 114.537659,368.799408
          C130.044647,352.591797 145.277573,336.670654 160.726700,320.523529
          C191.874573,358.392029 229.833557,383.977783 278.034119,393.828247
          C278.483582,391.918213 278.802979,390.991882 278.910187,390.041595
          C280.997894,371.534790 279.243042,353.206726 276.838867,334.838196
          C275.863251,327.384094 272.479614,323.029602 266.124390,319.102905
          C242.721542,304.642914 220.179153,288.937256 202.278397,267.610596
          C191.156281,254.359970 182.625610,239.731277 182.073318,221.940155
          C180.857117,182.761841 202.650009,138.833237 251.459045,124.126122
          C260.702240,121.340965 266.191956,116.852409 270.800629,108.388123
          C289.680908,73.712822 318.427032,48.504902 351.341888,27.652853
          C358.448334,23.150814 365.760864,18.974058 373.251007,14.484080
          C202.335602,-37.750774 -8.748354,89.896233 4.212301,309.049194
        z"
        />
        <path
          fill={allFilled ? 'currentColor' : '#2DA8B1'}
          opacity="1.000000"
          stroke="none"
          d="
          M306.645691,471.599609
            C309.005646,465.268066 313.236084,463.463806 319.639893,462.726837
            C370.557098,456.867035 398.397644,421.724121 405.064484,377.984558
            C408.983490,352.273041 398.529236,331.473969 382.249634,312.851288
            C364.503845,292.551331 342.912292,276.805054 319.848175,263.393433
            C308.392212,256.731873 302.383514,248.953918 301.442413,235.584671
            C300.390961,220.647568 298.271027,205.813324 299.803467,190.799011
            C299.917755,189.679306 300.071869,188.563675 300.235962,187.211136
            C311.135529,185.999344 321.302399,188.158310 331.248840,191.607422
            C363.074188,202.643448 388.469055,222.425552 408.868042,248.948822
            C410.167114,250.637878 411.443695,252.344254 413.109100,254.541916
            C420.741730,246.496201 428.036072,238.818161 435.317688,231.128082
            C442.074463,223.992249 448.689911,216.716080 455.627228,209.760696
            C458.564331,206.815964 458.501221,204.604263 456.227722,201.248657
            C442.573273,181.095337 424.067169,166.255920 403.917419,153.276199
            C379.189728,137.347580 352.395477,126.309456 322.662109,120.308784
            C323.955750,118.106842 324.989380,116.149719 326.202972,114.311371
            C344.836243,86.085510 369.855621,64.671509 398.324982,47.001148
            C405.251160,42.702209 412.515381,38.951229 419.517303,34.769424
            C422.409668,33.041996 424.724548,33.134998 427.755585,34.798347
            C497.058838,72.829704 544.069641,129.461060 567.401123,204.865646
            C600.705322,312.500916 568.275574,428.529694 484.494781,503.751495
            C441.899475,541.995239 392.010742,565.380798 335.370331,574.187866
            C291.372314,581.029236 248.151260,577.624817 205.672943,564.389954
            C204.609222,564.058533 203.585983,563.597107 201.701508,562.872314
            C243.787857,540.036255 281.149445,512.850525 306.645691,471.599609
          z"
        />
        <path
          fill="currentColor"
          opacity="1.000000"
          stroke="none"
          d="
          M4.160861,308.585083
            C-8.748354,89.896233 202.335602,-37.750774 373.251007,14.484080
            C365.760864,18.974058 358.448334,23.150814 351.341888,27.652853
            C318.427032,48.504902 289.680908,73.712822 270.800629,108.388123
            C266.191956,116.852409 260.702240,121.340965 251.459045,124.126122
            C202.650009,138.833237 180.857117,182.761841 182.073318,221.940155
            C182.625610,239.731277 191.156281,254.359970 202.278397,267.610596
            C220.179153,288.937256 242.721542,304.642914 266.124390,319.102905
            C272.479614,323.029602 275.863251,327.384094 276.838867,334.838196
            C279.243042,353.206726 280.997894,371.534790 278.910187,390.041595
            C278.802979,390.991882 278.483582,391.918213 278.034119,393.828247
            C229.833557,383.977783 191.874573,358.392029 160.726700,320.523529
            C145.277573,336.670654 130.044647,352.591797 114.537659,368.799408
            C131.133026,392.632294 153.260284,409.694336 177.323578,424.560547
            C201.369949,439.416351 227.250916,450.015564 255.812561,457.525177
            C249.382446,465.786469 243.790390,473.826874 237.338654,481.105286
            C214.729446,506.611481 187.071838,525.501648 156.970566,540.989807
            C155.215668,541.892822 151.993149,541.604797 150.218216,540.565491
            C100.899582,511.685699 62.856823,471.994476 36.395878,421.271027
            C20.810593,391.395325 10.955286,359.690125 6.095872,326.400391
            C5.256115,320.647583 4.829665,314.834473 4.160861,308.585083
          z"
        />
      </svg>
    )
  }
)
