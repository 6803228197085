import React from 'react'
import { NotificationSettingsContext } from './NotificationSettings.context'
import { NotificationSettingsContextType } from './NotificationSettingsContext.type'

export default function useNotificationSettingsContext(): NotificationSettingsContextType {
  const context = React.useContext(NotificationSettingsContext)
  if (context === undefined) {
    throw new Error(
      'useNotificationSettingsContext must be used within a NotificationSettingsContextProvider'
    )
  }
  return context
}
