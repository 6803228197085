import '@mantine/notifications/styles.css'

import { Capacitor } from '@capacitor/core'
import { Preferences } from '@capacitor/preferences'
import { FullscreenModal } from '@components/modals/FullscreenModal'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import {
  faDiamondExclamation,
  faLocationDot,
} from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useGetLocation } from '@hooks/useGetLocation'
import { useDisclosure } from '@mantine/hooks'
import { useEffect } from 'react'
import ChangeLocationSettingsModalContent from './ChangeLocationSettingsModalContent'
import { LocationPermissionModalContent } from './LocationPermissionModalContent'
import { WaitLocationPermissionModalContent } from './WaitLocationPermissionModalContent'

// * Show location service modals:
// If permissions have not been checked, open the location modal by default.
// If location modal is skipped or denied, open the wait modal.
export const LocationPermissionFullscreenModal = () => {
  const { permissions, permissionsChecked, setPermissionsChecked } =
    useGetLocation()

  const [
    locationModalOpened,
    { close: locationModalClose, open: locationModalOpen },
  ] = useDisclosure(false)

  const [waitModalOpened, { open: waitModalOpen, close: waitModalClose }] =
    useDisclosure()

  const [
    changeLocationSettingsModalOpened,
    {
      open: changeLocationSettingsModalOpen,
      close: changeLocationSettingsModalClose,
    },
  ] = useDisclosure()

  const isNativePlatform = Capacitor.isNativePlatform()

  const handleWaitModalClose = () => {
    waitModalClose()
    setPermissionsChecked(true)
  }

  const shouldOpenChangeLocationSettingsModal = async () => {
    const lastDeniedTimestamp = await Preferences.get({
      key: 'sway-location-last-denied-timestamp',
    })

    if (!lastDeniedTimestamp.value) {
      return false
    }

    const lastDeniedDate = new Date(lastDeniedTimestamp.value)
    const now = new Date()

    const diff = Math.abs(now.getTime() - lastDeniedDate.getTime())
    const diffDays = Math.ceil(diff / (1000 * 60 * 60 * 24))

    // If it has been more than 7 days since the last denied location permission
    return diffDays >= 7
  }

  useEffect(() => {
    const openModalAccordingToPermissions = async () => {
      if (!permissionsChecked && permissions?.location === 'denied') {
        waitModalOpen()
      }

      if (!permissionsChecked && permissions?.location.startsWith('prompt')) {
        locationModalOpen()
      }

      if (permissionsChecked && permissions?.location === 'denied') {
        const shouldOpen = await shouldOpenChangeLocationSettingsModal()
        shouldOpen && changeLocationSettingsModalOpen()

        await Preferences.set({
          key: 'sway-location-last-denied-timestamp',
          value: new Date().toISOString(),
        })
      }
    }

    openModalAccordingToPermissions()
  }, [
    permissionsChecked,
    permissions?.location,
    waitModalOpen,
    locationModalOpen,
    changeLocationSettingsModalOpen,
  ])

  return (
    <>
      {isNativePlatform && (
        <>
          {/* Location modal */}
          <FullscreenModal
            opened={locationModalOpened}
            backgroundIcon={
              <FontAwesomeIcon
                icon={faLocationDot as IconProp}
                color="white"
                className="h-auto w-auto"
              />
            }
            onClose={locationModalClose}
            children={
              <LocationPermissionModalContent
                fullscreen
                onClose={locationModalClose}
                onSkip={waitModalOpen}
              />
            }
          />
          {/* Wait! location modal */}
          <FullscreenModal
            opened={waitModalOpened}
            onClose={handleWaitModalClose}
            backgroundColor="#B70101"
            backgroundIcon={
              <FontAwesomeIcon
                icon={faDiamondExclamation as IconProp}
                color="white"
                className="h-auto w-auto -rotate-12"
              />
            }
            children={
              <WaitLocationPermissionModalContent
                onClose={handleWaitModalClose}
              />
            }
          />
          {/* You should change your location settings modal */}
          <FullscreenModal
            opened={changeLocationSettingsModalOpened}
            onClose={changeLocationSettingsModalClose}
            backgroundColor="#25A8AF"
            backgroundIcon={
              <FontAwesomeIcon
                icon={faLocationDot as IconProp}
                color="white"
                className="h-auto w-auto"
              />
            }
            children={
              <ChangeLocationSettingsModalContent
                onClose={changeLocationSettingsModalClose}
              />
            }
          />
        </>
      )}
    </>
  )
}
