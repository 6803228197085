import { SwayError } from '@components/swayStates/SwayError'
import * as Sentry from '@sentry/react'

export const EarnRoutes = [
  {
    path: 'earn',
    async lazy() {
      const { EarnPage } = await import('./Earn.page')
      return {
        element: (
          <Sentry.ErrorBoundary
            fallback={SwayError}
            beforeCapture={(scope) => {
              scope.setTag('location', 'EarnRoutes')
            }}
          >
            <EarnPage />
          </Sentry.ErrorBoundary>
        ),
      }
    },
  },
]
