// A constants file

export const THIRTY_SECONDS = 1000 * 30
export const FORTY_FIVE_SECONDS = 1000 * 45
export const ONE_MINUTE = 1000 * 60 * 1
export const TWO_MINUTES = 1000 * 60 * 2
export const FIVE_MINUTES = 1000 * 60 * 5
export const TEN_MINUTES = 1000 * 60 * 10
export const FIFTEEN_MINUTES = 1000 * 60 * 15

export const LOW_PRIORITY_QUERY = TEN_MINUTES
export const MEDIUM_PRIORITY_QUERY = FIVE_MINUTES
export const HIGH_PRIORITY_QUERY = TWO_MINUTES
