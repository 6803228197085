import { DualPageLayout } from '@components/layout/DualPageLayout'
import { RedemptionDisoverySkeletons } from '@components/skeletons/member/RedemptionDiscoverySkeletons'
import { SwayEmpty } from '@components/swayStates/SwayEmpty'
import { SwayLoadingOverlay } from '@components/swayStates/SwayLoadingOverlay'
import { FilterFormValues } from '@context/redemptionFiltersContext'
import { faEyeSlash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  MediaOption,
  Redemption,
  RedemptionsDiscoveryQueryVariables,
  RedemptionsSavedQueryVariables,
} from '@graphql'
import { AppShell, Box, Group, Skeleton, Stack, Title } from '@mantine/core'
import { RedemptionsList } from './RedemptionsList'
import { RedemptionsPageHeader } from './RedemptionsPageHeader'

type RedemptionDisplayProps = {
  redemptions: Redemption[]
  redemptionId?: string
  searchValue: string
  setSearchValue: React.Dispatch<React.SetStateAction<string>>
  hasPreviousData?: boolean
  isSearching: boolean
  loadingRedemptions: boolean
  thereAreMoreRedemptions: boolean
  imgixOpts: MediaOption[]
  isLoadingMoreRedemptions: boolean
  viewportRef: (node: HTMLElement | null) => void
  onClickItemRedirectUrl?: string
  refetchRedemptions: (
    variables:
      | RedemptionsDiscoveryQueryVariables
      | RedemptionsSavedQueryVariables
  ) => Promise<any>
  handleFiltersFormRefetch: (formValues: FilterFormValues) => void
}

// Displays a list of redemptions with filtering and sorting capabilities.
export const RedemptionsDisplay = ({
  // The redemptions to be displayed.
  redemptions,
  // The selected redemption id for displaying its details.
  redemptionId,
  // Search value for filtering redemptions.
  searchValue,
  setSearchValue,
  // Indicates whether the member is searching for redemptions using the search component.
  isSearching,
  // Indicates whether the redemptions query is currently loading
  loadingRedemptions,
  // Indicates whether there are more redemptions to load.
  thereAreMoreRedemptions,
  // Indicates whether there are previous redemptions being displayed.
  hasPreviousData,
  // Indicates if the redemptions are being fetched.
  isLoadingMoreRedemptions,
  // Options for configuring redemption img.
  imgixOpts,
  // Reference used for triggering the inViewport hook.
  viewportRef,
  // The URL to redirect after clicking a redemption.
  onClickItemRedirectUrl,
  // Function to refetch redemptions.
  refetchRedemptions,
  // Function to update the filters based on badge changes.
  handleFiltersFormRefetch,
}: RedemptionDisplayProps) => {
  const hasRedemptions = redemptions.length > 0

  return (
    <AppShell.Main>
      <DualPageLayout mobileToggle={!!redemptionId}>
        <Stack gap={8}>
          <RedemptionsPageHeader
            refetchRedemptions={refetchRedemptions}
            handleFiltersFormRefetch={handleFiltersFormRefetch}
            setSearchValue={setSearchValue}
            searchValue={searchValue}
            imgixOpts={imgixOpts}
            redemptionsLength={redemptions.length}
          />
          <Box pos="relative" mih={200}>
            <SwayLoadingOverlay visible={isSearching} />

            {loadingRedemptions && !hasPreviousData ? (
              <Box px={12}>
                <RedemptionDisoverySkeletons amountOfSkeletons={6} />
              </Box>
            ) : !hasRedemptions ? (
              <SwayEmpty message="No offers were found" />
            ) : (
              <Stack px={4} gap={4}>
                {redemptions?.length > 0 ? (
                  <RedemptionsList
                    redemptions={redemptions as Array<Redemption>}
                    onClickItemRedirectUrl={onClickItemRedirectUrl}
                  />
                ) : (
                  !loadingRedemptions && (
                    <Group px={24}>
                      <FontAwesomeIcon icon={faEyeSlash} className="h-7 w-7" />
                      <Title order={4}>
                        No redemptions were found to match your search
                      </Title>
                    </Group>
                  )
                )}

                {/* Box used to trigger the inViewport hook and load more redemptions when scrolled to the bottom */}
                <Box ref={viewportRef} />

                {thereAreMoreRedemptions && isLoadingMoreRedemptions && (
                  <Skeleton h={128} my={4} ml={6} />
                )}
              </Stack>
            )}
          </Box>
        </Stack>
      </DualPageLayout>
    </AppShell.Main>
  )
}
