import { Button } from '@mantine/core'
import { openAppSettings } from '@util/utils'

export const ButtonsLocationPermissionModal = ({
  requestLocationPermission,
  isLocationDenied,
  onClose,
  onSkip,
}: {
  requestLocationPermission: () => void
  isLocationDenied: boolean
  onClose: () => void
  onSkip?: () => void
}) => {
  return (
    <>
      <Button
        fullWidth
        color={'deep-blue.9'}
        onClick={() => {
          if (isLocationDenied) {
            openAppSettings()
          } else {
            requestLocationPermission()
          }
          onClose()
        }}
      >
        {isLocationDenied ? 'Update Location Settings' : 'Share Location'}
      </Button>
      <Button
        color="white"
        variant="outline"
        fullWidth
        onClick={() => {
          onClose()
          onSkip?.()
        }}
      >
        Not Right Now
      </Button>
    </>
  )
}
