import { useAuth } from '@hooks/useAuth'
import { JSX } from 'react/jsx-runtime'
import { AuthenticatedLayout } from './AuthenticatedLayout'
import { UnauthenticatedLayout } from './unauthenticated/Layout.unauthenticated'

export function Layout(
  props: JSX.IntrinsicAttributes & { children: React.ReactNode }
) {
  const { currentUser } = useAuth()

  if (!currentUser) {
    return <UnauthenticatedLayout {...props} />
  }

  return <AuthenticatedLayout {...props} />
}
