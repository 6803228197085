import { faMagnifyingGlass, faPalette } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  Button,
  Modal,
  Popover,
  TextInput,
  useMantineColorScheme,
} from '@mantine/core'
import { Spotlight, SpotlightActionData, spotlight } from '@mantine/spotlight'
import React, { useCallback, useEffect, useState } from 'react'
import { HslColorPicker } from 'react-colorful'

const CSS_VARIABLES = [
  '--background',
  '--foreground',
  '--muted',
  '--muted-foreground',
  '--popover',
  '--popover-foreground',
  '--border',
  '--input',
  '--input-foreground',
  '--disabled',
  '--disabled-foreground',
  '--card',
  '--card-foreground',
  '--primary',
  '--primary-foreground',
  '--secondary',
  '--secondary-foreground',
  '--accent',
  '--accent-foreground',
  '--destructive',
  '--destructive-foreground',
  '--success',
  '--ring',
]

export const DevCommand = () => {
  const [openThemeChanger, setOpenThemeChanger] = useState(false)
  const { colorScheme, setColorScheme } = useMantineColorScheme()

  // Toggle the menu when ⌘K is pressed
  React.useEffect(() => {
    const down = (e: KeyboardEvent) => {
      if (e.key === 'k' && (e.metaKey || e.ctrlKey)) {
        e.preventDefault()
        spotlight.open()
      }
    }

    document.addEventListener('keydown', down)
    return () => document.removeEventListener('keydown', down)
  }, [])

  const actions: SpotlightActionData[] = [
    {
      id: 'change-theme',
      label: 'Change Theme Colors',
      description: 'Change the theme colors of the application',
      onClick: () => {
        setOpenThemeChanger(true)
        spotlight.close()
      },
      leftSection: <FontAwesomeIcon icon={faMagnifyingGlass} />,
    },
    {
      id: 'change-theme-mode',
      label: `Change theme to ${
        colorScheme === 'dark' ? 'light' : 'dark'
      } mode`,
      description: `Change the theme to ${
        colorScheme === 'dark' ? 'light' : 'dark'
      } mode`,
      onClick: () => {
        setColorScheme(colorScheme === 'dark' ? 'light' : 'dark')
        spotlight.close()
      },
      leftSection: <FontAwesomeIcon icon={faPalette} />,
    },
  ]

  return (
    <>
      <Spotlight
        actions={actions}
        nothingFound="Nothing found..."
        highlightQuery
        searchProps={{
          leftSection: <FontAwesomeIcon icon={faMagnifyingGlass} />,
          placeholder: 'Search for a command...',
        }}
      />
      <ChangeTheme open={openThemeChanger} setOpen={setOpenThemeChanger} />
    </>
  )
}

const ChangeTheme = ({
  open,
  setOpen,
}: {
  open: boolean
  setOpen: (open: boolean) => void
}) => {
  const [originalThemeColors] = useState<
    Record<string, { h: number; s: number; l: number }>
  >(() => getAllCssVariables())
  const [themeColors, setThemeColors] = useState<
    Record<string, { h: number; s: number; l: number }> | undefined
  >(() => getAllCssVariables())

  const [themeRefs] = useState(() => {
    if (!themeColors) return undefined
    const _themeRefs = {} as Record<
      string,
      Record<string, React.RefObject<HTMLInputElement>>
    >

    Object.keys(themeColors).forEach((key: string) => {
      _themeRefs[key] = {
        h: React.createRef(),
        s: React.createRef(),
        l: React.createRef(),
      }
    })

    return _themeRefs
  })

  // Set the theme colors in the document when the theme colors change if that color is different than the original theme
  // Otherwise, if they are the same, remove the custom styling from the document
  useEffect(() => {
    if (!themeColors) return

    Object.keys(themeColors).forEach((key) => {
      const { h, s, l } = themeColors[key]
      const color = `${h}, ${s}%, ${l}%`
      const originalColor = `${originalThemeColors[key].h}, ${originalThemeColors[key].s}%, ${originalThemeColors[key].l}%`

      if (color.localeCompare(originalColor) !== 0) {
        document.documentElement.style.setProperty(`--${key}`, color)
      } else {
        document.documentElement.style.removeProperty(`--${key}`)
      }
    })
  }, [originalThemeColors, themeColors])

  // Handles any color change created by the color picker or its associated inputs
  const handleHslColorChange = useCallback(
    (color: { h: number; s: number; l: number }, key: string) => {
      if (!themeRefs) return

      const { h, s, l } = color

      const hInput = themeRefs[key].h.current
      const sInput = themeRefs[key].s.current
      const lInput = themeRefs[key].l.current

      if (!hInput || !sInput || !lInput) return

      if (h > 360 || h < 0) return
      if (s > 100 || s < 0) return
      if (l > 100 || l < 0) return

      hInput.value = h.toString()
      sInput.value = s.toString()
      lInput.value = l.toString()

      setThemeColors({
        ...themeColors,
        [key]: {
          h,
          s,
          l,
        },
      })
    },
    [themeColors, themeRefs]
  )

  // Handles copying to the clipboard the current value of the theme colors
  const handleCopyTheme = useCallback(() => {
    if (!themeColors) return

    const themeString = Object.keys(themeColors)
      .map((key) => {
        const { h, s, l } = themeColors[key]
        return `--${key}: ${h} ${s}% ${l}%;`
      })
      .join('\n')

    navigator.clipboard.writeText(`:root {\n${themeString}\n}`).then(() => {
      alert('Theme copied to clipboard')
    })
  }, [themeColors])

  // Removes all the custom styling added to the root document
  const handleResetTheme = useCallback(() => {
    if (!themeColors) return

    Object.keys(themeColors).forEach((key) => {
      document.documentElement.style.removeProperty(`--${key}`)
    })

    setThemeColors(getAllCssVariables())
    // Update all the refs current value to match the reset values
    themeRefs &&
      Object.keys(themeRefs).forEach((key) => {
        const { h, s, l } = themeColors[key]
        const hInput = themeRefs[key].h.current
        const sInput = themeRefs[key].s.current
        const lInput = themeRefs[key].l.current

        if (!hInput || !sInput || !lInput) return

        hInput.value = h.toString()
        sInput.value = s.toString()
        lInput.value = l.toString()
      })
  }, [themeColors, themeRefs])

  // Present a list of all the css variables in the application and allow the user to change them
  // Each row should have a label, a color picker, and a reset button
  return (
    <>
      <Modal opened={open} onClose={() => setOpen(false)}>
        {themeColors && themeRefs && (
          <div className="flex flex-col gap-2">
            <div className="flex flex-row items-center justify-between">
              <h2 className="text-lg font-bold">Change Theme</h2>
            </div>
            <div className="flex flex-col gap-2">
              {Object.keys(themeColors).map((key) => (
                <div
                  className="flex flex-row items-center justify-start gap-2"
                  key={key}
                >
                  <Popover>
                    <Popover.Target>
                      <div
                        className="h-5 w-5 rounded-full border border-black/50 dark:border-white/50"
                        style={{
                          backgroundColor: `hsl(${themeColors[key].h}, ${themeColors[key].s}%, ${themeColors[key].l}%)`,
                        }}
                      />
                    </Popover.Target>
                    <Popover.Dropdown className="w-80">
                      <div className="flex flex-row gap-4">
                        <HslColorPicker
                          color={themeColors[key]}
                          onChange={(c) => {
                            handleHslColorChange(c, key)
                          }}
                        />
                        <div className="flex flex-col gap-2">
                          <div className="flex flex-row items-center gap-2">
                            <TextInput
                              label="H: "
                              type="input"
                              ref={themeRefs[key].h}
                              defaultValue={themeColors[key].h}
                              className="w-12"
                              onChange={(
                                e: React.ChangeEvent<HTMLInputElement>
                              ) => {
                                const h = parseInt(e.target.value)
                                if (h > 360 || h < 0) return

                                setThemeColors({
                                  ...themeColors,
                                  [key]: {
                                    ...themeColors[key],
                                    h,
                                  },
                                })
                              }}
                            />
                          </div>
                          <div className="flex flex-row items-center gap-2">
                            <TextInput
                              label="S: "
                              type="input"
                              ref={themeRefs[key].s}
                              defaultValue={themeColors[key].s}
                              className="w-12"
                              onChange={(
                                e: React.ChangeEvent<HTMLInputElement>
                              ) => {
                                const s = parseInt(e.target.value)
                                if (s > 100 || s < 0) return

                                setThemeColors({
                                  ...themeColors,
                                  [key]: {
                                    ...themeColors[key],
                                    s,
                                  },
                                })
                              }}
                            />
                          </div>
                          <div className="flex flex-row items-center gap-2">
                            <TextInput
                              label="L: "
                              type="input"
                              ref={themeRefs[key].l}
                              defaultValue={themeColors[key].l}
                              className="w-12"
                              onChange={(
                                e: React.ChangeEvent<HTMLInputElement>
                              ) => {
                                const l = parseInt(e.target.value)
                                if (l > 100 || l < 0) return

                                setThemeColors({
                                  ...themeColors,
                                  [key]: {
                                    ...themeColors[key],
                                    l,
                                  },
                                })
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </Popover.Dropdown>
                  </Popover>
                  <label className="text-sm font-medium">
                    {'  '}:{key.toString().toLocaleUpperCase()}
                  </label>
                </div>
              ))}
            </div>
            <div className="flex flex-row items-center justify-end gap-2">
              <Button onClick={handleCopyTheme}>Copy Theme</Button>
              <Button onClick={handleResetTheme}>Reset Theme</Button>
            </div>
          </div>
        )}
      </Modal>
    </>
  )
}

function getAllCssVariables() {
  // Get all css variables in the document and return them as an object
  // with the h s l values mapped to the variable name as the key
  const _themeColors = {} as Record<string, { h: number; s: number; l: number }>

  CSS_VARIABLES.forEach((variable) => {
    const color = getComputedStyle(document.documentElement).getPropertyValue(
      variable
    )
    const [h, s, l] = color.split(' ').map((c) => parseInt(c))
    _themeColors[variable.replace('--', '')] = { h, s, l }
  })

  return _themeColors
}
