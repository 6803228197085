import { InputLabelSectionHeader } from '@components/InputLabelSectionHeader/InputLabelSectionHeader'
import { TextInput } from '@mantine/core'
import { UseFormReturnType } from '@mantine/form'
import { BroadcastFormValues } from '../BroadcastContext'

type BroadcastNameProps = {
  form: UseFormReturnType<
    BroadcastFormValues,
    (values: BroadcastFormValues) => BroadcastFormValues
  >
}

export const BroadcastName = ({ form }: BroadcastNameProps) => {
  return (
    <TextInput
      id="broadcastName"
      label={<InputLabelSectionHeader>Broadcast Name</InputLabelSectionHeader>}
      placeholder="Enter broadcast name"
      required
      {...form.getInputProps('broadcastName')}
    />
  )
}
