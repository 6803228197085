import { AppInfo, AppState } from '@capacitor/app'
import { DeviceInfo } from '@capacitor/device'
import { createContext } from 'react'

export interface AppContextType {
  appInfo: AppInfo | null
  appState: AppState | null
  backgroundTimestamp: number | null
  currentAppState: string
  deviceId: string | null
  deviceInfo: DeviceInfo | null
}

export const AppContext = createContext<AppContextType>({
  appInfo: null,
  appState: null,
  backgroundTimestamp: null,
  currentAppState: 'active',
  deviceId: null,
  deviceInfo: null,
} as AppContextType)
