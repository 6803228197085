import { create } from 'zustand'

interface Insets {
  top: number
  bottom: number
  left: number
  right: number
}

interface ScreenDimensionsState {
  safeAreaInsets: Insets
  keyboardHeight: number
  setSafeAreaInsets: (insets: Insets) => void
  setKeyboardHeight: (height: number) => void
}

export const useScreenDimensionsStore = create<ScreenDimensionsState>(
  (set) => ({
    safeAreaInsets: { top: 0, bottom: 0, left: 0, right: 0 },
    keyboardHeight: 0,
    setSafeAreaInsets: (insets) => set({ safeAreaInsets: insets }),
    setKeyboardHeight: (height) => set({ keyboardHeight: height }),
  })
)
