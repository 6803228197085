import {
  BadgeCountType,
  KnockFeedProvider,
  KnockProvider,
  NotificationFeedPopover,
  NotificationIconButtonProps,
  useKnockFeed,
} from '@knocklabs/react'

// Required CSS import, unless you're overriding the styling
import { useFeatureFlags } from '@context/index'
import { faBell } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useKnockCredentialsQuery } from '@graphql'
import { FeedMetadata } from '@knocklabs/client'
import '@knocklabs/react/dist/index.css'
import { ActionIcon, Indicator, useMantineTheme } from '@mantine/core'
import React, { useRef, useState } from 'react'

function selectBadgeCount(
  badgeCountType: BadgeCountType,
  metadata: FeedMetadata
) {
  switch (badgeCountType) {
    case 'all':
      return metadata.total_count
    case 'unread':
      return metadata.unread_count
    case 'unseen':
      return metadata.unseen_count
  }
}

export const CustomIconButton = React.forwardRef<
  HTMLButtonElement,
  NotificationIconButtonProps
>(({ onClick, badgeCountType }, ref) => {
  const theme = useMantineTheme()
  const { useFeedStore, colorMode } = useKnockFeed()
  const badgeCountValue = useFeedStore((state) =>
    selectBadgeCount(badgeCountType || 'unseen', state.metadata)
  )

  return (
    <ActionIcon
      variant="transparent"
      className={`rnf-notification-icon-button rnf-notification-icon-button--${colorMode}`}
      aria-label="Open notification feed"
      ref={ref}
      onClick={onClick}
      size="lg"
      style={{ overflow: 'visible' }}
    >
      <Indicator
        offset={3}
        color={theme.colors.cyan[6]}
        disabled={badgeCountValue == 0}
        label={badgeCountValue}
        position="top-start"
        size={18}
        style={{ overflow: 'visible' }}
      >
        <FontAwesomeIcon icon={faBell} className="h-7 w-7 text-black" />
      </Indicator>
    </ActionIcon>
  )
})

export const KnockFeed = () => {
  const [isVisible, setIsVisible] = useState(false)
  const buttonRef = useRef(null)
  const { data, loading, error } = useKnockCredentialsQuery()
  const { knockFeed } = useFeatureFlags()

  if (!knockFeed) return null

  if (
    loading ||
    error ||
    !data?.knockCredentials?.userToken ||
    !data?.knockCredentials?.publicKey ||
    !data?.knockCredentials?.userId ||
    !data?.knockCredentials?.feedChannelId
  )
    return null

  return (
    <KnockProvider
      apiKey={data?.knockCredentials?.publicKey}
      userToken={data?.knockCredentials?.userToken}
      userId={data?.knockCredentials?.userId}
    >
      <KnockFeedProvider feedId={data?.knockCredentials.feedChannelId}>
        <div>
          <CustomIconButton
            onClick={() => setIsVisible(!isVisible)}
            badgeCountType={'unread'}
            ref={buttonRef}
          />
          <NotificationFeedPopover
            buttonRef={buttonRef}
            isVisible={isVisible}
            onClose={() => {
              setTimeout(() => setIsVisible(false), 50)
            }}
          />
        </div>
      </KnockFeedProvider>
    </KnockProvider>
  )
}
