import { SwayError } from '@components/swayStates/SwayError'
import * as Sentry from '@sentry/react'
import { RequireFeatureFlag } from '../../routes/guards/RequireFeatureFlag'

export const SettingsRoutes = [
  {
    path: 'settings',
    async lazy() {
      const { SettingsPage } = await import('./Settings.page')
      return {
        element: (
          <Sentry.ErrorBoundary
            fallback={SwayError}
            beforeCapture={(scope) => {
              scope.setTag('location', 'SettingsRoutes')
            }}
          >
            <SettingsPage />
          </Sentry.ErrorBoundary>
        ),
      }
    },
    children: [
      {
        path: 'notifications',
        async lazy() {
          const { NotificationSettingsScreen } = await import(
            './screens/notificationSettings/NotificationSettings.screen'
          )
          return {
            element: (
              <Sentry.ErrorBoundary
                fallback={SwayError}
                beforeCapture={(scope) => {
                  scope.setTag('location', 'SettingsNotificationSettingsScreen')
                }}
              >
                <NotificationSettingsScreen />
              </Sentry.ErrorBoundary>
            ),
          }
        },
      },
      {
        path: 'update-email',
        async lazy() {
          const { UpdateEmailSettingsScreen } = await import(
            './screens/UpdateEmailSettings.screen'
          )
          return {
            element: (
              <Sentry.ErrorBoundary
                fallback={SwayError}
                beforeCapture={(scope) => {
                  scope.setTag('location', 'SettingsUpdateEmailSettingsScreen')
                }}
              >
                <UpdateEmailSettingsScreen />
              </Sentry.ErrorBoundary>
            ),
          }
        },
      },
      {
        path: 'update-password',
        async lazy() {
          const { UpdatePasswordSettingsScreen } = await import(
            './screens/UpdatePasswordSettings.screen'
          )
          return {
            element: (
              <Sentry.ErrorBoundary
                fallback={SwayError}
                beforeCapture={(scope) => {
                  scope.setTag(
                    'location',
                    'SettingsUpdatePasswordSettingsScreen'
                  )
                }}
              >
                <UpdatePasswordSettingsScreen />
              </Sentry.ErrorBoundary>
            ),
          }
        },
      },
      {
        path: 'blocks',
        async lazy() {
          const { BlockedAccountSettingsScreen } = await import(
            './screens/BlockedAccountSettings.screen'
          )
          return {
            element: (
              <Sentry.ErrorBoundary
                fallback={SwayError}
                beforeCapture={(scope) => {
                  scope.setTag(
                    'location',
                    'SettingsBlockedAccountSettingsScreen'
                  )
                }}
              >
                <BlockedAccountSettingsScreen />
              </Sentry.ErrorBoundary>
            ),
          }
        },
      },
      {
        path: 'deactivate-account',
        async lazy() {
          const { DeactivateAccountSettingsScreen } = await import(
            './screens/DeactivateAccountSettings.screen'
          )
          return {
            element: (
              <Sentry.ErrorBoundary
                fallback={SwayError}
                beforeCapture={(scope) => {
                  scope.setTag(
                    'location',
                    'SettingsDeactivateAccountSettingsScreen'
                  )
                }}
              >
                <DeactivateAccountSettingsScreen />
              </Sentry.ErrorBoundary>
            ),
          }
        },
      },
      {
        path: 'delete-account',
        async lazy() {
          const { DeleteAccountSettingsScreen } = await import(
            './screens/DeleteAccountSettings.screen'
          )
          return {
            element: (
              <Sentry.ErrorBoundary
                fallback={SwayError}
                beforeCapture={(scope) => {
                  scope.setTag(
                    'location',
                    'SettingsDeleteAccountSettingsScreen'
                  )
                }}
              >
                <DeleteAccountSettingsScreen />
              </Sentry.ErrorBoundary>
            ),
          }
        },
      },
      {
        path: 'edit-profile',
        async lazy() {
          const { EditProfilePageSettingsScreen } = await import(
            './screens/editProfileSettings/EditProfilePageSettings.screen'
          )
          return {
            element: (
              <Sentry.ErrorBoundary
                fallback={SwayError}
                beforeCapture={(scope) => {
                  scope.setTag(
                    'location',
                    'SettingsEditProfilePageSettingsScreen'
                  )
                }}
              >
                <EditProfilePageSettingsScreen />
              </Sentry.ErrorBoundary>
            ),
          }
        },
      },
      {
        path: 'experimental-features',
        async lazy() {
          const { ExperimentalFeaturesScreen } = await import(
            './screens/ExperimentalFeatures.screen'
          )
          return {
            element: (
              <Sentry.ErrorBoundary
                fallback={SwayError}
                beforeCapture={(scope) => {
                  scope.setTag('location', 'SettingsExperimentalFeaturesScreen')
                }}
              >
                <RequireFeatureFlag flag="fe_experimental_features">
                  <ExperimentalFeaturesScreen />
                </RequireFeatureFlag>
              </Sentry.ErrorBoundary>
            ),
          }
        },
      },
      {
        path: 'device-details',
        async lazy() {
          const { DeviceDetailsScreen } = await import(
            './screens/DeviceDetails.screen'
          )
          return {
            element: (
              <Sentry.ErrorBoundary
                fallback={SwayError}
                beforeCapture={(scope) => {
                  scope.setTag('location', 'SettingsDeviceDetailsScreen')
                }}
              >
                <DeviceDetailsScreen />
              </Sentry.ErrorBoundary>
            ),
          }
        },
      },
    ],
  },
]
