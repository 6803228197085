import { ConnectionType } from '@capacitor/network'
import { createContext } from 'react'

interface ContextProps {
  connected: boolean
  connectionType: ConnectionType
}

export const NetworkContext = createContext<ContextProps>({
  connected: false,
  connectionType: 'unknown',
})
