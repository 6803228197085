import useDeepLinkStore from '@stores/useDeepLinkStore'
import { isShortLink, resolveAndNavigate, trackUri } from '@util/linkUtils'
import { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

export const DeepLinkProvider = () => {
  const { deepLink, clearDeepLink } = useDeepLinkStore((state) => ({
    deepLink: state.deepLink,
    setDeepLink: state.setDeepLink,
    clearDeepLink: state.clearDeepLink,
  }))
  const navigate = useNavigate()
  const location = useLocation()

  useEffect(() => {
    if (deepLink && location.pathname !== '/') {
      // If the url is a short link, resolve it and redirect accordingly
      // Example https://link.sway.dm/s/3997_dO1gKY
      if (isShortLink(deepLink)) {
        resolveAndNavigate(deepLink, navigate)
      } else {
        // Will redirect to the path after the domain, which includes query params.
        // Example: /profile/sonicthehedgehog?rings=23
        const slug = deepLink.href.split(deepLink.origin).pop()

        if (slug) {
          trackUri(deepLink.toString())
          navigate(slug)
        }
      }
      clearDeepLink()
    }
  }, [clearDeepLink, deepLink, navigate, location])

  return null
}
