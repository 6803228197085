import { Box, Button, Modal, Slider, Stack, Text, Title } from '@mantine/core'
import Cropper from 'react-easy-crop'

const ImageCropperModal = ({
  attachedMediaThumbnail,
  crop,
  setCrop,
  zoom,
  setZoom,
  imageModalOpened,
  imageModalClose,
  handleCropComplete,
  onSave,
}: {
  attachedMediaThumbnail?: string
  crop: { x: number; y: number }
  setCrop: (crop: { x: number; y: number }) => void
  zoom: number
  setZoom: (zoom: number) => void
  imageModalOpened: boolean
  imageModalClose: () => void
  handleCropComplete: (croppedArea: any, croppedAreaPixels: any) => void
  onSave: () => void
}) => {
  return (
    <Modal
      opened={imageModalOpened}
      onClose={imageModalClose}
      size="md"
      centered
      withCloseButton={false}
    >
      <Stack w={'100%'} align="center" px={24} gap={16}>
        <Title order={2}>Crop Photo</Title>
        <Text ta={'center'}>Crop your photo to the desired size</Text>
        <Box pos={'relative'} w={'100%'} h={'300'} mx={24}>
          <Cropper
            image={attachedMediaThumbnail}
            crop={crop}
            zoom={zoom}
            minZoom={1}
            maxZoom={10}
            aspect={5 / 3}
            onCropChange={setCrop}
            onCropComplete={handleCropComplete}
            onZoomChange={setZoom}
          />
        </Box>
        <Slider
          label="Zoom"
          value={zoom}
          onChange={setZoom}
          min={1}
          max={10}
          step={0.1}
          w={'100%'}
        />
        <Button
          fullWidth
          onClick={() => {
            onSave()
            imageModalClose()
          }}
        >
          Save
        </Button>
      </Stack>
    </Modal>
  )
}

export default ImageCropperModal
