import {
  AttachedImageCombined,
  AttachedMedia,
} from '@context/mediaContext/MediaTypes.types'
import { MediaPointer, SignedUrl, SignedUrlKey } from '@graphql'
import { Area } from 'react-easy-crop'
import { ImageCroppedArea } from './ImageCropper'

export const downloadUrlForKey = (
  mediaPointer: MediaPointer,
  signedUrlKey: SignedUrlKey
): string | undefined => {
  return mediaPointer.downloadUrls.find(
    (downloadUrl: SignedUrl) => downloadUrl.key === signedUrlKey
  )?.url
}

// The following code is a modified example from react-easy-crop
// https://codesandbox.io/embed/react-easy-crop-custom-image-demo-hgqst?codemirror=1
const createImage = (url: string): Promise<HTMLImageElement> =>
  new Promise((resolve, reject) => {
    const image = new Image()
    image.addEventListener('load', () => resolve(image))
    image.addEventListener('error', (error) => reject(error))
    image.setAttribute('crossOrigin', 'anonymous')
    image.src = url
  })

function getRadianAngle(degreeValue: number) {
  return (degreeValue * Math.PI) / 180
}

export async function getCroppedImg(
  imageSrc: string,
  pixelCrop: ImageCroppedArea,
  rotation = 0
) {
  const image = await createImage(imageSrc)
  const canvas = document.createElement('canvas')
  const ctx = canvas.getContext('2d')

  const maxSize = Math.max(image.width, image.height)
  const safeArea = 2 * ((maxSize / 2) * Math.sqrt(2))

  // set each dimensions to double largest dimension to allow for a safe area for the
  // image to rotate in without being clipped by canvas context
  // cap at 4096, as this is the max canvas size allowed by most browsers
  canvas.width = Math.min(safeArea, 4096)
  canvas.height = Math.min(safeArea, 4096)

  if (ctx) {
    // translate canvas context to a central location on image to allow rotating around the center.
    ctx.translate(safeArea / 2, safeArea / 2)
    ctx.rotate(getRadianAngle(rotation))
    ctx.translate(-safeArea / 2, -safeArea / 2)

    // draw rotated image and store data.
    ctx.drawImage(
      image,
      safeArea / 2 - image.width * 0.5,
      safeArea / 2 - image.height * 0.5
    )

    const data = ctx.getImageData(0, 0, safeArea, safeArea)

    // set canvas width to final desired crop size - this will clear existing context
    canvas.width = pixelCrop.width
    canvas.height = pixelCrop.height

    // paste generated rotate image with correct offsets for x,y crop values.
    ctx.putImageData(
      data,
      0 - safeArea / 2 + image.width * 0.5 - pixelCrop.x,
      0 - safeArea / 2 + image.height * 0.5 - pixelCrop.y
    )

    // As Base64 string
    return canvas.toDataURL('image/jpeg')
    //return canvas;
  }
  throw new Error('Canvas Context is null')
}

export const handleCropComplete = (
  _croppedArea: Area,
  croppedAreaPixels: Area,
  attachedMedia: AttachedMedia[],
  setMediaFocalPoint: (
    focalPoint: { x: number; y: number; z: number } | undefined
  ) => void,
  zoom: number
) => {
  const centerX = croppedAreaPixels.x + croppedAreaPixels.width / 2
  const centerY = croppedAreaPixels.y + croppedAreaPixels.height / 2

  const originalImage = (attachedMedia[0] as AttachedImageCombined).original

  if (!originalImage) {
    setMediaFocalPoint(undefined)
    return
  }

  const normalizedCenterX = centerX / originalImage.width
  const normalizedCenterY = centerY / originalImage.height

  setMediaFocalPoint({
    x: normalizedCenterX,
    y: normalizedCenterY,
    z: zoom,
  })
}
