// ? 🌎 https://capacitorjs.com/docs/guides/deep-links

import { App, URLOpenListenerEvent } from '@capacitor/app'
import { PluginListenerHandle } from '@capacitor/core'
import useDeepLinkStore from '@stores/useDeepLinkStore'
import { useEffect } from 'react'

const AppUrlListener: React.FC<any> = () => {
  const setDeepLink = useDeepLinkStore((state) => state.setDeepLink)

  useEffect(() => {
    let listener: PluginListenerHandle | null = null
    App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
      const url = new URL(event.url)
      setDeepLink(url)
    }).then((l) => {
      // There may be a better way to do this, but using await in useEffect was causing issues.
      listener = l
    })
    return () => {
      // We need to remove the listener, otherwise there will be multiple listeners, and issues start to happen.
      listener?.remove()
    }
  }, [setDeepLink])

  return null
}

export default AppUrlListener
