import { PageNotFound } from '../pages'

/*
 * These routes are for handling use cases such as 404 pages and
 * when the user tries to go to a page that doesn't exist.
 */
export const CleanupRoutes = [
  {
    path: '404',
    element: <PageNotFound />,
  },
  {
    path: '*',
    async lazy() {
      const { RedirectPage } = await import('./../pages/Redirect.page')
      return {
        element: <RedirectPage route="/" />,
      }
    },
  },
]
