import { useContext } from 'react'
import { AnalyticsContext } from './Analytics.context'

export function useAnalyticsContext() {
  const context = useContext(AnalyticsContext)
  if (context === undefined) {
    throw new Error(
      'useAnalyticsContext must be used within an AnalyticsProvider'
    )
  }
  return context
}
