import {
  AndroidSettings,
  IOSSettings,
  NativeSettings,
} from 'capacitor-native-settings'
import { clsx, type ClassValue } from 'clsx'
import haversine from 'haversine'
import { twMerge } from 'tailwind-merge'

const MILES_TO_KM_CONVERSION_FACTOR = 1.609344

export const BOLD = 800
export const SEMI_BOLD = 700
export const DESKTOP_MODAL_HEIGHT = '80vh'

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}

export function timeDifference(date1: Date | string, date2?: Date | string) {
  // Parse the dates
  const start = new Date(date1)
  const end = date2 ? new Date(date2) : new Date()

  // Calculate the difference in milliseconds
  const diff = Math.abs(end.getTime() - start.getTime())

  // Convert the difference to hours and minutes
  const hours = Math.floor(diff / (1000 * 60 * 60))
  const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60))

  return {
    hours,
    minutes,
  }
}

export function milesToKm(miles: number) {
  return miles * MILES_TO_KM_CONVERSION_FACTOR
}

export function kmToMiles(km: number) {
  return km / MILES_TO_KM_CONVERSION_FACTOR
}

export function calculateDistance(
  from: { latitude: number; longitude: number },
  to: { latitude: number; longitude: number }
): number | undefined {
  if (!from.latitude || !from.longitude || !to.latitude || !to.longitude)
    return undefined

  return haversine(from, to, { unit: 'mile' })
}

export function formattedDistance(
  from: { latitude: number; longitude: number },
  to: { latitude: number; longitude: number }
) {
  const distance = calculateDistance(from, to)
  if (!distance) return ''

  const formattedDistance = distance > 100 ? '+100' : distance.toFixed(1)

  return `${formattedDistance} mi`
}

export function htmlToText(html: string): string {
  return html.replace(/<[^>]+>/g, ' ')
}

// Formats a given label as singular or plural based on a provided value.
export function formatUnit(value: number, label: string): string {
  return `${value === 1 ? label : label + 's'}`
}

export const openAppSettings = () => {
  try {
    NativeSettings.open({
      optionAndroid: AndroidSettings.ApplicationDetails,
      optionIOS: IOSSettings.App,
    })
  } catch (error) {
    console.error('Error opening settings', error)
  }
}

export const bytesToMB = (bytes: number) => {
  if (isNaN(bytes)) return 0

  return bytes / (1024 * 1024)
}

export function getPositionWithOrdinal(position: number): string {
  const j = position % 10
  const k = position % 100

  if (j === 1 && k !== 11) {
    return position + 'st'
  }
  if (j === 2 && k !== 12) {
    return position + 'nd'
  }
  if (j === 3 && k !== 13) {
    return position + 'rd'
  }
  return position + 'th'
}
