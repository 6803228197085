import { InMemoryCache } from '@apollo/client'
import { uniqBy } from 'lodash-es'

export function createCache() {
  const cache = new InMemoryCache({
    possibleTypes: {
      Interaction: [
        'InteractionClick',
        'InteractionPoll',
        'InteractionRedemption',
        'InteractionRedemptionOffer',
        'InteractionReply',
      ],
      PollOption: ['PollTextOption'],
      ConversationsEvent: [
        'BlockEvent',
        'ConversationEvent',
        'MediaEvent',
        'MessageEvent',
        'ParticipantSettingsEvent',
        'TypingEvent',
      ],
      RedemptionLimit: ['RedemptionLimitCount', 'RedemptionLimitRate'],
    },
    typePolicies: {
      InteractionRedemptionOffer: {
        merge: true,
      },
      Query: {
        fields: {
          feedItems: {
            keyArgs: ['afterId', 'includeAfterItemInFeed', 'includeSeen'],
            merge(_existing, incoming) {
              return incoming
            },
          },
          vendorAnalytics: {
            keyArgs: [
              'actions',
              'actionSources',
              'lookbackDays',
              'targetTypes',
            ],
            merge(_existing, incoming) {
              return incoming
            },
          },
        },
      },
      Wallet: {
        fields: {
          adjustments: {
            keyArgs: ['type'],
            merge(_existing, incoming) {
              if (_existing) {
                return {
                  ..._existing,
                  ...incoming,
                  data: uniqBy([..._existing.data, ...incoming.data], '__ref'),
                }
              } else {
                return incoming
              }
            },
          },
        },
      },
    },
  })

  return cache
}
