import { useFeatureFlags } from '@context/index'
import { ProfileType } from '@graphql'
import { useAuth } from '@hooks/useAuth'
import { Navigate } from 'react-router-dom'

export function RequireUnauthed({ children }: { children: JSX.Element }) {
  const { currentUser } = useAuth()
  const { contentFeed } = useFeatureFlags()

  if (
    currentUser &&
    currentUser.profileType === ProfileType.CommunityFollower &&
    contentFeed
  ) {
    return <Navigate to="/feed" />
  } else if (currentUser) {
    return <Navigate to="/" />
  } else {
    return children
  }
}
