import { faCircleExclamation } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Card, Center, Space, Text } from '@mantine/core'

export function UnconfirmedEmailCard({
  showUnconfirmedEmailMessage,
  resendEmailVerificaitonLoading,
  resendVerificationEmail,
}: {
  showUnconfirmedEmailMessage: { email: string; sent: boolean }
  resendEmailVerificaitonLoading: boolean
  resendVerificationEmail: () => void
}) {
  return (
    <Card>
      <Center c="swurple">
        <FontAwesomeIcon
          icon={faCircleExclamation}
          className="inline-block h-24 w-24"
        />
      </Center>
      <Space h="md" />
      {!showUnconfirmedEmailMessage.sent && (
        <Text>
          The email you provided ({showUnconfirmedEmailMessage.email}) has not
          been verified.
        </Text>
      )}
      {showUnconfirmedEmailMessage.sent && (
        <Text>
          A new verification email has been sent to (
          {showUnconfirmedEmailMessage.email})
        </Text>
      )}

      {!showUnconfirmedEmailMessage.sent && (
        <Button
          disabled={resendEmailVerificaitonLoading}
          className="mt-4"
          onClick={resendVerificationEmail}
          fullWidth
        >
          Resend Verification Email
        </Button>
      )}
    </Card>
  )
}
