import { PermissionStatus, Position } from '@capacitor/geolocation'

import { create } from 'zustand'

type State = {
  position: Position | null
  lastPositionTimestamp: number | null
  permissions: PermissionStatus | undefined
  permissionsChecked: boolean
  error: Error | null
}

type Action = {
  updatePosition: (position: Position) => void
  updateLastPositionTimestamp: (lastPositionTimestamp: number) => void
  setPermissions: (permissions: PermissionStatus) => void
  setPermissionsChecked: (permissionsChecked: boolean) => void
  setError: (error: Error | null) => void
}

const useGeolocationStore = create<State & Action>((set) => ({
  position: null,
  lastPositionTimestamp: null,
  permissions: undefined,
  permissionsChecked: false,
  error: null,

  updatePosition: (position) => set({ position }),
  updateLastPositionTimestamp: (lastPositionTimestamp) =>
    set({ lastPositionTimestamp }),
  setPermissions: (permissions) => set({ permissions }),
  setPermissionsChecked: (permissionsChecked) => set({ permissionsChecked }),
  setError: (error) => set({ error }),
}))

export default useGeolocationStore
