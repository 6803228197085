import { InputLabelSectionHeader } from '@components/InputLabelSectionHeader/InputLabelSectionHeader'
import ImageCropperModal from '@components/media/ImageCropperModal'
import { handleCropComplete } from '@components/media/MediaUtils'
import useUploadMediaContext from '@context/mediaContext/useUploadMediaContext'
import { faClose, faEdit } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { MediaType, Redemption, useMediaPointerLazyQuery } from '@graphql'
import {
  ActionIcon,
  Box,
  Button,
  Card,
  Group,
  Image,
  Modal,
  Progress,
  Stack,
  Text,
} from '@mantine/core'
import { UseFormReturnType } from '@mantine/form'
import { useDisclosure } from '@mantine/hooks'
import { generateImgixOptions } from '@util/imgixUtils'
import { SEMI_BOLD } from '@util/utils'
import { useEffect, useState } from 'react'
import { Point } from 'react-easy-crop'
import { BroadcastFormValues } from '../BroadcastContext'
import { MediaFocalPoint } from './EditBroadcastForm'

type BroadcastPreviewProps = {
  form: UseFormReturnType<
    BroadcastFormValues,
    (values: BroadcastFormValues) => BroadcastFormValues
  >
  showMessagePreviewModal: boolean
  mediaFocalPoint: MediaFocalPoint | undefined
  isMultipleChoice: boolean
  setShowMessagePreviewModal: React.Dispatch<React.SetStateAction<boolean>>
  setMediaFocalPoint: React.Dispatch<
    React.SetStateAction<MediaFocalPoint | undefined>
  >
  attachedBroadcastImage:
    | {
        attachmentId: string
        url: string
      }
    | undefined
  redemptions: Redemption[] | undefined
}

export const BroadcastPreview = ({
  form,
  showMessagePreviewModal,
  mediaFocalPoint,
  setShowMessagePreviewModal,
  setMediaFocalPoint,
  attachedBroadcastImage,
}: BroadcastPreviewProps) => {
  const [crop, setCrop] = useState<Point>({ x: 0.5, y: 0.5 })
  const [zoom, setZoom] = useState(1)
  const { attachedMedia, clearAttachedMedia } = useUploadMediaContext()

  const [imageModalOpened, { open: imageModalOpen, close: imageModalClose }] =
    useDisclosure(false)

  const hasLocallyAttachedMedia = attachedMedia && attachedMedia.length > 0

  const attachedMediaThumbnail =
    hasLocallyAttachedMedia && attachedMedia[0].mediaType === MediaType.Image
      ? attachedMedia[0].original.localUrl
      : undefined

  const [
    getMediaPointer,
    {
      loading: loadingMediaPointer,
      error: errorMediaPointer,
      data: mediaPointerData,
    },
  ] = useMediaPointerLazyQuery()

  const [localMediaPointerData, setLocalMediaPointerData] =
    useState(mediaPointerData)

  const clearMediaPointerData = () => {
    setLocalMediaPointerData(undefined)
  }

  const handleGetMediaPointer = () => {
    getMediaPointer({
      variables: {
        mediaPointerId: attachedMedia[0].mediaPointerId,
        opts9by16: generateImgixOptions({
          w: 360,
          h: 640,
          fit: 'crop',
          auto: 'compress',
          'fp-x': mediaFocalPoint?.x,
          'fp-y': mediaFocalPoint?.y,
          'fp-z': mediaFocalPoint?.z,
          crop: 'focalpoint',
          ar: '9:16',
        }),
        opts5by3: generateImgixOptions({
          w: 500,
          h: 300,
          fit: 'crop',
          auto: 'compress',
          'fp-x': mediaFocalPoint?.x,
          'fp-y': mediaFocalPoint?.y,
          'fp-z': mediaFocalPoint?.z,
          crop: 'focalpoint',
          ar: '5:3',
        }),
      },
    })
  }

  const handleRemoveBroadcastImage = () => {
    form.setFieldValue('messageTemplate.primaryAttachmentId', null)
  }

  useEffect(() => {
    if (!mediaPointerData || errorMediaPointer || loadingMediaPointer) return
    setLocalMediaPointerData(mediaPointerData)
  }, [mediaPointerData, errorMediaPointer, loadingMediaPointer])

  useEffect(
    function handleAttachedMediaUpdateSideEffects() {
      if (attachedMedia?.length > 0) {
        form.setFieldValue(
          'messageTemplate.primaryAttachmentId',
          attachedMedia[0].mediaPointerId
        )
        handleGetMediaPointer()
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [attachedMedia]
  )

  return (
    <>
      <Stack gap={2}>
        <Text
          size="sm"
          fw={SEMI_BOLD}
          display="inline-block"
          component="label"
          htmlFor="templateRedemptionOffers"
        >
          Cover Image{' '}
          <Text component="span" c="dimmed">
            (Optional)
          </Text>
          {!attachedMediaThumbnail && <BroadcastMediaButtons />}
        </Text>
      </Stack>
      {attachedMedia[0]?.mediaType === MediaType.Image && (
        <Box p={6} maw={150} pos="relative">
          <Image
            src={attachedMedia[0]?.thumbnail.localUrl}
            h={150}
            mah={150}
            w={150}
          />

          {attachedMedia[0]?.uploadProgress !== 100 && (
            <Progress
              value={attachedMedia[0]?.uploadProgress || 0}
              pos="absolute"
              bottom={6}
              w="100%"
              size="xl"
              animated={attachedMedia[0]?.uploadProgress !== 100}
            />
          )}
          {attachedMedia[0]?.uploadProgress === 100 && (
            <Progress.Root pos="absolute" bottom={6} w="100%" size="xl">
              <Progress.Section value={100} c="violet">
                <Progress.Label>Upload Complete!</Progress.Label>
              </Progress.Section>
            </Progress.Root>
          )}

          <ActionIcon
            variant="filled"
            radius="xl"
            onClick={() => {
              clearAttachedMedia()
              clearMediaPointerData()
              setMediaFocalPoint(undefined)
            }}
            pos="absolute"
            top={0}
            right={0}
          >
            <FontAwesomeIcon icon={faClose} />
          </ActionIcon>
          <ActionIcon
            variant="filled"
            radius="xl"
            onClick={imageModalOpen}
            pos="absolute"
            top={0}
            left={0}
          >
            <FontAwesomeIcon icon={faEdit} />
          </ActionIcon>
        </Box>
      )}
      {attachedMedia[0]?.mediaType !== MediaType.Image &&
        form.values.messageTemplate.primaryAttachmentId &&
        attachedBroadcastImage?.attachmentId ===
          form.values.messageTemplate.primaryAttachmentId && (
          <Stack p={6} maw={150} pos="relative">
            <Image src={attachedBroadcastImage.url} h={150} w={150} mah={150} />
            <ActionIcon
              variant="filled"
              radius="xl"
              onClick={() => {
                handleRemoveBroadcastImage()
              }}
              pos="absolute"
              top={0}
              right={0}
            >
              <FontAwesomeIcon icon={faClose} />
            </ActionIcon>
          </Stack>
        )}
      {localMediaPointerData && (
        <>
          <InputLabelSectionHeader>
            Fullscreen Image Preview
          </InputLabelSectionHeader>
          <Card withBorder>
            <Image
              src={localMediaPointerData?.mediaPointer.nine_by_sixteen?.url}
              w={180}
              h={320}
              mah={320}
              radius="md"
            />
          </Card>
        </>
      )}

      <Modal
        opened={showMessagePreviewModal}
        onClose={() => setShowMessagePreviewModal(false)}
        title="Message Preview"
        size="md"
        w={'100%'}
      >
        <Stack>
          <Text>Different preview sizes</Text>
          {localMediaPointerData && (
            <Group>
              {/* We are not showing the 1:1 image here because it is not used for broadcasts */}
              <Image
                src={localMediaPointerData.mediaPointer.five_by_three?.url}
                w={500}
                h={300}
                mah={150}
                radius="md"
              />
              <Image
                src={localMediaPointerData.mediaPointer.nine_by_sixteen?.url}
                w={360}
                h={640}
                mah={150}
                radius="md"
              />
            </Group>
          )}
        </Stack>
      </Modal>
      <ImageCropperModal
        imageModalOpened={imageModalOpened}
        imageModalClose={imageModalClose}
        attachedMediaThumbnail={attachedMediaThumbnail}
        crop={crop}
        setCrop={setCrop}
        zoom={zoom}
        setZoom={setZoom}
        handleCropComplete={(croppedArea, croppedAreaPixels) =>
          handleCropComplete(
            croppedArea,
            croppedAreaPixels,
            attachedMedia,
            setMediaFocalPoint,
            zoom
          )
        }
        onSave={handleGetMediaPointer}
      />
    </>
  )
}

const BroadcastMediaButtons = () => {
  const { selectMedia } = useUploadMediaContext()

  return (
    <>
      <Button
        variant="outline"
        type="button"
        onClick={() => selectMedia()}
        fullWidth
      >
        Add Photo
      </Button>
      {/* // ! This is commented out because it is not full implemented yet
      <ActionIcon variant="outline" radius="xl">
      <FontAwesomeIcon icon={faCamera} />
      </ActionIcon> */}
    </>
  )
}
