import { faBars, faCircleMinus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { DragDropContext, Draggable, Droppable } from '@hello-pangea/dnd'
import { Button, Group, TextInput } from '@mantine/core'
import { useBroadcastFormContext } from './BroadcastContext'

export function EditFormMultipleChoiceOptions({
  handleRemoveOption,
  formOptionsField,
}: {
  handleRemoveOption: (index: number) => void
  formOptionsField: string
}) {
  const form = useBroadcastFormContext()

  const options = (form.getInputProps(`${formOptionsField}`).value || []) as {
    text: string
  }[]

  return (
    <DragDropContext
      onDragEnd={({ destination, source }) =>
        form.reorderListItem(formOptionsField, {
          from: source.index,
          to: destination?.index || 0,
        })
      }
    >
      <Droppable droppableId={formOptionsField} direction="vertical">
        {(provided) => (
          <div {...provided.droppableProps} ref={provided.innerRef}>
            {options?.map((option, index) => (
              <Draggable
                key={`${option}-${index}`}
                draggableId={`${option?.text}-${index}`}
                index={index}
              >
                {(provided) => (
                  <Group
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    mb={8}
                  >
                    <span {...provided.dragHandleProps}>
                      <FontAwesomeIcon icon={faBars} />
                    </span>
                    <TextInput
                      placeholder="Enter option"
                      style={{ flexGrow: 1 }}
                      required
                      {...form.getInputProps(
                        `${formOptionsField}.${index}.text`
                      )}
                    />
                    <Button
                      variant="transparent"
                      c="red"
                      onClick={() => handleRemoveOption(index)}
                    >
                      <FontAwesomeIcon icon={faCircleMinus} />
                    </Button>
                  </Group>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  )
}
