import { ApolloError } from '@apollo/client'
import { createContext } from 'react'

export interface WalletContextType {
  wallet: any
  walletLoading: boolean
  walletError: ApolloError | undefined
  refetch: () => Promise<any>
}

export const WalletContext = createContext<WalletContextType>({
  wallet: null,
  walletLoading: false,
  walletError: undefined,
  refetch: () => Promise.resolve(),
})
