import {
  notificationsStore,
  notifications as originalNotifications,
} from '@mantine/notifications'
import classes from './notifications.module.css'

const colors = {
  red: '#8B0000',
  green: '#006400',
  default: '#000065',
}

export const notifications = {
  ...originalNotifications,
  show: (prop: any) => {
    const color: string =
      colors[(prop.color as keyof typeof colors) || 'default']
    const id = originalNotifications.show({
      ...prop,
      styles: {
        root: {
          backgroundColor: color,
        },
      },
      classNames: classes,
    })

    return id
  },
}

export const notificationAlreadyAdded = (message: string) => {
  const currentNotificationState = notificationsStore.getState()
  const isVisible = currentNotificationState.notifications.some(
    (notification) => notification.message === message
  )

  const isQueued = currentNotificationState.queue.some(
    (notification) => notification.message === message
  )

  return isVisible || isQueued
}
