import { SwayError } from '@components/swayStates/SwayError'
import * as Sentry from '@sentry/react'
import { RequireVendor } from '../../routes/guards/RequireVendor'

export const VendorBroadcastRoutes = [
  {
    path: 'broadcasts',
    async lazy() {
      const { BroadcastsPage } = await import('./Broadcasts.page')
      return {
        element: (
          <Sentry.ErrorBoundary
            fallback={SwayError}
            beforeCapture={(scope) => {
              scope.setTag('location', 'VendorBroadcastRoutes')
            }}
          >
            <RequireVendor>
              <BroadcastsPage />
            </RequireVendor>
          </Sentry.ErrorBoundary>
        ),
      }
    },
    children: [
      {
        path: '',
        async lazy() {
          const { AllBroadcastsScreen } = await import(
            './screens/AllBroadcasts.screen'
          )
          return {
            element: (
              <Sentry.ErrorBoundary
                fallback={SwayError}
                beforeCapture={(scope) => {
                  scope.setTag('location', 'BroadcastsAllBroadcastsScreen')
                }}
              >
                <AllBroadcastsScreen />
              </Sentry.ErrorBoundary>
            ),
          }
        },
      },
      {
        path: 'drafts',
        async lazy() {
          const { AllDraftBroadcastsScreen } = await import(
            './screens/AllDraftBroadcasts.screen'
          )
          return {
            element: (
              <Sentry.ErrorBoundary
                fallback={SwayError}
                beforeCapture={(scope) => {
                  scope.setTag('location', 'BroadcastsAllDraftBroadcastsScreen')
                }}
              >
                <AllDraftBroadcastsScreen />
              </Sentry.ErrorBoundary>
            ),
          }
        },
      },
      {
        path: 'active',
        async lazy() {
          const { AllActiveBroadcastsScreen } = await import(
            './screens/AllActiveBroadcasts.screen'
          )
          return {
            element: (
              <Sentry.ErrorBoundary
                fallback={SwayError}
                beforeCapture={(scope) => {
                  scope.setTag(
                    'location',
                    'BroadcastsAllActiveBroadcastsScreen'
                  )
                }}
              >
                <AllActiveBroadcastsScreen />
              </Sentry.ErrorBoundary>
            ),
          }
        },
      },
      {
        path: 'completed',
        async lazy() {
          const { AllCompletedBroadcastsScreen } = await import(
            './screens/AllCompletedBroadcasts.screen'
          )
          return {
            element: (
              <Sentry.ErrorBoundary
                fallback={SwayError}
                beforeCapture={(scope) => {
                  scope.setTag(
                    'location',
                    'BroadcastsAllCompletedBroadcastsScreen'
                  )
                }}
              >
                <AllCompletedBroadcastsScreen />
              </Sentry.ErrorBoundary>
            ),
          }
        },
      },
      {
        path: 'archived',
        async lazy() {
          const { ArchivedBroadcastsScreen } = await import(
            './screens/ArchivedBroadcasts.screen'
          )
          return {
            element: (
              <Sentry.ErrorBoundary
                fallback={SwayError}
                beforeCapture={(scope) => {
                  scope.setTag('location', 'BroadcastsArchivedBroadcastsScreen')
                }}
              >
                <ArchivedBroadcastsScreen />
              </Sentry.ErrorBoundary>
            ),
          }
        },
      },
      {
        path: 'stats/:broadcastId',
        async lazy() {
          const { BroadcastStatsScreen } = await import(
            './screens/BroadcastStats.screen'
          )
          return {
            element: (
              <Sentry.ErrorBoundary
                fallback={SwayError}
                beforeCapture={(scope) => {
                  scope.setTag('location', 'BroadcastsBroadcastStatsScreen')
                }}
              >
                <BroadcastStatsScreen />
              </Sentry.ErrorBoundary>
            ),
          }
        },
      },
      {
        path: 'new',
        async lazy() {
          const { RedirectPage } = await import('../Redirect.page')
          return {
            element: <RedirectPage route="/" />,
          }
        },
      },
      {
        path: 'edit/:broadcastId',
        async lazy() {
          const { EditBroadcastScreen } = await import(
            './screens/EditBroadcast.screen'
          )
          return {
            element: (
              <Sentry.ErrorBoundary
                fallback={SwayError}
                beforeCapture={(scope) => {
                  scope.setTag('location', 'BroadcastsEditBroadcastScreen')
                }}
              >
                <EditBroadcastScreen />
              </Sentry.ErrorBoundary>
            ),
          }
        },
      },
      {
        path: '*',
        async lazy() {
          const { RedirectPage } = await import('../Redirect.page')
          return {
            element: <RedirectPage route="/" />,
          }
        },
      },
    ],
  },
]
