import { SwayError } from '@components/swayStates/SwayError'
import * as Sentry from '@sentry/react'
import { RequireUnauthed } from '../../routes/guards/RequireUnAuthed'
import { LoginPage } from './login'

function withSentryBoundary(element: JSX.Element, locationTag: string) {
  return (
    <Sentry.ErrorBoundary
      fallback={SwayError}
      beforeCapture={(scope) => {
        scope.setTag('location', locationTag)
      }}
    >
      {element}
    </Sentry.ErrorBoundary>
  )
}

export const AuthRoutes = [
  {
    path: 'login',
    element: (
      <RequireUnauthed>
        {withSentryBoundary(<LoginPage />, 'LoginPage')}
      </RequireUnauthed>
    ),
  },
  {
    path: 'register',
    async lazy() {
      const { RegisterMemberPage } = await import(
        './register/RegisterMember.page'
      )
      return {
        element: (
          <RequireUnauthed>
            {withSentryBoundary(<RegisterMemberPage />, 'RegisterMemberPage')}
          </RequireUnauthed>
        ),
      }
    },
  },
  {
    path: 'register/vendor/:displayName',
    async lazy() {
      const { RegisterMemberPage } = await import(
        './register/RegisterMember.page'
      )
      return {
        element: (
          <RequireUnauthed>
            {withSentryBoundary(
              <RegisterMemberPage />,
              'RegisterMemberPage.displayName'
            )}
          </RequireUnauthed>
        ),
      }
    },
  },
  {
    path: 'register/vendor',
    async lazy() {
      const { RegisterVendorPage } = await import(
        './register/RegisterVendor.page'
      )
      return {
        element: (
          <RequireUnauthed>
            {withSentryBoundary(<RegisterVendorPage />, 'RegisterVendorPage')}
          </RequireUnauthed>
        ),
      }
    },
  },
  {
    path: 'register/redemption/:redemptionId',
    async lazy() {
      const { RegisterMemberPage } = await import(
        './register/RegisterMember.page'
      )
      return {
        element: (
          <RequireUnauthed>
            {withSentryBoundary(
              <RegisterMemberPage />,
              'RegisterMemberPage.redemptionId'
            )}
          </RequireUnauthed>
        ),
      }
    },
  },
  {
    path: 'confirm',
    async lazy() {
      const { ConfirmAccountPage } = await import('./ConfirmAccount.page')
      return {
        element: (
          <RequireUnauthed>
            {withSentryBoundary(<ConfirmAccountPage />, 'ConfirmAccountPage')}
          </RequireUnauthed>
        ),
      }
    },
  },
  {
    path: 'token-auth',
    async lazy() {
      const { TokenAuth } = await import('./TokenAuth.page')
      return {
        element: withSentryBoundary(<TokenAuth />, 'TokenAuth'),
      }
    },
  },
  {
    path: 'users/reset_password',
    async lazy() {
      const { ResetPasswordPage } = await import(
        './resetPassword/ResetPassword.page'
      )
      return {
        element: withSentryBoundary(<ResetPasswordPage />, 'ResetPasswordPage'),
      }
    },
    children: [
      {
        path: ':token',
        async lazy() {
          const { ResetPasswordPage } = await import(
            './resetPassword/ResetPassword.page'
          )
          return {
            element: withSentryBoundary(
              <ResetPasswordPage />,
              'ResetPasswordPage.token'
            ),
          }
        },
      },
    ],
  },
  {
    path: 'thankYou/:email',
    async lazy() {
      const { RegisterEmailSentPage } = await import('./RegisterEmailSent.page')
      return {
        element: withSentryBoundary(
          <RegisterEmailSentPage />,
          'RegisterEmailSentPage'
        ),
      }
    },
  },
]
