import { faCircleXmark } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Redemption, RedemptionMediaPreviewPartsFragment } from '@graphql'
import {
  Avatar,
  ComboboxData,
  Group,
  Image,
  MultiSelect,
  Text,
} from '@mantine/core'
import { UseFormReturnType } from '@mantine/form'
import { SEMI_BOLD } from '@util/utils'
import { BroadcastFormValues } from '../BroadcastContext'

type BroadcastRedemptionOffersProps = {
  form: UseFormReturnType<
    BroadcastFormValues,
    (values: BroadcastFormValues) => BroadcastFormValues
  >
  redemptions: Redemption[] | undefined
}

export const BroadcastRedemptionOffers = ({
  form,
  redemptions,
}: BroadcastRedemptionOffersProps) => {
  const redemptionOptionsMap = new Map(
    redemptions?.map((redemption) => [redemption.id, redemption]) || []
  )
  const redemptionOptions: ComboboxData =
    redemptions?.map((redemption) => ({
      label: redemption.name,
      value: redemption.id,
    })) || []

  return (
    <MultiSelect
      data={redemptionOptions}
      label={
        <Text
          size="sm"
          fw={SEMI_BOLD}
          display="inline-block"
          component="label"
          htmlFor="templateRedemptionOffers"
        >
          Redemption Offers{' '}
          <Text component="span" c="dimmed">
            (Optional)
          </Text>
        </Text>
      }
      id="templateRedemptionOffers"
      maxDropdownHeight={300}
      placeholder="Select redemption offers"
      hidePickedOptions
      clearable
      clearButtonProps={{
        icon: <FontAwesomeIcon icon={faCircleXmark} />,
      }}
      renderOption={({ option }) => {
        const redemption = redemptionOptionsMap.get(option.value)
        if (!redemption) return null
        const redemptionMedia =
          redemption as RedemptionMediaPreviewPartsFragment

        const thumbnailUrl = redemptionMedia?.primaryMedia?.proxy?.url
        return (
          <Group gap="sm" wrap="nowrap">
            {thumbnailUrl && (
              <Image src={thumbnailUrl} h={36} w={36} fit="contain" />
            )}
            {!thumbnailUrl && <Avatar size={36} />}
            <div>
              <Text size="sm" lineClamp={1}>
                {redemption.name}
              </Text>
              <Text size="xs" lineClamp={1} opacity={0.5}>
                {redemption.description}
              </Text>
            </div>
          </Group>
        )
      }}
      {...form.getInputProps('messageTemplate.templateRedemptionOffers')}
    />
  )
}
