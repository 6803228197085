import { create } from 'zustand'

type State = {
  deepLink: URL | null
}

type Action = {
  setDeepLink: (deepLink: URL) => void
  clearDeepLink: () => void
}

const useDeepLinkStore = create<State & Action>((set) => ({
  deepLink: null,
  setDeepLink: (deepLink) => set({ deepLink }),
  clearDeepLink: () => set({ deepLink: null }),
}))

export default useDeepLinkStore
