import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

export const Redirect = ({
  to,
  replace = false,
  state = {},
}: {
  to: string
  replace?: boolean
  state?: any
}) => {
  const navigate = useNavigate()
  useEffect(() => {
    navigate(to, { replace, state })
  }, [navigate, to, replace, state])
  return null
}
