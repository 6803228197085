import { faCircleExclamation } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Badge, Button, Popover, Stack, Text } from '@mantine/core'
import { useCallback } from 'react'
import { useIntercom } from 'react-use-intercom'

export const UnclaimedChip = ({
  size,
  vendorDisplayName,
  handleClick,
}: {
  size?: 'sm' | 'md' | 'lg' | 'xl'
  vendorDisplayName: string
  handleClick?: () => void
}) => {
  const { boot, showNewMessage } = useIntercom()

  const bootAndShowIntercom = useCallback(() => {
    boot({ name: `Unclaimed Profile - ${vendorDisplayName}` })
    showNewMessage(
      `I would like to claim my SwayDM profile (${vendorDisplayName}), how do I do that?`
    )
  }, [boot, showNewMessage, vendorDisplayName])

  return (
    <Popover width={200}>
      <Popover.Target>
        <Badge
          className="cursor-pointer"
          color="red.8"
          size={size}
          leftSection={<FontAwesomeIcon icon={faCircleExclamation} />}
        >
          Unclaimed
        </Badge>
      </Popover.Target>
      <Popover.Dropdown>
        <Stack>
          <Text size="sm">
            Unclaimed profiles are businesses in your area that haven't yet
            signed up for SwayDM. Follow them to earn SwayCash, and let them
            know you want to see them on SwayDM.
          </Text>

          <Text size="sm">
            Is this your business? Contact us to claim your profile.
          </Text>

          <Button onClick={handleClick ? handleClick : bootAndShowIntercom}>
            Claim Profile
          </Button>
        </Stack>
      </Popover.Dropdown>
    </Popover>
  )
}
