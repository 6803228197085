import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPartyHorn } from '@fortawesome/sharp-solid-svg-icons'
import { Button, Stack, Text, Title } from '@mantine/core'

export const ChangelogModalContent = ({ onClose }: { onClose: () => void }) => {
  return (
    <Stack align="center" h="100%" justify="center" gap={28} pos="relative">
      <FontAwesomeIcon
        icon={faPartyHorn as IconProp}
        color="white"
        className="h-24 w-24"
      />
      <Title order={4} c="white" className="text-center">
        Brand New SwayDM Feed
      </Title>
      <Text c="white" ta="center">
        Check out the new SwayDM Feed! <br />
        Interact with messages, offers, and vendors directly from the Home tab.{' '}
        <br />
        Swipe through to see new content from your favorite vendors.
      </Text>

      <Stack w="100%" gap={18} pos="absolute" bottom={24}>
        <Button fullWidth color="deep-blue.9" onClick={onClose}>
          Go to Feed
        </Button>
      </Stack>
    </Stack>
  )
}
