import {
  ProfileType,
  PublicVendor,
  Redemption,
  useFeedGetSingleVendorQuery,
  useFollowCommunityMutation,
} from '@graphql'
import { useBreakpointHeight } from '@hooks/useBreakpoint'
import { useIsMobileViewport } from '@hooks/useIsMobileViewport'
import {
  Box,
  Button,
  Loader,
  MantineProvider,
  Stack,
  colorsTuple,
  createTheme,
  useMantineTheme,
} from '@mantine/core'
import { HeroSection } from '@pages/profile/components/HeroSection'
import { VendorProfileRedemptionsList } from '@pages/profile/components/VendorProfileRedemptionList'
import { useProfileGradient } from '@pages/profile/hooks/useProfileGradient'
import { generateImgixOptions } from '@util/imgixUtils'
import { notifications } from '@util/notifications/notifications'
import { useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'

const imgixOpts = generateImgixOptions({
  w: 256,
  h: 256,
  fit: 'crop',
  auto: 'compress',
})

export function VendorSuggestion({
  id,
  skip,
}: {
  id: string
  skip: () => void
}) {
  const [followCommunity] = useFollowCommunityMutation()
  const { data, loading } = useFeedGetSingleVendorQuery({
    variables: {
      id,
      imgixOpts,
    },
  })

  const isFollowing = !!data?.vendor.followRecord

  const [followSubmited, setFollowSubmited] = useState(false)

  const isSmallScreen = useBreakpointHeight('max', 'sm')
  const isMediumScreen = useBreakpointHeight('max', 'md')

  const navigate = useNavigate()
  const theme = useMantineTheme()
  const ref = useRef<HTMLDivElement | null>(null)

  const gradient = useProfileGradient(
    ProfileType.Community,
    data?.vendor.unclaimed,
    data?.vendor.isNonProfit
  )
  const isMobile = useIsMobileViewport()
  const customTheme = createTheme({
    ...theme,
    colors: {
      ...theme.colors,
      white: colorsTuple('#FFFFFF'),
    },
    primaryColor: data?.vendor.isNonProfit ? 'dark' : 'white',
  })

  const vendor = data?.vendor as PublicVendor

  return (
    <>
      {loading ? (
        <Loader color="swurple" size="xl" variant="bars" />
      ) : (
        <MantineProvider
          theme={customTheme}
          getRootElement={() => ref.current ?? undefined}
          cssVariablesSelector=":root #vendorProfile"
        >
          <Stack
            w="100%"
            h="100%"
            gap={0}
            bg={gradient}
            pt={isMobile ? '4vh' : '0'}
            justify="space-between"
          >
            <Stack
              gap={15}
              c={customTheme.primaryColor}
              ref={ref}
              id="vendorProfile"
              pt={15}
              px={20}
            >
              <HeroSection
                avatarImageUrl={vendor?.photoUrl as string}
                displayName={vendor.displayName as string}
                name={vendor.name as string}
                unclaimed={vendor.unclaimed}
                verified={vendor.verified}
                isNonProfit={vendor.isNonProfit}
              />

              <VendorProfileRedemptionsList
                redemptions={
                  vendor.redemptions.slice(
                    0,
                    isSmallScreen ? 1 : isMediumScreen ? 2 : 3
                  ) as Redemption[]
                }
                avatarImageUrl={vendor.photoUrl as string}
                name={vendor.name as string}
              />
              <Button
                variant="outline"
                size={'sm'}
                radius="xl"
                onClick={() => navigate(`/profile/${vendor.displayName}`)}
              >
                See More
              </Button>
            </Stack>
            <Box w="100%" p="xs">
              <Stack gap={6}>
                <Button
                  onClick={async () => {
                    await followCommunity({
                      variables: {
                        communityId: id,
                      },
                      onCompleted: () => {
                        notifications.show({
                          title: `You are now following ${vendor.name}`,
                        })
                        setFollowSubmited(true)
                      },
                      onError: () => {
                        notifications.show({
                          title: 'Error',
                          message: `Something went wrong while following ${vendor?.name}`,
                        })
                        setFollowSubmited(false)
                      },
                    })
                    skip()
                  }}
                  size={'md'}
                  disabled={isFollowing || followSubmited}
                >
                  {isFollowing || followSubmited ? 'Followed' : 'Follow'}
                </Button>
              </Stack>
            </Box>
          </Stack>
        </MantineProvider>
      )}
    </>
  )
}
