// SafeAreaProvider.tsx
import { PluginListenerHandle } from '@capacitor/core'
import { Device } from '@capacitor/device'
import { Keyboard, KeyboardInfo } from '@capacitor/keyboard'
import { useScreenDimensionsStore } from '@stores/useScreenDimensionsStore'
import { SafeArea } from 'capacitor-plugin-safe-area'
import { FC, useEffect } from 'react'

const SafeAreaProvider: FC = () => {
  const {
    safeAreaInsets,
    setSafeAreaInsets,
    setKeyboardHeight,
    keyboardHeight,
  } = useScreenDimensionsStore()

  const handleKeyboardWillShow = (info: KeyboardInfo) => {
    setKeyboardHeight(info.keyboardHeight)
  }

  const handleKeyboardDidShow = (info: KeyboardInfo) => {
    setKeyboardHeight(info.keyboardHeight)
  }

  const handleKeyboardWillHide = () => {
    setKeyboardHeight(0)
  }

  useEffect(() => {
    Device.getInfo().then((info) => {
      if (info.platform === 'web') {
        return
      } else {
        Keyboard.addListener('keyboardWillShow', handleKeyboardWillShow)
        Keyboard.addListener('keyboardDidShow', handleKeyboardDidShow)
        Keyboard.addListener('keyboardWillHide', handleKeyboardWillHide)
        Keyboard.addListener('keyboardDidHide', handleKeyboardWillHide)

        if (info.platform === 'android') {
          SafeArea.setImmersiveNavigationBar()
        }
      }
    })

    SafeArea.getSafeAreaInsets().then(({ insets }) => {
      setSafeAreaInsets(insets)
    })

    return () => {
      Device.getInfo().then((info) => {
        if (info.platform !== 'web') {
          Keyboard.removeAllListeners()
        }
      })
    }
    // * We only want to update when the setSafeAreaInsets function changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setSafeAreaInsets])

  useEffect(() => {
    let eventListener: PluginListenerHandle | undefined

    SafeArea.addListener('safeAreaChanged', (data) => {
      setSafeAreaInsets(data.insets)
    }).then((listener) => {
      eventListener = listener
    })

    return () => {
      if (eventListener) {
        eventListener.remove()
      }
    }
  }, [setSafeAreaInsets])

  useEffect(() => {
    // * Update CSS variables for safe area insets to be used throughout the app
    // ? This is mainly for if the device returns values, but the browser does not
    for (const [key, value] of Object.entries(safeAreaInsets)) {
      if (key === 'bottom') {
        document.documentElement.style.setProperty(
          `--safe-area-${key}`,
          `${keyboardHeight ? 0 : value}px`
        )
      } else {
        document.documentElement.style.setProperty(
          `--safe-area-${key}`,
          `${value}px`
        )
      }
    }
  }, [safeAreaInsets, keyboardHeight])

  return null
}

export default SafeAreaProvider
