import { RedemptionDonationProgress } from '@graphql'
import { Group, Progress, Stack, Text } from '@mantine/core'
import { BOLD } from '@util/utils'

type RedemptionDonationSectionProps = {
  donationProgress?: RedemptionDonationProgress | null
  isForDetailsPage?: boolean
}

export const RedemptionDonationSection = ({
  donationProgress,
  isForDetailsPage = false,
}: RedemptionDonationSectionProps) => {
  return (
    <>
      {donationProgress?.goal ? (
        <Stack gap={0} mt={4}>
          <Progress
            value={donationProgress?.progress as number}
            color="#00875A"
            size={isForDetailsPage ? 'xl' : 'sm'}
            w={'100%'}
          />
          <Group gap={6} wrap="nowrap">
            <Text fw={BOLD} size="xl" c="#00875A">
              ${((donationProgress?.current.amount as number) / 100).toFixed(2)}
            </Text>
            <Text size="xs" pt={4}>
              of $
              {((donationProgress?.goal?.amount as number) / 100).toFixed(2)}{' '}
              goal
            </Text>
          </Group>
        </Stack>
      ) : (
        <Group gap={6}>
          <Text fw={BOLD} size="xl" c="#00875A">
            ${((donationProgress?.current.amount as number) / 100).toFixed(2)}
          </Text>
          <Text size="xs" pt={4}>
            raised
          </Text>
        </Group>
      )}
    </>
  )
}
