import { CSSVariablesResolver } from '@mantine/core'

export const baseSwayThemeVariablesResolver: CSSVariablesResolver = (
  theme
) => ({
  variables: {},
  light: {
    '--incoming-message-bg': theme.other.incomingMessageBgLight,
    '--outgoing-message-bg': theme.other.outgoingMessageBgLight,
    '--incoming-message-text-color': 'var(--mantine-color-blue-grey-9)',
    '--outgoing-message-text-color': 'var(--mantine-color-white)',
  },
  dark: {
    '--incoming-message-bg': theme.other.incomingMessageBgDark,
    '--outgoing-message-bg': theme.other.outgoingMessageBgDark,
    '--incoming-message-text-color': 'var(--mantine-color-blue-grey-0)',
    '--outgoing-message-text-color': 'var(--mantine-color-white)',
  },
})
