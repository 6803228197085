import { faClose, faMobileScreen } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Group, Text } from '@mantine/core'
import { SEMI_BOLD } from '@util/utils'

export function AppBannerContent({
  openGetAppModal,
  hideShowAppBanner,
}: {
  openGetAppModal: () => void
  hideShowAppBanner: () => void
}) {
  return (
    <Group justify="center">
      <FontAwesomeIcon icon={faMobileScreen} />
      <Text fz={{ base: 'xs', sm: 'sm', md: 'md' }} fw={SEMI_BOLD}>
        Get the Sway mobile app!
      </Text>
      <Button
        type="button"
        size="xs"
        onClick={() => openGetAppModal()}
        hiddenFrom="md"
      >
        Download
      </Button>
      <Button type="button" onClick={() => openGetAppModal()} visibleFrom="md">
        Download
      </Button>
      <Button
        type="button"
        size="xs"
        variant="outline"
        color="white"
        onClick={() => hideShowAppBanner()}
        hiddenFrom="md"
      >
        <FontAwesomeIcon icon={faClose} />
      </Button>
      <Button
        type="button"
        variant="outline"
        color="white"
        onClick={() => hideShowAppBanner()}
        visibleFrom="md"
      >
        Dismiss
      </Button>
    </Group>
  )
}
