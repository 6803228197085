import { Button, Modal, Stack, Text } from '@mantine/core'

export function EditBroadcastSendImmediatelyConfirmationModal({
  opened,
  onConfirmation,
  onClose,
  isPersistent,
}: {
  opened: boolean
  onConfirmation: () => void
  onClose: () => void
  isPersistent: boolean
}) {
  return (
    <Modal
      opened={opened}
      onClose={onClose}
      title={isPersistent ? 'Go Live Now' : 'Send Broadcast Now'}
      centered
    >
      <Stack>
        <Text>
          {isPersistent
            ? 'Are you sure you want to go live now?'
            : 'This will send your broadcast to all recipients immediately. Are you sure you want to send this broadcast now?'}
        </Text>

        <Button variant="primary" onClick={onConfirmation} fullWidth>
          {isPersistent ? 'Yes, Go Live Now' : 'Yes, Send Now'}
        </Button>
        <Button variant="outline" onClick={onClose} fullWidth>
          Not now
        </Button>
      </Stack>
    </Modal>
  )
}
