import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { LoadingAnimated } from '@icons/LoadingAnimated'
import {
  Anchor,
  Box,
  Button,
  Divider,
  Group,
  PasswordInput,
  ScrollArea,
  Stack,
  Text,
  TextInput,
  rem,
} from '@mantine/core'
import { UseFormReturnType } from '@mantine/form'
import { Link, NavLink } from 'react-router-dom'

interface LoginFormProps {
  hideRegistration?: boolean
  loading?: boolean
  form: UseFormReturnType<{ email: string; password: string }>
  onBusinessInquiry?: () => void
  onSubmit: ({ email, password }: { email: string; password: string }) => void
  onClose?: () => void
}

export const LoginForm = ({
  form,
  hideRegistration,
  loading,
  onBusinessInquiry,
  onSubmit,
  onClose,
}: LoginFormProps) => {
  return (
    <ScrollArea.Autosize>
      <form onSubmit={form.onSubmit((values) => onSubmit(values))}>
        <Stack>
          <TextInput
            id="email"
            label="Email"
            autoComplete="email"
            placeholder="Enter your email"
            {...form.getInputProps('email')}
          />

          <Box>
            <Group justify="space-between" mb={5}>
              <Text
                component="label"
                htmlFor="your-password"
                size="sm"
                fw={500}
              >
                Your password
              </Text>

              <Anchor
                to="/users/reset_password"
                component={NavLink}
                pt={2}
                fw={500}
                fz="xs"
                onClick={onClose}
              >
                Forgot your password?
              </Anchor>
            </Group>
            <PasswordInput
              id="password"
              placeholder="Enter your password"
              autoComplete="current-password"
              visibilityToggleIcon={({ reveal }) =>
                reveal ? (
                  <FontAwesomeIcon icon={faEyeSlash} className="h-4 w-4" />
                ) : (
                  <FontAwesomeIcon icon={faEye} className="h-4 w-4" />
                )
              }
              {...form.getInputProps('password')}
            />
          </Box>
          <Button
            type="submit"
            disabled={loading}
            id="submit"
            leftSection={<>{loading && <LoadingAnimated className="mr-2" />}</>}
          >
            Login
          </Button>
        </Stack>
      </form>

      {!hideRegistration && (
        <Stack mt={rem(20)}>
          <Divider label="Not a member?" />
          <Button to="/register" component={Link}>
            Create Member Account
          </Button>
          <Button onClick={onBusinessInquiry} variant="transparent">
            Are you a Business?
          </Button>
        </Stack>
      )}
    </ScrollArea.Autosize>
  )
}
