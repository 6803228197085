import { AnalyticsBrowser } from '@segment/analytics-next'
import React, { createContext } from 'react'

export interface AnalyticsContextProps {
  analytics?: AnalyticsBrowser
}

const defaultAnalyticsContext: AnalyticsContextProps = {
  analytics: undefined,
}

export const AnalyticsContext = createContext<AnalyticsContextProps>(
  defaultAnalyticsContext
)

export interface AnalyticsProviderProps {
  children: React.ReactNode
}
