import { Logo } from '@icons/Logo'
import { AppShell, Group } from '@mantine/core'
import { useScreenDimensionsStore } from '@stores/useScreenDimensionsStore'

export function UnauthenticatedLayout({
  children,
}: {
  children: React.ReactNode
}) {
  const { safeAreaInsets, keyboardHeight } = useScreenDimensionsStore()
  return (
    <AppShell
      header={{
        height: {
          base: 60 + safeAreaInsets.top,
          md: 70 + safeAreaInsets.top,
        },
      }}
      footer={{
        height: 78 + safeAreaInsets.bottom + (keyboardHeight || 0),
      }}
    >
      <AppShell.Header className="flex flex-col justify-end">
        <Group justify="center" px="md" align="end" py={8}>
          <Logo />
        </Group>
      </AppShell.Header>
      {children}
    </AppShell>
  )
}
