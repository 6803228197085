import { SwayError } from '@components/swayStates/SwayError'
import * as Sentry from '@sentry/react'
import { RequireVendor } from '../../routes/guards/RequireVendor'

export const VendorAnalyticsRoutes = [
  {
    path: 'analytics',
    async lazy() {
      const { VendorAnalyticsPage } = await import('./VendorAnalytics.page')
      return {
        element: (
          <Sentry.ErrorBoundary
            fallback={SwayError}
            beforeCapture={(scope) => {
              scope.setTag('location', 'VendorAnalyticsRoutes')
            }}
          >
            <RequireVendor>
              <VendorAnalyticsPage />
            </RequireVendor>
          </Sentry.ErrorBoundary>
        ),
      }
    },
  },
]
