import { InputLabelSectionHeader } from '@components/InputLabelSectionHeader/InputLabelSectionHeader'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { MessageTemplate } from '@graphql'
import { SwayCashIcon } from '@icons/SwayCash'
import {
  Box,
  Button,
  Card,
  Group,
  NumberInput,
  Stack,
  Switch,
  Text,
  Textarea,
  Transition,
} from '@mantine/core'
import { useEffect } from 'react'
import {
  isMessageTemplateDefault,
  useBroadcastFormContext,
} from './BroadcastContext'
import { EditFormMultipleChoiceOptions } from './EditFormMultipleChoiceOptions'

export const BroadcastFollowUpMessage = ({
  showBroadcastFollowUp,
  setShowBroadcastFollowUp,
  isImpersonating,
}: {
  showBroadcastFollowUp: boolean
  setShowBroadcastFollowUp: React.Dispatch<React.SetStateAction<boolean>>
  isImpersonating: boolean
}) => {
  const form = useBroadcastFormContext()

  // The current `Follow Up Message Template` of the broadcast being edited.
  const followUpMessageTemplate = form.getInputProps('followUpMessageTemplate')
    .value as MessageTemplate | undefined

  // If the template is present, we enable the follow up message section.
  useEffect(() => {
    if (!isMessageTemplateDefault(followUpMessageTemplate)) {
      setShowBroadcastFollowUp(true)
    }
  }, [followUpMessageTemplate, setShowBroadcastFollowUp])

  const handleBroadcastFollowUpChange = () => {
    setShowBroadcastFollowUp(!showBroadcastFollowUp)

    // If we are toggling the follow up section, then we reset the `Follow Up Message Template` to the default state.
    form.getInputProps('followUpMessageTemplate').onChange({
      body: '',
      options: [],
      price: 100,
    })
  }

  const handleAddOption = () => {
    form.insertListItem('followUpMessageTemplate.options', {
      text: '',
    })
  }

  const handleRemoveOption = (index: number) => {
    form.removeListItem('followUpMessageTemplate.options', index)
  }

  return (
    <Box>
      <InputLabelSectionHeader>Follow-Up</InputLabelSectionHeader>
      <Text mt={12}>
        When a customer responds to your broadcast, you can send an immediate
        follow up message.
      </Text>
      <Switch
        checked={showBroadcastFollowUp}
        label="Follow up message"
        onChange={handleBroadcastFollowUpChange}
        mt={12}
      />
      <Transition transition="fade" mounted={showBroadcastFollowUp}>
        {(styles) => (
          <Stack style={styles} mt={12}>
            <Textarea
              id="followUpMessageTemplate-body"
              placeholder="Enter message"
              autosize
              maxRows={5}
              required
              {...form.getInputProps('followUpMessageTemplate.body')}
            />
            <Card withBorder>
              <Stack id="followUpMessageTemplate-options">
                <EditFormMultipleChoiceOptions
                  handleRemoveOption={handleRemoveOption}
                  formOptionsField="followUpMessageTemplate.options"
                />
                <Button
                  variant="Outline"
                  leftSection={<FontAwesomeIcon icon={faPlus} />}
                  onClick={handleAddOption}
                >
                  Add Response
                </Button>
              </Stack>
            </Card>
            {isImpersonating && (
              <Group>
                <Text size="sm"> Follow up message price:</Text>
                <NumberInput
                  id="followUpMessageTemplate-price"
                  value={Number(followUpMessageTemplate?.price) / 100}
                  onChange={(value) =>
                    form.setFieldValue(
                      'followUpMessageTemplate.price',
                      Math.round(Number(value) * 100)
                    )
                  }
                  step={0.05}
                  leftSection={<SwayCashIcon height={20} />}
                  error={
                    form.getInputProps('followUpMessageTemplate.price').error
                  }
                />
              </Group>
            )}
          </Stack>
        )}
      </Transition>
    </Box>
  )
}
