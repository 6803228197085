import {
  PublicVendor,
  RedemptionDonationProgress,
  RedemptionLimitPartsFragment,
  RedemptionMediaPreviewPartsFragment,
} from '@graphql'
import { useIsMobileViewport } from '@hooks/useIsMobileViewport'
import { useSaveRedemption } from '@hooks/useSaveRedemption'
import { SwayCashIcon } from '@icons/SwayCash'
import { Box, Group, Stack, Text } from '@mantine/core'
import { RedemptionDonationSection } from './RedemptionDonationSection'
import { RedemptionImage } from './RedemptionImage'

type RedemptionHorizontalCardProps = {
  id: string
  name: string
  description: string
  community: {
    profilePhotoUrl: string
    communityName: string
  }
  price: {
    amount: number
  }
  isNonProfit: boolean
  donationProgress?: RedemptionDonationProgress | null
  redemptionMedia: RedemptionMediaPreviewPartsFragment
  linkedProfile?: PublicVendor | null
  isExpired?: boolean
  ownerProfileId: string
  hasLimits?: boolean
  limits?: RedemptionLimitPartsFragment[] | undefined | null
  validEnd?: string | undefined | null
  distance?: string
  saved?: boolean
  showSaveSection: boolean
}

export const RedemptionHorizontalCard = ({
  id,
  name,
  description,
  community,
  price,
  redemptionMedia,
  isNonProfit,
  donationProgress,
  linkedProfile,
  isExpired,
  ownerProfileId,
  hasLimits,
  limits,
  validEnd,
  distance,
  saved,
  showSaveSection = false,
}: RedemptionHorizontalCardProps) => {
  const isMobile = useIsMobileViewport(true)
  const { handleSaveRedemption, handleUnsaveRedemption } = useSaveRedemption(id)

  return (
    <Group wrap="nowrap" w={'100%'}>
      <Group className="flex-shrink-0" pos={'relative'} gap={0}>
        <RedemptionImage
          isForCard={true}
          profilePhotoUrl={community.profilePhotoUrl}
          isExpired={isExpired}
          src={redemptionMedia.primaryMedia?.proxy?.url}
          srcSet={redemptionMedia.primaryMedia?.proxySrcSet?.url}
          linkedProfileId={linkedProfile?.id}
          linkedProfilePhotoUrl={linkedProfile?.photoUrl}
          linkedProfileDisplayName={linkedProfile?.displayName}
          ownerProfileId={ownerProfileId}
          saved={saved}
          handleSaveRedemption={handleSaveRedemption}
          handleUnsaveRedemption={handleUnsaveRedemption}
          hasLimits={hasLimits}
          limits={limits}
          validEnd={validEnd}
          showSaveSection={showSaveSection}
        />
      </Group>

      <Stack
        miw={0}
        justify="space-between"
        gap={0}
        w={'100%'}
        align="start"
        mih={isMobile ? 105 : 128}
        h={'100%'}
      >
        <Stack gap={4} w={'100%'} pr={16}>
          <Text size="lg" className="font-bold" lineClamp={1} visibleFrom="md">
            {name}
          </Text>
          <Text size="sm" className="font-bold" lineClamp={1} hiddenFrom="md">
            {name}
          </Text>
          {isNonProfit && (
            <RedemptionDonationSection donationProgress={donationProgress} />
          )}
          {!isNonProfit && (
            <Box pr={16}>
              <Text
                size="sm"
                lineClamp={2}
                visibleFrom="md"
                truncate="end"
                style={{
                  wordBreak: 'break-word',
                  whiteSpace: 'pre-wrap',
                }}
              >
                {description}
              </Text>
              <Text
                size="xs"
                lineClamp={2}
                hiddenFrom="md"
                truncate="end"
                style={{
                  wordBreak: 'break-word',
                  whiteSpace: 'pre-wrap',
                }}
              >
                {description}
              </Text>
            </Box>
          )}
        </Stack>
        {price.amount && (
          <Stack gap={0} w={'100%'}>
            <Text size="xs" lineClamp={1} className="mb-1" visibleFrom="md">
              {community.communityName}
            </Text>
            <Text size="xs" lineClamp={1} className="mb-1" hiddenFrom="md">
              {community.communityName}
            </Text>

            <Group justify={'space-between'} w={'100%'}>
              <Group gap={0}>
                <SwayCashIcon className="mr-2 h-4 w-4" />
                <Text size="sm">{(price.amount / 100).toFixed(2)} SC</Text>
              </Group>

              {!!distance && (
                <Text size="sm" className="ml-2" c={'dimmed'}>
                  {distance}
                </Text>
              )}
            </Group>
          </Stack>
        )}
      </Stack>
    </Group>
  )
}
