import React from 'react'
import {
  RedemptionFiltersContext,
  RedemptionFiltersContextType,
} from './RedemptionFilters.context'

export function useRedemptionFiltersContext(): RedemptionFiltersContextType {
  const context = React.useContext(RedemptionFiltersContext)
  if (context === undefined) {
    throw new Error(
      'useRedemptionFiltersContext must be used within a RedemptionFiltersProvider'
    )
  }
  return context
}
