import { PermissionState } from '@capacitor/core'
import { useAnalyticsContext } from '@context/analyticsContext'
import { useCallback } from 'react'

/**
 * Use SwayAnaltyicsAdditionalProperties to provide arbitrary properties to segment events.
 * Good for providing additional context to events.
 */
export interface SwayAnaltyicsAdditionalProperties {
  additional_properties?: object
}

export interface UserLogin {
  userId: string
}

export interface UserLogout {
  userId: string
}

export interface UserSwitch {
  fromUserId: string
  toUserId: string
}

export interface FeedItem {
  itemId: string
  type:
    | 'broadcast'
    | 'persistent-broadcast'
    | 'redemption-suggestion'
    | 'vendor-suggestion'
    | 'unclaimed-suggestion'
  vendorId?: string
  profileId?: string
}

export interface AnalyticsDuration {
  durationMs: number
}

export interface FeedItemAction extends FeedItem, AnalyticsDuration {
  action: 'skipped' | 'viewed'
}

export interface PushNotificationAction {
  userId: string
  route: string
  event: string
  action: string
}

export interface LocationPermission {
  locationPermission: PermissionState
  platform: 'ios' | 'android' | 'web'
}

export interface Impression {
  /**
   * The type the impression was created for.
   */
  targetType:
    | 'broadcast'
    | 'persistent-broadcast'
    | 'redemption-suggestion'
    | 'vendor-suggestion'
    | 'unclaimed-suggestion'
    | 'vendor'
    | 'redemption'

  /**
   * The ID of the type the impression was created for.
   * This should be a consistent identifier, and must be a valid uuid.
   */
  targetId: string

  /**
   * The source of the impression.  Where in the app did this happen?
   */
  actionSource:
    | 'feed'
    | 'profile-page'
    | 'redemption-page'
    | 'vendor-profile-page'

  /**
   * What action triggered the creation of the impression?
   */
  action: 'skipped' | 'viewed'

  /**
   * What profile triggered the creation of the impression?
   * Most likely this is the logged in user's profile_id.
   * This is required in order to determine unique views, or aggregating interactions by member.
   */

  profileId?: string | null

  /**
   * What vendor triggered the creation of the impression?
   * This is required in order to aggregate counts of impressions by vendor.
   */
  vendorId?: string

  /**
   * If there is a time component to an impression, you can use this attribute.
   * For example, how long did the user view a feed item before skipping.
   */
  deltaMs?: number
}

export interface SwayAnaltyics {
  /**
   * Capture a user login event.
   *
   * @param userLogin
   * @param additional_properties
   */
  userLogin(
    userLogin: UserLogin,
    additional_properties?: SwayAnaltyicsAdditionalProperties
  ): void

  /**
   * Capture a user logout event.
   *
   * @param userLogin
   * @param additional_properties
   */
  userLogout(
    userLogout: UserLogout,
    additional_properties?: SwayAnaltyicsAdditionalProperties
  ): void

  /**
   * Capture a user switch event.
   *
   * @param userSwitch
   * @param additional_properties
   */
  userSwitch(
    userSwitch: UserSwitch,
    additional_properties?: SwayAnaltyicsAdditionalProperties
  ): void

  /**
   * Capture an impression.
   *
   * Used to track user activities across all aspects of the app.
   * Impressions can be rolled up into aggregates for analytics, and also used to
   * help influence future content the user may, or may not see.
   *
   * @param impression
   * @param additional_properties
   */
  impression(
    impression: Impression,
    additional_properties?: SwayAnaltyicsAdditionalProperties
  ): void

  /**
   * Capture an action on a feed item.
   *
   * This is simply a wrapper for creating impressions.
   *
   * @param feedItemAction
   * @param additional_properties
   */
  feedItemAction(
    feedItemAction: FeedItemAction,
    additional_properties?: SwayAnaltyicsAdditionalProperties
  ): void

  /**
   * Capture a push notificaiton action.
   *
   * @param pushNotificationAction
   * @param additional_properties
   */
  pushNotificationAction(
    pushNotificationAction: PushNotificationAction,
    additional_properties?: SwayAnaltyicsAdditionalProperties
  ): void

  /**
   * Capture a location permission update.
   *
   * @param locationPermission
   * @param additional_properties
   */
  locationPermission(
    locationPermission: LocationPermission,
    additional_properties?: SwayAnaltyicsAdditionalProperties
  ): void
}

const useAnalytics = (): SwayAnaltyics => {
  const { analytics } = useAnalyticsContext()

  const userLogin = useCallback(
    (
      userLogin: UserLogin,
      additional_properties?: SwayAnaltyicsAdditionalProperties
    ) => {
      analytics?.identify(userLogin.userId, additional_properties)
      analytics?.track('user:login', {
        ...userLogin,
        ...additional_properties,
      })
    },
    [analytics]
  )

  const userLogout = useCallback(
    (
      userLogout: UserLogout,
      additional_properties?: SwayAnaltyicsAdditionalProperties
    ) => {
      analytics?.track('user:logout', {
        ...userLogout,
        ...additional_properties,
      })
    },
    [analytics]
  )

  const userSwitch = useCallback(
    (
      userSwitch: UserSwitch,
      additional_properties?: SwayAnaltyicsAdditionalProperties
    ) => {
      analytics?.track('user:switch', {
        from_user_id: userSwitch.fromUserId,
        to_user_id: userSwitch.toUserId,
        ...additional_properties,
      })
      analytics?.identify(userSwitch.toUserId, additional_properties)
    },
    [analytics]
  )

  const feedItemAction = useCallback(
    (
      feedItemAction: FeedItemAction,
      additional_properties?: SwayAnaltyicsAdditionalProperties
    ) => {
      if (analytics) {
        const impression: Impression = {
          targetType: feedItemAction.type,
          targetId: feedItemAction.itemId,
          actionSource: 'feed',
          action: feedItemAction.action,
          profileId: feedItemAction.profileId,
          vendorId: feedItemAction.vendorId,
          deltaMs: feedItemAction.durationMs,
        }
        analytics?.track('impression', {
          ...impression,
          ...additional_properties,
        })
      }
    },
    [analytics]
  )

  const locationPermission = useCallback(
    (
      locationPermission: LocationPermission,
      additional_properties?: SwayAnaltyicsAdditionalProperties
    ) => {
      analytics?.track('location:permission', {
        ...locationPermission,
        ...additional_properties,
      })
    },
    [analytics]
  )

  const pushNotificationAction = useCallback(
    (
      pushNotificationAction: PushNotificationAction,
      additional_properties?: SwayAnaltyicsAdditionalProperties
    ) => {
      if (analytics) {
        analytics.track('push:action', {
          ...pushNotificationAction,
          ...additional_properties,
        })
      }
    },
    [analytics]
  )

  const impression = useCallback(
    (
      impression: Impression,
      additional_properties?: SwayAnaltyicsAdditionalProperties
    ) => {
      analytics?.track('impression', {
        ...impression,
        ...additional_properties,
      })
    },
    [analytics]
  )

  return {
    userLogin,
    userLogout,
    userSwitch,
    feedItemAction,
    pushNotificationAction,
    locationPermission,
    impression,
  }
}

export default useAnalytics
