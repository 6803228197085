import { LoadingOverlay, LoadingOverlayProps } from '@mantine/core'
import { forwardRef } from 'react'

export const SwayLoadingOverlay = forwardRef<
  HTMLDivElement,
  LoadingOverlayProps
>((props, ref) => {
  const {
    visible,
    zIndex = 50,
    overlayProps = {},
    loaderProps = {},
    style,
    ...rest
  } = props

  return (
    <LoadingOverlay
      ref={ref}
      visible={visible}
      zIndex={zIndex}
      overlayProps={{ radius: 'sm', blur: 2, ...overlayProps }}
      loaderProps={{ color: 'swurple', type: 'bars', top: 50, ...loaderProps }}
      style={{ alignItems: 'flex-start', ...style }}
      {...rest}
    />
  )
})
