import { SwayCashIcon } from '@icons/SwayCash'
import { Flex, Modal, useMantineTheme } from '@mantine/core'
import { LocationPermissionModalContent } from './LocationPermissionModalContent'

export const LocationPermissionModal = ({
  opened,
  onClose,
}: {
  opened: boolean
  onClose: () => void
}) => {
  const theme = useMantineTheme()

  return (
    <Modal.Root opened={opened} onClose={onClose} centered>
      <Modal.Overlay />
      <Modal.Content bg={theme.primaryColor}>
        <Modal.Body className="overflow-hidden" pos={'relative'}>
          <Flex
            className="h-68 w-68 absolute inset-0 m-auto scale-200 overflow-hidden opacity-10"
            justify={'center'}
          >
            <SwayCashIcon allFilled color="white" />
          </Flex>
          <LocationPermissionModalContent onClose={onClose} />
        </Modal.Body>
      </Modal.Content>
    </Modal.Root>
  )
}
