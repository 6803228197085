import { faLocationDot } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useGetLocation } from '@hooks/useGetLocation'
import { Divider, Stack, Text, Title } from '@mantine/core'
import { ButtonsLocationPermissionModal } from './ButtonsLocationPermissionModal'

const LocationPermissionModalCopy = () => {
  return (
    <Text c={'white'} size="sm" className="text-center">
      SwayDM works best with your location! We can customize offers and maximize
      your SwayCash earnings. We never share your location with businesses or
      any third parties
    </Text>
  )
}

const MainTextAndIcon = ({
  fullscreen,
  isLocationDenied,
}: {
  fullscreen: boolean
  isLocationDenied?: boolean
}) => {
  return (
    <>
      <FontAwesomeIcon
        icon={faLocationDot}
        color="white"
        className={fullscreen ? 'mb-8 h-24 w-24' : 'h-24 w-24'}
      />
      <Title order={4} c={'white'} className="text-center">
        {isLocationDenied
          ? 'Please update your device settings!'
          : 'Share your location with SwayDM'}
      </Title>
      <LocationPermissionModalCopy />
    </>
  )
}

export const LocationPermissionModalContent = ({
  fullscreen = false,
  onClose,
  onSkip,
}: {
  fullscreen?: boolean
  onClose: () => void
  onSkip?: () => void
}) => {
  const { requestLocationPermission, permissions } = useGetLocation()

  const isLocationDenied = permissions?.location === 'denied'

  return (
    <Stack
      align="center"
      {...(fullscreen
        ? {
            h: '100%',
            px: 12,
            justify: 'center',
            gap: 0,
            pos: 'relative',
          }
        : { p: 24, gap: 24 })}
    >
      {fullscreen ? (
        <Stack align="center" gap={14}>
          <MainTextAndIcon fullscreen={fullscreen} />
        </Stack>
      ) : (
        <MainTextAndIcon
          fullscreen={fullscreen}
          isLocationDenied={isLocationDenied}
        />
      )}
      <Stack
        w={'100%'}
        gap={12}
        {...(fullscreen ? { pos: 'absolute', bottom: 24 } : {})}
      >
        {!fullscreen && <Divider color="swurple.4" w={'100%'} my={16} />}
        <ButtonsLocationPermissionModal
          isLocationDenied={isLocationDenied}
          onClose={onClose}
          onSkip={onSkip}
          requestLocationPermission={requestLocationPermission}
        />
      </Stack>
    </Stack>
  )
}
