import { Button, Modal, Stack, Text } from '@mantine/core'
import dayjs from 'dayjs'

export function EditBroadcastPublishConfirmationModal({
  opened,
  publishDate,
  onConfirmation,
  onClose,
}: {
  opened: boolean
  publishDate: Date
  onConfirmation: () => void
  onClose: () => void
}) {
  return (
    <Modal
      opened={opened}
      onClose={onClose}
      title="Schedule Broadcase"
      centered
    >
      <Stack>
        <Text>
          Are you sure you want to schedule this broadcast for{' '}
          {dayjs(publishDate).format('MMMM D, YYYY h:mm A')}? You will be unable
          to edit the broadcase once it has been scheduled.
        </Text>

        <Button variant="primary" onClick={onConfirmation} fullWidth>
          Yes, Schedule For {dayjs(publishDate).format('MMMM D, YYYY h:mm A')}
        </Button>
        <Button variant="outline" onClick={onClose} fullWidth>
          Not now
        </Button>
      </Stack>
    </Modal>
  )
}
