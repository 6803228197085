import { faClose, faMobileScreen } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ProfileType } from '@graphql'
import {
  ActionIcon,
  Affix,
  Box,
  Button,
  Card,
  Center,
  Drawer,
  Stack,
  Text,
  Transition,
} from '@mantine/core'
import { SEMI_BOLD } from '@util/utils'

export const GetAppPopup = ({
  open,
  profileType,
  onContinue,
}: {
  open: boolean
  profileType: ProfileType
  onContinue: () => void
}) => {
  return (
    <>
      <Drawer
        opened={open}
        position="bottom"
        onClose={() => onContinue()}
        withCloseButton
        classNames={{
          close: 'text-white',
          header: 'text-white',
          content: 'rounded-t-3xl text-white',
        }}
        styles={{
          header: {
            backgroundColor: 'var(--mantine-color-deep-blue-9, #000065)',
          },
          content: {
            backgroundColor: 'var(--mantine-color-deep-blue-9, #000065)',
          },
        }}
        bg="deep-blue.9"
        hiddenFrom="sm"
      >
        <GetAppPopupContent profileType={profileType} />
      </Drawer>
      <Affix position={{ bottom: 22, right: 25 }} visibleFrom="sm">
        <Transition transition="slide-up" mounted={open}>
          {(transitionStyles) => (
            <Card
              variant="filled"
              c="white"
              bg="deep-blue.9"
              style={transitionStyles}
              pos="relative"
              radius="lg"
              maw={340}
            >
              <Box pos="absolute" right={10} top={10}>
                <ActionIcon
                  color="white"
                  onClick={() => onContinue()}
                  aria-label="Close"
                  size="sm"
                  variant="subtle"
                >
                  <FontAwesomeIcon icon={faClose} />
                </ActionIcon>
              </Box>
              <GetAppPopupContent profileType={profileType} />
            </Card>
          )}
        </Transition>
      </Affix>
    </>
  )
}

function GetAppPopupContent({ profileType }: { profileType: ProfileType }) {
  let popupText = <MemberGetAppText />

  if (profileType === ProfileType.Community) {
    popupText = <VendorGetAppText />
  }

  return (
    <>
      <Stack justify="center" align="center" py={16} gap={15}>
        <Center bg="swurple" c="white" className="rounded-full" h={42} w={42}>
          <FontAwesomeIcon icon={faMobileScreen} style={{ height: '21px' }} />
        </Center>
        <Text fw={SEMI_BOLD} ta="center">
          Get the Sway mobile app!
        </Text>
        <Text c="grey.400" size="sm" ta="center">
          {popupText}
        </Text>
        <Button
          component="a"
          href="https://link.sway.dm/e/app-store/ua"
          fullWidth
          maw={250}
        >
          Download
        </Button>
      </Stack>
    </>
  )
}

function VendorGetAppText() {
  return (
    <>
      Create Broadcasts, update Redemptions, and see what SwayDM is
      accomplishing for your business. The SwayDM mobile app is the easiest way
      to make sure you don't miss out.
    </>
  )
}

function MemberGetAppText() {
  return (
    <>
      Earn more SwayCash and stay in the loop with the newest Vendors and
      Redemptions offers! The SwayDM mobile app is the easiest way to make sure
      you don't miss out.
    </>
  )
}
