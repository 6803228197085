import { NotificationPreferences, NotificationPreferencesInput } from '@graphql'

export function transformNotificationPreferences(
  notificationPreferences: NotificationPreferences
): NotificationPreferencesInput {
  const channels = notificationPreferences.channels.map((channel) => {
    if (!channel) return null
    return {
      type: channel.type,
      enabled: channel.enabled,
    }
  })

  const workflows = notificationPreferences.workflows.map((workflow) => {
    if (!workflow) return null

    const channelPreferences = workflow.channelPreferences.map((channel) => {
      if (!channel) return null
      return {
        type: channel.type,
        enabled: channel.enabled,
      }
    })

    return {
      key: workflow.key,
      enabled: workflow.enabled,
      channelPreferences,
    }
  })

  return {
    channels: channels.filter((c) => !!c),
    workflows: workflows.filter((w) => !!w),
  }
}
