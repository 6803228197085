import '@mantine/notifications/styles.css'

import { Preferences } from '@capacitor/preferences'
import { ChangelogModalContent } from '@components/modals/changelogModal/ChangelogModalContent'
import { FullscreenModal } from '@components/modals/FullscreenModal'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPartyHorn } from '@fortawesome/sharp-solid-svg-icons'
import { useDisclosure } from '@mantine/hooks'
import { useEffect, useState } from 'react'

type ChangelogFullscreenModalProps = {
  isCommunityFollower: boolean
}

const getChangelogTimestamp = async () => {
  const { value } = await Preferences.get({ key: 'changelog-last-seen' })
  return value
}

// * Changelog modal:
// A one time modal which shows SwayDM latest changes.
export const ChangelogFullscreenModal = ({
  isCommunityFollower,
}: ChangelogFullscreenModalProps) => {
  const [showChangelog, setShowChangelog] = useState<boolean | null>(null)

  const [changelogModalOpened, { close: changelogModalClose }] =
    useDisclosure(true)

  const handleChangelogModalClose = async () => {
    changelogModalClose()
    await Preferences.set({
      key: 'changelog-last-seen',
      value: JSON.stringify(Date.now()),
    })
  }

  useEffect(() => {
    const fetchChangelogLastSeen = async () => {
      const timestamp = await getChangelogTimestamp()
      // If the changelog was seen, then we don't show it again.
      // TODO: Change this when we start exposing future changelogs from the BE.
      setShowChangelog(!timestamp)
    }

    fetchChangelogLastSeen()
  }, [])

  return (
    <>
      {showChangelog && isCommunityFollower && (
        <FullscreenModal
          opened={changelogModalOpened}
          onClose={handleChangelogModalClose}
          backgroundIcon={
            <FontAwesomeIcon
              icon={faPartyHorn as IconProp}
              color="white"
              className="h-auto w-auto"
            />
          }
          children={
            <ChangelogModalContent onClose={handleChangelogModalClose} />
          }
        />
      )}
    </>
  )
}
