import { SwayError } from '@components/swayStates/SwayError'
import { RedemptionFiltersProvider } from '@context/redemptionFiltersContext'
import * as Sentry from '@sentry/react'
import { RequireVendor } from '../../routes/guards/RequireVendor'

export const PublicRedemptionsRoutes = [
  {
    path: 'redemptions/offers/:redemptionId',
    async lazy() {
      const { PublicRedemptionsPage } = await import('./PublicRedemptions.page')
      return {
        element: (
          <Sentry.ErrorBoundary
            fallback={SwayError}
            beforeCapture={(scope) => {
              scope.setTag('location', 'PublicRedemptionsPageRoute')
            }}
          >
            <PublicRedemptionsPage />
          </Sentry.ErrorBoundary>
        ),
      }
    },
  },
]

export const MemberRedemptionsRoutes = [
  {
    path: 'redemptions/vendors',
    async lazy() {
      const { RedemptionVendors } = await import('./RedemptionVendors.page')
      return {
        element: (
          <Sentry.ErrorBoundary
            fallback={SwayError}
            beforeCapture={(scope) => {
              scope.setTag('location', 'MemberRedemptionsRoutes')
            }}
          >
            <RedemptionVendors />
          </Sentry.ErrorBoundary>
        ),
      }
    },
    children: [
      {
        path: '',
        async lazy() {
          const { EmptyVendorScreen } = await import(
            './screens/EmptyVendor.screen'
          )
          return {
            Component: EmptyVendorScreen,
          }
        },
      },
      {
        path: ':displayName',
        async lazy() {
          const { VendorRedemptionsProfileScreen } = await import(
            './screens/VendorRedemptionsProfile.screen'
          )
          return {
            element: (
              <Sentry.ErrorBoundary
                fallback={SwayError}
                beforeCapture={(scope) => {
                  scope.setTag('location', 'RedemptionVendorsProfileScreen')
                }}
              >
                <VendorRedemptionsProfileScreen />
              </Sentry.ErrorBoundary>
            ),
          }
        },
      },
    ],
  },
  {
    path: 'redemptions/saved',
    async lazy() {
      const { SavedRedemptionsPage } = await import('./SavedRedemptions.page')
      return {
        element: (
          <Sentry.ErrorBoundary
            fallback={SwayError}
            beforeCapture={(scope) => {
              scope.setTag('location', 'SavedRedemptionsPageRoute')
            }}
          >
            <RedemptionFiltersProvider>
              <SavedRedemptionsPage />
            </RedemptionFiltersProvider>
          </Sentry.ErrorBoundary>
        ),
      }
    },
    children: [
      {
        path: '',
        async lazy() {
          const { EmptyRedemptionScreen } = await import(
            './screens/EmptyRedemption.screen'
          )
          return {
            Component: EmptyRedemptionScreen,
          }
        },
      },
      {
        path: 'offers',
        async lazy() {
          const { EmptyRedemptionScreen } = await import(
            './screens/EmptyRedemption.screen'
          )
          return {
            Component: EmptyRedemptionScreen,
          }
        },
      },
      {
        path: 'offers/:redemptionId',
        async lazy() {
          const { RedemptionsScreen } = await import(
            './screens/Redemptions.screen'
          )
          return {
            element: (
              <Sentry.ErrorBoundary
                fallback={SwayError}
                beforeCapture={(scope) => {
                  scope.setTag('location', 'SavedRedemptionsScreen')
                }}
              >
                <RedemptionsScreen />
              </Sentry.ErrorBoundary>
            ),
          }
        },
      },
    ],
  },
  {
    path: 'redemptions/history',
    async lazy() {
      const { HistoryRedemptionsPage } = await import(
        './HistoryRedemptions.page'
      )
      return {
        element: (
          <Sentry.ErrorBoundary
            fallback={SwayError}
            beforeCapture={(scope) => {
              scope.setTag('location', 'HistoryRedemptionsPageRoute')
            }}
          >
            <HistoryRedemptionsPage />
          </Sentry.ErrorBoundary>
        ),
      }
    },
    children: [
      {
        path: '',
        async lazy() {
          const { EmptyRedemptionScreen } = await import(
            './screens/EmptyRedemption.screen'
          )
          return {
            Component: EmptyRedemptionScreen,
          }
        },
      },
      {
        path: 'vendors',
        async lazy() {
          const { EmptyVendorScreen } = await import(
            './screens/EmptyVendor.screen'
          )
          return {
            Component: EmptyVendorScreen,
          }
        },
      },
      {
        path: 'vendors/:displayName',
        async lazy() {
          const { VendorRedemptionsProfileScreen } = await import(
            './screens/VendorRedemptionsProfile.screen'
          )
          return {
            element: (
              <Sentry.ErrorBoundary
                fallback={SwayError}
                beforeCapture={(scope) => {
                  scope.setTag('location', 'RedemptionHistoryProfileScreen')
                }}
              >
                <VendorRedemptionsProfileScreen />
              </Sentry.ErrorBoundary>
            ),
          }
        },
      },
      {
        path: ':redemptionId',
        async lazy() {
          const { RedemptionsScreen } = await import(
            './screens/Redemptions.screen'
          )
          return {
            element: (
              <Sentry.ErrorBoundary
                fallback={SwayError}
                beforeCapture={(scope) => {
                  scope.setTag('location', 'RedemptionHistoryRedemptionScreen')
                }}
              >
                <RedemptionsScreen />
              </Sentry.ErrorBoundary>
            ),
          }
        },
      },
    ],
  },
  {
    path: 'redemptions',
    async lazy() {
      const { RedemptionsPage } = await import('./Redemptions.page')
      return {
        element: (
          <Sentry.ErrorBoundary
            fallback={SwayError}
            beforeCapture={(scope) => {
              scope.setTag('location', 'RedemptionsPageRoute')
            }}
          >
            <RedemptionFiltersProvider>
              <RedemptionsPage />
            </RedemptionFiltersProvider>
          </Sentry.ErrorBoundary>
        ),
      }
    },
    children: [
      {
        path: '',
        async lazy() {
          const { EmptyRedemptionScreen } = await import(
            './screens/EmptyRedemption.screen'
          )
          return {
            Component: EmptyRedemptionScreen,
          }
        },
      },
      {
        path: 'offers',
        async lazy() {
          const { EmptyRedemptionScreen } = await import(
            './screens/EmptyRedemption.screen'
          )
          return {
            Component: EmptyRedemptionScreen,
          }
        },
      },
      {
        path: 'offers/:redemptionId',
        async lazy() {
          const { RedemptionsScreen } = await import(
            './screens/Redemptions.screen'
          )
          return {
            element: (
              <Sentry.ErrorBoundary
                fallback={SwayError}
                beforeCapture={(scope) => {
                  scope.setTag('location', 'RedemptionsScreen')
                }}
              >
                <RedemptionsScreen />
              </Sentry.ErrorBoundary>
            ),
          }
        },
      },
    ],
  },
]

export const VendorRedemptionsRoutes = [
  {
    path: 'redemptions',
    async lazy() {
      const { VendorRedemptionsPage } = await import('./VendorRedemptions.page')
      return {
        element: (
          <Sentry.ErrorBoundary
            fallback={SwayError}
            beforeCapture={(scope) => {
              scope.setTag('location', 'VendorRedemptionsRoutes')
            }}
          >
            <RequireVendor>
              <VendorRedemptionsPage />
            </RequireVendor>
          </Sentry.ErrorBoundary>
        ),
      }
    },
    children: [
      {
        path: '',
        async lazy() {
          const { LiveVendorRedemptionsScreen } = await import(
            './screens/vendor/LiveVendorRedemptions.screen'
          )
          return {
            element: (
              <Sentry.ErrorBoundary
                fallback={SwayError}
                beforeCapture={(scope) => {
                  scope.setTag('location', 'LiveVendorRedemptionsScreen')
                }}
              >
                <LiveVendorRedemptionsScreen />
              </Sentry.ErrorBoundary>
            ),
          }
        },
      },
      {
        path: 'drafts',
        async lazy() {
          const { DraftVendorRedemptionsScreen } = await import(
            './screens/vendor/DraftVendorRedemptions.screen'
          )
          return {
            element: (
              <Sentry.ErrorBoundary
                fallback={SwayError}
                beforeCapture={(scope) => {
                  scope.setTag('location', 'DraftVendorRedemptionsScreen')
                }}
              >
                <DraftVendorRedemptionsScreen />
              </Sentry.ErrorBoundary>
            ),
          }
        },
      },
      {
        path: 'archived',
        async lazy() {
          const { ArchivedVendorRedemptionsScreen } = await import(
            './screens/vendor/ArchivedVendorRedemptions.screen'
          )
          return {
            element: (
              <Sentry.ErrorBoundary
                fallback={SwayError}
                beforeCapture={(scope) => {
                  scope.setTag('location', 'ArchivedVendorRedemptionsScreen')
                }}
              >
                <ArchivedVendorRedemptionsScreen />
              </Sentry.ErrorBoundary>
            ),
          }
        },
      },
      {
        path: 'history',
        async lazy() {
          const { VendorRedemptionHistoryScreen } = await import(
            './screens/vendor/VendorRedemptionHistory.screen'
          )
          return {
            element: (
              <Sentry.ErrorBoundary
                fallback={SwayError}
                beforeCapture={(scope) => {
                  scope.setTag('location', 'VendorRedemptionHistoryScreen')
                }}
              >
                <VendorRedemptionHistoryScreen />
              </Sentry.ErrorBoundary>
            ),
          }
        },
      },
      {
        path: 'edit/:redemptionId',
        async lazy() {
          const { EditRedemptionScreen } = await import(
            './screens/vendor/EditRedemption.screen'
          )
          return {
            element: (
              <Sentry.ErrorBoundary
                fallback={SwayError}
                beforeCapture={(scope) => {
                  scope.setTag('location', 'EditRedemptionScreen')
                }}
              >
                <EditRedemptionScreen />
              </Sentry.ErrorBoundary>
            ),
          }
        },
      },
      {
        path: ':redemptionId',
        async lazy() {
          const { VendorRedemptionDetailsScreen } = await import(
            './screens/vendor/VendorRedemptionDetails.screen'
          )
          return {
            element: (
              <Sentry.ErrorBoundary
                fallback={SwayError}
                beforeCapture={(scope) => {
                  scope.setTag('location', 'VendorRedemptionDetailsScreen')
                }}
              >
                <VendorRedemptionDetailsScreen />
              </Sentry.ErrorBoundary>
            ),
          }
        },
      },
    ],
  },
]
