import { ProfileType } from '@graphql'
import { Box, Group, rem, Stack, Text } from '@mantine/core'

import { useAuth } from '@hooks/useAuth'
import { SwayCashIcon } from '@icons/SwayCash'
import { SEMI_BOLD } from '@util/utils'
import { Link } from 'react-router-dom'
import { QrCodeScanButton } from './QrCodeScanButton'

export function WalletBalanceFooterSection({
  platform,
  walletBalance,
  onQrCodeScan,
}: {
  platform: string
  walletBalance: number
  onQrCodeScan?: () => void
}) {
  const { currentUser } = useAuth()

  const isMember = currentUser?.profileType === ProfileType.CommunityFollower
  const isVendor = currentUser?.profileType === ProfileType.Community

  return (
    <Group
      wrap="nowrap"
      justify="space-between"
      px={rem(12)}
      py={4}
      className="border-b"
      gap={4}
    >
      <Box component={Link} to="/earn" w="100%">
        <Group wrap="nowrap" w="100%" justify="space-between">
          <Stack gap={0}>
            <Text size="xs" fw={SEMI_BOLD}>
              SwayCash Balance
            </Text>
            <Text c={'cyan.6'} fw={SEMI_BOLD} style={{ fontSize: '0.6rem' }}>
              {isMember && `${currentUser?.firstName} ${currentUser?.lastName}`}
              {isVendor && currentUser?.communityName}
            </Text>
          </Stack>
          <Group>
            <Text size="sm" className="inline-flex items-center" fw={700}>
              <SwayCashIcon className="mr-2 h-4 w-4" />
              {(walletBalance / 100).toLocaleString('en-US', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}{' '}
              SC
            </Text>
          </Group>
        </Group>
      </Box>
      {platform !== 'web' && onQrCodeScan && (
        <QrCodeScanButton onQrCodeScan={onQrCodeScan} />
      )}
    </Group>
  )
}
